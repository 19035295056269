import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import Lottie from "react-lottie";
import loading from "./loading.json";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Client, Databases, ID } from "appwrite";
import jobit from "../assets/BlogContent/jobit.webp";
import CustomMarkdownRenderer from "../components/Markdown.js";
import { Link } from "react-router-dom";
import casestudy1 from "../assets/Casestudy/casestudy1.webp";
import rightarrow from "../assets/Blogs/rightarrow.webp";
import { Helmet } from "react-helmet";
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from "libphonenumber-js";

// function titleToSlug(title) {
//   return title
//     .toLowerCase()
//     .replace(/[^\w\s-]/g, "")
//     .replace(/[\s_-]+/g, "-")
//     .replace(/^-+|-+$/g, "");
// }
function titleToSlug(title) {
  if (!title) return "";
  return title
    .toLowerCase()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
}

const Casestudycontent = ({ utmContent }) => {
  const { id, title } = useParams();
  const {
    loading: casestudyLoading,
    data: casestudyData,
    error: casestudyError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/casestudy/data.json`
  );

  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  console.log("blogs", casestudyData);
  const [currentCaseStudy, setCurrentCaseStudy] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [businessemail, setEmailId] = useState("");
  const [projectdetails, setprojectdetails] = useState("");

  const [utmParams, setUtmParams] = useState({
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_id: "",
    utm_term: "",
    utm_content: "",
  });

  const [errors, setErrors] = useState({});

  const resetForm = () => {
    setfirstname("");
    setlastname("");
    setPhoneNumber("");

    setCompanyName("");
    setEmailId("");
    setprojectdetails("");
    setIsChecked(true);
    setErrors({});
  };
  const handleButtonClickcontact = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    resetForm();
  };

  const client = new Client()
    .setEndpoint(process.env.REACT_APP_ENDPOINT)
    .setProject(process.env.REACT_APP_PROJECT_ID);

  const databases = new Databases(client);
  useEffect(() => {
    // Set page title
    document.title = `${currentCaseStudy?.case_title} | Random Walk Innovations`;
  
    // Set meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', currentCaseStudy?.meta_desc);
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = currentCaseStudy?.meta_desc;
      document.head.appendChild(meta);
    }
  
    // Add or update Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement('meta');
      ogTitle.setAttribute('property', 'og:title');
      document.head.appendChild(ogTitle);
    }
    ogTitle.setAttribute('content', `${currentCaseStudy?.case_title} | Random Walk Innovations`);
  
    // Add or update Open Graph description
    let ogDescription = document.querySelector('meta[property="og:description"]');
    if (!ogDescription) {
      ogDescription = document.createElement('meta');
      ogDescription.setAttribute('property', 'og:description');
      document.head.appendChild(ogDescription);
    }
    ogDescription.setAttribute('content', currentCaseStudy?.meta_desc);
  
    // Add viewport meta tag
    let viewport = document.querySelector('meta[name="viewport"]');
    if (!viewport) {
      viewport = document.createElement('meta');
      viewport.name = "viewport";
      document.head.appendChild(viewport);
    }
    viewport.setAttribute('content', "width=device-width, initial-scale=1");
  
    // Add theme-color meta tag
    let themeColor = document.querySelector('meta[name="theme-color"]');
    if (!themeColor) {
      themeColor = document.createElement('meta');
      themeColor.name = "theme-color";
      document.head.appendChild(themeColor);
    }
    themeColor.setAttribute('content', "#000000");
  
    // Add Open Graph image
    let ogImage = document.querySelector('meta[property="og:image"]');
    if (!ogImage) {
      ogImage = document.createElement('meta');
      ogImage.setAttribute('property', 'og:image');
      document.head.appendChild(ogImage);
    }
    ogImage.setAttribute('content', `${process.env.PUBLIC_URL}/rw_logo.png`);
  
    // Add Open Graph type
    let ogType = document.querySelector('meta[property="og:type"]');
    if (!ogType) {
      ogType = document.createElement('meta');
      ogType.setAttribute('property', 'og:type');
      document.head.appendChild(ogType);
    }
    ogType.setAttribute('content', "website");
  
    // Add canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.rel = "canonical";
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.href = `https://randomwalk.ai/casestudy/${title}`;
  }, [currentCaseStudy, title]);
  
  const handlePhoneNumberChange = (number, country) => {
    setPhoneNumber(number);

    try {
      // Ensure number starts with +
      const fullNumber = number.startsWith("+") ? number : `+${number}`;

      // Check if the number is valid for the selected country
      if (isValidPhoneNumber(fullNumber)) {
        const phoneNumberObj = parsePhoneNumberFromString(fullNumber);
        const nationalNumber = phoneNumberObj.nationalNumber;

        // Get expected length based on country
        const expectedLength = phoneNumberObj.nationalNumber?.length;
        const actualLength = nationalNumber?.length;

        if (actualLength < expectedLength) {
          setErrors((prev) => ({
            ...prev,
            phoneNumber: `Phone number is too short - needs ${expectedLength} digits`,
          }));
        } else if (actualLength > expectedLength) {
          setErrors((prev) => ({
            ...prev,
            phoneNumber: `Phone number is too long - should be ${expectedLength} digits`,
          }));
        } else {
          // Clear the error when valid
          setErrors((prev) => ({
            ...prev,
            phoneNumber: "",
          }));
        }
      } else {
        setErrors((prev) => ({
          ...prev,
          phoneNumber: "Please enter a valid phone number",
        }));
      }
    } catch (error) {
      console.error("Phone validation error:", error);
      setErrors((prev) => ({
        ...prev,
        phoneNumber: "Please enter a valid phone number",
      }));
    }
  };

  useEffect(() => {
    const captureUtmParams = () => {
      const params = new URLSearchParams(window.location.search);
      const utmFields = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_id",
        "utm_term",
        "utm_content",
      ];

      const newUtmParams = { utm_content: utmContent };
      utmFields.forEach((field) => {
        newUtmParams[field] = params.get(field) || "";
      });
      setUtmParams(newUtmParams);
    };

    captureUtmParams();
    window.addEventListener("popstate", captureUtmParams);

    return () => {
      window.removeEventListener("popstate", captureUtmParams);
    };
  }, []);

  const handleSubmitcontact = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      if (!phoneNumber) {
        setErrors({ phoneNumber: "Phone number is required" });
        setIsLoading(false);
        return;
      }
      const fullNumber = phoneNumber.startsWith("+")
        ? phoneNumber
        : `+${phoneNumber}`;
      if (!isValidPhoneNumber(fullNumber)) {
        setErrors((prev) => ({
          ...prev,
          phoneNumber: "Please enter a valid phone number",
        }));
        return;
      }

      const formData = {
        firstname,
        lastname,
        businessemail,
        companyName,
        phoneNumber,
        projectdetails,
        utmSource: utmParams.utm_source,
        utmMedium: utmParams.utm_medium,
        utmCampaign: utmParams.utm_campaign,
        utmId: utmParams.utm_id,
        utmTerm: utmParams.utm_term,
        utmContent: "case study", // Set the utmContent to "homepage"
      };
      await Promise.all([
        databases.createDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_ID2,
          ID.unique(),
          formData
        ),

        // Then send to Express backend
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/submit-form`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },

          body: JSON.stringify(formData),
        }).then(async (response) => {
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Failed to submit form");
          }
        }),
      ]);
      // Close the modal and show success message
      setIsModalOpen(false);
      setIsSuccessOpen(true);
      resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrors({ submit: "Failed to submit form. Please try again." });
    } finally {
      setIsLoading(false);
    }
  };
  const closeSuccessModal = () => {
    setIsSuccessOpen(false);
    resetForm();
  };

  useEffect(() => {
    if (casestudyData) {
      const foundCaseStudy = casestudyData.find(
        (study) => titleToSlug(study.case_title) === title
      );
      setCurrentCaseStudy(foundCaseStudy);
    }
  }, [casestudyData, title]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (casestudyLoading || !currentCaseStudy) {
    return (
      <div className="w-full flex justify-center items-center min-h-screen">
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    );
  }

  return (
    <div>
      {/* <Helmet>
        <title>{currentCaseStudy?.case_title} | Random Walk Innovations</title>
        <meta
          property="og:title"
          content={`${currentCaseStudy?.case_title} | Random Walk Innovations`}
        />
        <meta name="description" content={currentCaseStudy?.meta_desc} />
        <meta property="og:description" content={currentCaseStudy?.meta_desc} />
        <link
          rel="canonical"
          href={`https://randomwalk.ai/casestudy/${title}`}
        />
      </Helmet> */}





      <section className="mt-10 bg-[#3E59B70D] text-[#666666] py-16 px-4 md:px-10 ">
        <h1 className="text-2xl md:text-4xl font-bold mb-6">
          {currentCaseStudy.case_title}
        </h1>

        <div className="md:w-full flex flex-col">
          <h3 className="text-[#3E59B7] font-bold pb-2 ">INSIGHTS FROM</h3>
          <div className="flex items-center space-x-4 mb-8">
            <img
              src={currentCaseStudy.author_img?.url}
              alt={currentCaseStudy.author_name || "Author"}
              className="w-16 h-16 rounded-full object-cover"
            />
            <div>
              <h3 className="text-xl font-semibold">
                {currentCaseStudy.author_name || "Author Name"}
              </h3>
            </div>
          </div>

          <div className="flex flex-col md:flex-row  space-y-6 md:space-y-0 md:space-x-20">
            {/* Use Cases */}
            <div className="flex flex-col">
              <h3 className="text-lg lg:text-nowrap  text-[#3E59B7] font-semibold mb-5">
                Use Cases
              </h3>
              <div className="space-y-4 lg:text-nowrap md:text-sm 2xl:text-xl text-sm">
                <CustomMarkdownRenderer
                  content={currentCaseStudy.usecases}
                  setHeadings={() => {}}
                />
              </div>
            </div>

            {/* Departments */}
            <div className="flex flex-col">
              <h3 className="text-lg lg:text-nowrap md:mt-5 lg:mt-0 font-semibold mb-5 text-[#3E59B7]">
                Department
              </h3>
              <div className="space-y-4 text-base">
                <div className="space-y-4 lg:text-nowrap md:text-sm 2xl:text-xl text-sm">
                  <CustomMarkdownRenderer
                    content={currentCaseStudy.departments}
                    setHeadings={() => {}}
                  />
                </div>
              </div>
            </div>

            <div className="bg-white text-[#666666] p-4 text-sm md:text-sm 2xl:text-xl rounded-lg shadow-md">
              <div className="flex flex-col">
                {currentCaseStudy.header_img?.url && (
                  <img
                    src={currentCaseStudy.header_img.url}
                    alt="Author"
                    className="w-40 h-20 object-cover"
                  />
                )}
                <CustomMarkdownRenderer
                  content={currentCaseStudy.header_desc}
                  setHeadings={() => {}}
                />
              </div>
            </div>
          </div>
        </div>
      </section>





      {/* Key Results and Image */}
      <section className="bg-white max-md:hidden text-[#3E59B7] md:py-10 px-4 md:px-10">
        <div className="md:max-w-[90rem] 2xl:max-w-full  mx-auto">
          {" "}
          {/* Changed from max-w-6xl to allow wider content */}
          <div className="flex flex-col md:flex-row md:space-x-6 ">
            {/* Left Section: Sticky Key Results and Button */}
            <div className=" md:w-5/12 lg:w-4/12 2xl:w-4/12 md:sticky md:top-4 md:h-fit flex flex-col ">
              {" "}
              {/* Added specific widths for larger screens and left margin */}
              <div className=" md:mt-20  bg-gradient-to-r from-[#3E59B7] to-[#1B2751] bg-clip-text text-transparent">
                <p className="text-[#3E59B7] md:text-base lg:text-xl 2xl:text-2xl font-bold">
                  Key Results
                </p>
                <CustomMarkdownRenderer
                  content={currentCaseStudy.key_results}
                  setHeadings={() => {}}
                />
              </div>
              <p className="font-bold py-5 md:text-sm lg:text-lg">
                See what Random Walk can do for you
              </p>
              <button
                className="bg-gradient-to-r w-full from-[#3E59B7] to-[#1B2751] text-white font-bold py-4 px-10 rounded-lg"
                onClick={handleButtonClickcontact}
              >
                Contact us
              </button>
            </div>

            {/* Right Section: Image and Description */}
            <div className="md:w-7/12 lg:w-8/12 2xl:w-8/12 mt-6 md:mt-0">
              {" "}
              {/* Adjusted widths for larger screens */}
              {currentCaseStudy.case_img && (
                <div className="text-[#666666] p-4 rounded-lg ">
                  <img
                    src={
                      currentCaseStudy.case_img.url ||
                      currentCaseStudy.case_img.formats?.medium?.url
                    }
                    alt={
                      currentCaseStudy.case_img.alternativeText ||
                      "Case Study Image"
                    }
                    className="w-full h-auto rounded-lg"
                  />
                </div>
              )}
              <div className="text-[#666666] p-4 rounded-lg mt-6  md:text-sm 2xl:text-xl ">
                <CustomMarkdownRenderer
                  content={currentCaseStudy.desc}
                  setHeadings={() => {}}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="md:hidden bg-white text-[#3E59B7] py-0 px-4 md:px-10">
        <div className="max-w-6xl mx-auto">
          <div className="flex flex-col md:flex-row md:space-x-6">
            {/* Right Section: Image and Description */}
            <div className="md:w-6/12 order-1 md:order-none mt-3 md:mt-0">
              {currentCaseStudy.case_img && (
                <div className="text-[#666666] p-4 rounded-lg">
                  <img
                    src={
                      currentCaseStudy.case_img.url ||
                      currentCaseStudy.case_img.formats?.medium?.url
                    }
                    alt={
                      currentCaseStudy.case_img.alternativeText ||
                      "Case Study Image"
                    }
                    className="w-full h-auto rounded-lg"
                  />
                </div>
              )}
              {/* Description below the image */}
              <div className="text-[#666666] p-4 text-sm   rounded-lg">
                <CustomMarkdownRenderer
                  content={currentCaseStudy.desc}
                  setHeadings={() => {}}
                />
              </div>
            </div>

            {/* Left Section: Key Results and Button */}
            <div className="md:w-6/12 md:-mt-0  -mt-10 flex flex-col order-2 md:order-none">
              <div className=" bg-gradient-to-r from-[#3E59B7] to-[#1B2751] bg-clip-text text-transparent mt-6 md:mt-0">
                <h3 className="text-[#3E59B7]  font-bold">Key Results</h3>
                <CustomMarkdownRenderer
                  content={currentCaseStudy.key_results}
                  setHeadings={() => {}}
                />
              </div>
              <p className="font-bold py-5 md:text-sm lg:text-lg">
                See what Random Walk can do for you
              </p>
              <button
                className="bg-gradient-to-r md:w-8/12 my-5 md:my-0 from-[#3E59B7] to-[#1B2751] text-white font-bold  py-3 px-10 md:py-4 md:px-10 rounded-lg"
                onClick={handleButtonClickcontact}
              >
                Contact us
              </button>
            </div>
          </div>
        </div>
      </section>
      <div className="border-t md:hidden border-gray-300 my-4"></div>
      {/* Recent Case Studies Section */}

      <section className="pb-10 px-5 mt-5  2xl:px-96 md:px-10 md:mt-10 2xl:mt-24 lg:mx-16">
        <h2 className="text-xl md:text-3xl font-bold text-left mb-8 text-[#3E59B7]">
          More Case Studies
        </h2>

        {/* Wrapper for mobile horizontal scroll */}
        <div className="flex md:grid grid-cols-1 md:grid-cols-3 gap-6 overflow-x-scroll md:overflow-visible scrollbar-hide">
          {casestudyData
            .filter(
              (study) =>
                study.case_category === currentCaseStudy.case_category &&
                study.id !== currentCaseStudy.id
            )
            .sort((a, b) => new Date(b.case_date) - new Date(a.case_date))
            .slice(0, 3)
            .map((caseStudy) => (
              <Link
                key={caseStudy.id}
                to={`/casestudy/${titleToSlug(caseStudy.case_title)}`}
                className="bg-white rounded-xl overflow-hidden shadow-xl flex-shrink-0 w-80 md:w-full 2xl:w-11/12 lg:w-11/12 flex flex-col relative h-full hover:shadow-2xl transition-shadow duration-300"
              >
                <img
                  className="object-cover h-56 w-full"
                  src={caseStudy.case_img?.url || casestudy1}
                  alt={caseStudy.case_title}
                />
                <div className="p-4 flex flex-col flex-grow">
                  <h3 className="text-lg font-display font-bold my-3 text-[#666666]">
                    {caseStudy.case_title}
                  </h3>
                  <p
                    className="text-[#666666] font-display text-sm my-3 overflow-hidden flex-grow"
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 3,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {caseStudy.case_desc}
                  </p>
                  <div className="flex justify-between items-center">
                    <div className="ml-auto">
                      <img
                        src={rightarrow}
                        alt="Case Study Arrow"
                        className="w-4 h-4"
                      />
                    </div>
                  </div>
                </div>
              </Link>
            ))}
        </div>
      </section>

      {isModalOpen && (
        <div className="fixed z-50 inset-0 md:p-0 p-10  bg-black bg-opacity-50 flex items-center justify-center">
          <div className="relative bg-[#F1F6FF]   p-8 lg:px-10 rounded-lg max-h-screen   max-w-md w-full">
            <button
              className="absolute top-2 right-2 text-[#666666] hover:text-[#666666]"
              onClick={closeModal}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>

            <form onSubmit={handleSubmitcontact}>
              <div className="max-h-[400px] overflow-y-auto custom-scrollbar pr-4 ">
                <div className="mb-4">
                  <label className=" text-[#666666]">First Name</label>
                  <input
                    type="text"
                    id="firstname"
                    name="firstname"
                    value={firstname}
                    onChange={(e) => setfirstname(e.target.value)}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                  {errors.firstname && (
                    <p className="text-red-500 text-sm">{errors.firstname}</p>
                  )}
                </div>
                <div className="mb-4">
                  <label className=" text-[#666666]">Last Name</label>
                  <input
                    type="text"
                    name="lastname"
                    id="lastname5"
                    value={lastname}
                    onChange={(e) => setlastname(e.target.value)}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                  {errors.lastname && (
                    <p className="text-red-500 text-sm">{errors.lastname}</p>
                  )}
                </div>

                <div className="mb-4 w-full">
                  <label
                    htmlFor="phoneNumber"
                    className="block text-[#666666] text-sm mb-2"
                  >
                    Phone Number
                  </label>
                  <PhoneInput
                    country={"in"}
                    type="tel"
                    id="Phonenumber"
                    required
                    name="phonenumber"
                    value={phoneNumber}
                    onChange={(number, country) =>
                      handlePhoneNumberChange(number, country)
                    }
                    inputClass="custom-phone-input2"
                    buttonClass="border rounded"
                    dropdownClass="border rounded"
                    placeholder="Enter Phone Number"
                    enableSearch={true}
                    inputProps={{
                      required: true,
                      autoFocus: false,
                    }}
                  />
                  {errors.phoneNumber && (
                    <p className="text-red-500 text-sm">{errors.phoneNumber}</p>
                  )}
                </div>
                <div className="mb-4">
                  <label className="text-[#666666]">Company Name</label>
                  <input
                    type="text"
                    id="companyname"
                    name="companyName"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                  {errors.companyName && (
                    <p className="text-red-500 text-sm">{errors.companyName}</p>
                  )}
                </div>
                <div className="mb-4">
                  <label className=" text-[#666666]">Business Email</label>
                  <input
                    type="email"
                    id="businessemail"
                    name="businessemail"
                    value={businessemail}
                    onChange={(e) => setEmailId(e.target.value)}
                    className="w-full px-3 py-2 border rounded"
                    required
                  />
                  {errors.businessemail && (
                    <p className="text-red-500 text-sm">
                      {errors.businessemail}
                    </p>
                  )}
                </div>

                <div className="mb-4">
                  <label className=" text-[#666666]">
                    Project Details (Optional)
                  </label>
                  <input
                    type="text"
                    id="projectdetails"
                    name="projectdetails"
                    value={projectdetails}
                    onChange={(e) => setprojectdetails(e.target.value)}
                    className="w-full px-3 py-2 border rounded"
                  />
                  {errors.companyName && (
                    <p className="text-red-500 text-sm">{errors.companyName}</p>
                  )}
                </div>
                <div className="disclaimerContainercontact">
                  <label className="checkboxLabel">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      className="checkbox"
                    />
                    <span className="checkboxText">
                      I agree and authorize team to contact me and/or send
                      relevant information over Email, SMS & WhatsApp. This will
                      override the registry with DNC/NDNC.
                    </span>
                  </label>
                </div>
                {errors.terms && (
                  <p className="text-red-500 text-sm">{errors.terms}</p>
                )}
                <div className="linksContainer">
                  <a href="/terms-of-service/" className="link">
                    Terms of Service
                  </a>
                  <a href="/privacy-policy/" className="link">
                    Privacy Policy
                  </a>
                </div>
              </div>
              <div className="mb-4">
                <button
                  id="submit"
                  type="submit"
                  className={`w-full px-4 py-2 bg-[#C62B6D] text-white rounded ${
                    !isChecked || isLoading
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  disabled={!isChecked || isLoading}
                >
                  {isLoading ? (
                    <div className="flex items-center justify-center space-x-2">
                      <svg
                        className="animate-spin h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      <span>Submitting...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isSuccessOpen && (
        <section className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="relative bg-white p-8 rounded-lg max-w-md w-full text-center">
            <a
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={closeSuccessModal}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </a>
            <h2 className="text-2xl font-bold mb-4">Success!</h2>
            <p className="mb-4">Your request has been sent successfully.</p>
            <button
              id="close"
              className="px-4 py-2 bg-[#C62B6D] text-white rounded"
              onClick={closeSuccessModal}
            >
              Close
            </button>
          </div>
        </section>
      )}
    </div>
  );
};

export default Casestudycontent;
