import React from 'react';

const HTMLSitemap = () => {
  return (
    <div className="max-w-6xl mx-auto px-4 py-12">
      <h1 className="text-4xl font-bold mb-12 text-center mt-10">Sitemap</h1>
      
      <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
        {/* Main Section */}
        <div className="bg-white p-6 rounded-lg shadow-lg  ">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Main</h2>
          <ul className="space-y-3 list-disc pl-5"> {/* Added list-disc and pl-5 */}
            <li>
              <a href="/" className="text-blue-600 underline">
                Home
              </a>
            </li>
          </ul>
        </div>

        {/* Services Section */}
        <div className="bg-white p-6 rounded-lg shadow-lg ">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Services</h2>
          <ul className="space-y-3 list-disc pl-5"> {/* Added list-disc and pl-5 */}
            <li>
              <a href="/ai-integration/" className="text-blue-600 underline">
                AI Integration
              </a>
            </li>
            <li>
              <a href="/ai-managed-services/" className="text-blue-600 underline">
                AI Managed Services
              </a>
            </li>
            <li>
              <a href="/ai-workshop-for-corporates/" className="text-blue-600 underline">
                AI Workshop for Corporates
              </a>
            </li>
       
          </ul>
        </div>

        {/* Products Section */}
        <div className="bg-white  p-6 rounded-lg shadow-lg ">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Products</h2>
          <ul className="space-y-3 list-disc pl-5"> {/* Added list-disc and pl-5 */}
          <li>
              <a href="/ai-readiness-index/" className="text-blue-600 underline">
                AI Readiness Index
              </a>
            </li> 
           
           
            <li>
              <a href="/brandcut/" className="text-blue-600 underline">
                BrandCut
              </a>
            </li>
            <li>
              <a href="/fortunecookie/" className="text-blue-600 underline">
                Fortune Cookie
              </a>
            </li>

          </ul>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg ">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Company</h2>
          <ul className="space-y-3 list-disc pl-5"> {/* Added list-disc and pl-5 */}
            <li>
              <a href="/about-us/" className="text-blue-600 underline">
                About Us
              </a>
            </li>
            <li>
              <a href="/careers/" className="text-blue-600 underline">
                Careers
              </a>
            </li>
       
          </ul>
        </div>
        {/* Resources Section */}
        <div className="bg-white p-6 rounded-lg shadow-lg ">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Resources</h2>
          <ul className="space-y-3 list-disc pl-5"> {/* Added list-disc and pl-5 */}
            <li>
              <a href="/blog/" className="text-blue-600 underline">
                Blog
              </a>
            </li>
            <li>
              <a href="/brochures/" className="text-blue-600 underline">
                Brochures
              </a>
            </li>
     
          </ul>
        </div>

        

        {/* Legal Section */}
        <div className="bg-white p-6 rounded-lg shadow-lg ">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Events</h2>
          <ul className="space-y-3 list-disc pl-5"> {/* Added list-disc and pl-5 */}
           
          <li>
              <a href="/podcasts/" className="text-blue-600 underline">
                Podcasts
              </a>
            </li>
            <li>
              <a href="/webinars/" className="text-blue-600 underline">
                Webinars
              </a>
            </li>
            <li>
              <a href="/playlab/" className="text-blue-600 underline">
                Events
              </a>
            </li> 
    
          </ul>
        </div>
        <div className="bg-white p-6 rounded-lg shadow-lg ">
          <h2 className="text-2xl font-semibold mb-4 text-gray-800">Support</h2>
          <ul className="space-y-3 list-disc pl-5"> {/* Added list-disc and pl-5 */}
          <li>
              <a href="/contact-us/" className="text-blue-600 underline">
                Contact Us
              </a>
            </li> 
          <li>
              <a href="/terms-of-service/" className="text-blue-600 underline">
                Terms of Service
              </a>
            </li> 
            <li>
              <a href="/privacy-policy/" className="text-blue-600 underline">
                Privacy Policy
              </a>
            </li> 
           
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HTMLSitemap;