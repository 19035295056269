import React ,{useEffect} from "react";
import { Helmet } from "react-helmet-async";
const Privacy = () => {


  useEffect(() => {
    // Set page title
    document.title = "Privacy Policy | Random Walk";

    // Set meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', "Protecting your privacy is a priority at Random Walk AI. Learn how we protect your personal information, our data practices, and how we ensure compliance with privacy standards.");
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = "Protecting your privacy is a priority at Random Walk AI. Learn how we protect your personal information, our data practices, and how we ensure compliance with privacy standards.";
      document.head.appendChild(meta);
    }

    // Add or update Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement('meta');
      ogTitle.setAttribute('property', 'og:title');
      document.head.appendChild(ogTitle);
    }
    ogTitle.setAttribute('content', "Privacy Policy | Random Walk");

    // Add or update Open Graph description
    let ogDescription = document.querySelector('meta[property="og:description"]');
    if (!ogDescription) {
      ogDescription = document.createElement('meta');
      ogDescription.setAttribute('property', 'og:description');
      document.head.appendChild(ogDescription);
    }
    ogDescription.setAttribute('content', "Protecting your privacy is a priority at Random Walk AI. Learn how we protect your personal information, our data practices, and how we ensure compliance with privacy standards.");

    // Add viewport meta tag
    let viewport = document.querySelector('meta[name="viewport"]');
    if (!viewport) {
      viewport = document.createElement('meta');
      viewport.name = "viewport";
      document.head.appendChild(viewport);
    }
    viewport.setAttribute('content', "width=device-width, initial-scale=1");

    // Add theme-color meta tag
    let themeColor = document.querySelector('meta[name="theme-color"]');
    if (!themeColor) {
      themeColor = document.createElement('meta');
      themeColor.name = "theme-color";
      document.head.appendChild(themeColor);
    }
    themeColor.setAttribute('content', "#000000");

    // Add Open Graph image
    let ogImage = document.querySelector('meta[property="og:image"]');
    if (!ogImage) {
      ogImage = document.createElement('meta');
      ogImage.setAttribute('property', 'og:image');
      document.head.appendChild(ogImage);
    }
    ogImage.setAttribute('content', `${process.env.PUBLIC_URL}/rw_logo.png`);

    // Add Open Graph type
    let ogType = document.querySelector('meta[property="og:type"]');
    if (!ogType) {
      ogType = document.createElement('meta');
      ogType.setAttribute('property', 'og:type');
      document.head.appendChild(ogType);
    }
    ogType.setAttribute('content', "website");

    // Add canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.rel = "canonical";
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.href = "https://randomwalk.ai/privacy-policy/";
  }, []);


  return (
    <section className="text-[#666666]">
       



       {/* <Helmet>
        <title>Privacy Policy | Random Walk</title>
        <meta property="og:title" content="Privacy Policy | Random Walk" />
        <meta name="description" content="Protecting your privacy is a priority at Random Walk AI. Learn how we protect your personal information, our data practices, and how we ensure compliance with privacy standards." />
        <meta property="og:description" content="Protecting your privacy is a priority at Random Walk AI. Learn how we protect your personal information, our data practices, and how we ensure compliance with privacy standards." />
        <link rel="canonical" href="https://randomwalk.ai/privacy-policy/" />
      </Helmet> */}

      <article className="bg-indigo-200 mt-10 h-96 lg:h-52 flex justify-center items-center">
        <header className="text-[#666666] text-5xl lg:text-center font-bold">
          <p className="  text-center">Privacy Policy</p>
        </header>
      </article>
      <article className="font-display  lg:text-left flex justify-center items-center p-10 lg:px-44">
        <p>
          This privacy policy explains how we at Random Walk collect, use,
          retain and disclose personal data collected from you on our website.
          <br />
          <br />
          <div className="lg:text-2xl font-bold font-display ">WHO WE ARE</div>
          <br />
          Random Walk is a global solution provider specializing in AI
          Integration. We constantly update our technology to suit our
          customer’s needs. We are committed to protecting your personal
          information and your right to privacy.
          <br />
          <br />
          <div className="lg:text-2xl font-bold font-display ">
            Information Collection
          </div>
          <br />
          We collect your Personal Data in the following ways on our website:
          <br />
          <br />
          <div class="font-bold text-lg">
            1. Information Provided By You When You Visit Our Website
          </div>
          <ul class="list-disc pl-5 mt-2">
            <li>
              Personal information you disclose to us, such as name, address,
              email address, and contact information.
            </li>
            <li>
              Information automatically collected, such as IP address and/or
              browser and device characteristics, which is collected
              automatically when you visit our Services.
            </li>
            <li>
              Subscribe by email for and submit your name and email address.
            </li>
            <li>
              As a paying user of our service, we may collect data related to
              your transaction, such as name, the amount paid, and the date of
              payment.
            </li>
            <li>
              Request an Enquiry Form and submit your name, phone, email
              address, and company name.
            </li>
            <li>
              You connect to us on a call where we collect your phone number,
              name, and email address to contact you back.
            </li>
            <li>
              You connect to us by sending us emails where we collect your name
              and email address to contact you back.
            </li>
            <li>
              You submit your resume by emailing it to the specified email
              address.
            </li>
            <br />
          </ul>
          <div class="font-bold text-lg">2. Through Cookies On Our Website</div>
          <br />
          The cookies collect information about you when you visit a website.
          For more information about cookies, please refer to the ‘Cookies
          Information’ section on this Policy. We retain and use your personal
          data as long as it is necessary for the fulfilment of the respective
          purposes as specified in this section.
          <br />
          <br />
          <div className="lg:text-2xl font-bold font-display ">
            Information Collection
          </div>
          <br />
          <div className="lg:text-2xl font-bold font-display ">
            Personal Data
          </div>
          <br />
          <div class=" lg:text-lg">
            1. We process your information for purposes based on legitimate
            business interests, the fulfilment of our contract with you,
            compliance with our legal obligations, and/or your consent.
          </div>
          <br />
          <div class=" lg:text-lg">
            2. We may use your Personal Data to contact you with newsletters,
            marketing or promotional materials and other information that may be
            of interest to you. You may opt out of receiving any, or all, of
            these communications from us by following the unsubscribe link or
            the instructions provided in any email we send
          </div>
          <br />
          <div class=" lg:text-lg">
            3.We use the collected Personal Data for: We retain and use your
            Personal Data as long as it is necessary for the fulfilment of the
            respective purposes as specified in this section.
          </div>
          <ul class="list-disc pl-5 mt-2">
            <li>Providing you with our Solutions and/or Services</li>
            <li>Contacting you for providing our Solutions and/or Services</li>
            <li>Answering your queries or technical issues or complaints</li>
            <li>Tracking your activities on our website</li>
            <li>
              Providing you with the Marketing Material in case you have opted
              for
            </li>
            <li>Sending you updates about our blog</li>
            <li>Recruitment purposes</li>
            <br />
          </ul>
          We retain and use your Personal Data as long as it is necessary for
          the fulfilment of the respective purposes as specified in this
          section.
          <br />
          <br />
          <div class="font-bold lg:text-2xl">
            How Long Do We Keep Your Informations?
          </div>
          <br />
          We keep your information for as long as necessary to fulfil the
          purposes outlined in this privacy policy unless otherwise required by
          law. We will only keep your personal information for as long as it is
          necessary for the purposes set out in this privacy policy unless a
          longer retention period is required
          <br />
          <br />
          <div class="font-bold lg:text-2xl">
            How Do We Keep Your Information Safe?
          </div>
          <br />
          We aim to protect your personal information through a system of
          organizational and technical security measures. We have implemented
          appropriate technical and organizational security measures designed to
          protect the security of any personal information we process.
          <br />
          <br />
          <div class="font-bold lg:text-2xl">Grievance Redressal</div>
          <br />
          If you have any complaints, concerns with regards to the use, storage,
          deletion, and disclosure of your personal information provided to us,
          you may reach out to us using the contact form.
        </p>
      </article>
    </section>
  );
};
export default Privacy;