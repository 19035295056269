import React from "react";
import dashboardImgMob from "../assets/first-mob.webp";
import dashboardImg from "../assets/moitor2.webp";
import pattern from "../assets/pattern.svg";
import { isMobile } from "../config.tsx";
import styles from "../styles/SectionTwo.module.css";

const SectionTwo = ({ onOpenLeadForm }) => {
  const renderOnMobile = isMobile();
  return (
    <section className={styles.section}>
      <h1 className={styles.heading}>
        <span className={styles.highlight}>AI Powered</span> <br />
        Brand Sponsorship Analysis
      </h1>
      <div className={styles.content}>
        <div className={styles.imageWrapper}>
          {/* <img className={styles.pattern} src={pattern} alt="Pattern" /> */}
          <img
            className="absolute lg:ml-[150px] ml-[40px] w-[250px] h-[280px] md:w-[900px] md:h-[500px]  lg:w-[800px] lg:h-[390px] z-0"
            src={pattern}
            alt="Pattern"
          />

          {/* <img className={styles.dashboard} src={renderOnMobile ? dashboardImgMob : dashboardImg} alt='Dashboard' /> */}
          <img
            className={`relative w-[280px] h-[220px] md:w-[480px] md:h-[430px] rounded-lg z-10 ${
              renderOnMobile ? "mobile-dashboard-styles" : ""
            }`}
            src={renderOnMobile ? dashboardImgMob : dashboardImg}
            alt="Dashboard"
          />
        </div>
        <div className={styles.features}>
          <ol>
            <li>
            Innovative AI Sponsorship Tracking for Brand Managers and Event Sponsors
 
            </li>
            <li>
            AI-Driven Technology for Real-Time Logo Detection and Sponsorship Analysis
            </li>
            <li>
            Comprehensive Metrics and Actionable Insights for Informed Decision-Making
            </li>
          </ol>
          <button onClick={onOpenLeadForm} className={styles.button}>
            See How It Works
          </button>
        </div>
      </div>
    </section>
  );
};

export default SectionTwo;
