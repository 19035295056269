import Footer from "../components/Footer";
import React, { useState, useEffect, useRef } from "react";
import { Client, Databases, ID } from "appwrite";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import contact1 from "../assets/Contact/contact1.webp";
import "./contact.css";
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from "libphonenumber-js";
import contactt2 from "../assets/Contact/contact2.svg";
import contactt3 from "../assets/Contact/twitter.webp";
import contactt4 from "../assets/Contact/youtube.webp";
import { Helmet } from "react-helmet-async";

const Contact = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [businessemail, setEmailId] = useState("");
  const [projectdetails, setprojectdetails] = useState("");
 

  const [utmParams, setUtmParams] = useState({
    utmSource: "",
    utmMedium: "",
    utmCampaign: "",
    utmId: "",
    utmTerm: "",
    utmContent: "contact-us" // Hardcoded as requested
  });

  const [errors, setErrors] = useState({});

  const [errorsphone, setErrorsphone] = useState({ phoneNumber: "" });
  const resetForm = () => {
    setfirstname("");
    setlastname("");
    setPhoneNumber("");
    setCompanyName("");
    setEmailId("");
    setprojectdetails("");
    setIsChecked(true);
    setErrors({});
    setErrorsphone({ phoneNumber: "" });
  };

  const handleButtonClickcontact = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const client = new Client()
    .setEndpoint(process.env.REACT_APP_ENDPOINT)
    .setProject(process.env.REACT_APP_PROJECT_ID);

  const databases = new Databases(client);

  const handlePhoneNumberChange = (number, country) => {
    setPhoneNumber(number);

    try {
      // Ensure number starts with +
      const fullNumber = number.startsWith("+") ? number : `+${number}`;

      // Check if the number is valid for the selected country
      if (isValidPhoneNumber(fullNumber)) {
        const phoneNumberObj = parsePhoneNumberFromString(fullNumber);
        const nationalNumber = phoneNumberObj.nationalNumber;

        // Get expected length based on country
        const expectedLength = phoneNumberObj.nationalNumber?.length;
        const actualLength = nationalNumber?.length;

        if (actualLength < expectedLength) {
          setErrors((prev) => ({
            ...prev,
            phoneNumber: `Phone number is too short - needs ${expectedLength} digits`,
          }));
        } else if (actualLength > expectedLength) {
          setErrors((prev) => ({
            ...prev,
            phoneNumber: `Phone number is too long - should be ${expectedLength} digits`,
          }));
        } else {
          setErrors((prev) => ({ ...prev, phoneNumber: "" }));
        }
      } else {
        setErrors((prev) => ({
          ...prev,
          phoneNumber: "Please enter a valid phone number",
        }));
      }
    } catch (error) {
      console.error("Phone validation error:", error);
      setErrors((prev) => ({
        ...prev,
        phoneNumber: "Please enter a valid phone number",
      }));
    }
  };

  
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    
    setUtmParams({
      utmSource: searchParams.get("utm_source") || "",
      utmMedium: searchParams.get("utm_medium") || "",
      utmCampaign: searchParams.get("utm_campaign") || "",
      utmId: searchParams.get("utm_id") || "",
      utmTerm: searchParams.get("utm_term") || "",
      utmContent: "contact-us" // Always set to "podcast" regardless of URL parameter
    });
  }, []);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true); 

    try {
      // Ensure number starts with +
      const fullNumber = phoneNumber.startsWith("+")
        ? phoneNumber
        : `+${phoneNumber}`;

      if (!isValidPhoneNumber(fullNumber)) {
        setErrors((prev) => ({
          ...prev,
          phoneNumber: "Please enter a valid phone number",
        }));
        return;
      }
      const formData={
        firstname,
        lastname,
        businessemail,
        companyName,
        phoneNumber,
        projectdetails,
        ...utmParams // Spread the UTM parameters into the form data
      }

      // If validation passes, proceed with form submission
      await Promise.all([
        databases.createDocument(
        process.env.REACT_APP_DATABASE_ID,
        process.env.REACT_APP_COLLECTION_ID2,
        ID.unique(),
       formData
      ),
      fetch(`${process.env.REACT_APP_BACKEND_URL}/api/submit-form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      }).then(async (response) => {
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit form');
      }
    })
  ]);
  
      setIsModalOpen(false);
      setIsSuccessOpen(true);
      resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrors({ submit: "Failed to submit form. Please try again." });
    } finally {
      setIsLoading(false);
    }
  };

  const closeSuccessModal = () => {
    setIsSuccessOpen(false);
  };
  const signupRef = useRef(null);

  useEffect(() => {
    if (window.location.hash === "#signup") {
      signupRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  useEffect(() => {
    // Set page title
    document.title = "Connect with Random Walk AI for Expert AI Solutions & Services";

    // Set meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', "Looking for AI expertise? Reach out to Random Walk AI for tailored AI solutions. Our expert AI team is ready to assist with your AI-driven business goals.");
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = "Looking for AI expertise? Reach out to Random Walk AI for tailored AI solutions. Our expert AI team is ready to assist with your AI-driven business goals.";
      document.head.appendChild(meta);
    }

    // Add or update Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement('meta');
      ogTitle.setAttribute('property', 'og:title');
      document.head.appendChild(ogTitle);
    }
    ogTitle.setAttribute('content', "Connect with Random Walk AI for Expert AI Solutions & Services");

    // Add or update Open Graph description
    let ogDescription = document.querySelector('meta[property="og:description"]');
    if (!ogDescription) {
      ogDescription = document.createElement('meta');
      ogDescription.setAttribute('property', 'og:description');
      document.head.appendChild(ogDescription);
    }
    ogDescription.setAttribute('content', "Looking for AI expertise? Reach out to Random Walk AI for tailored AI solutions. Our expert AI team is ready to assist with your AI-driven business goals.");

    // Add viewport meta tag
    let viewport = document.querySelector('meta[name="viewport"]');
    if (!viewport) {
      viewport = document.createElement('meta');
      viewport.name = "viewport";
      document.head.appendChild(viewport);
    }
    viewport.setAttribute('content', "width=device-width, initial-scale=1");

    // Add theme-color meta tag
    let themeColor = document.querySelector('meta[name="theme-color"]');
    if (!themeColor) {
      themeColor = document.createElement('meta');
      themeColor.name = "theme-color";
      document.head.appendChild(themeColor);
    }
    themeColor.setAttribute('content', "#000000");

    // Add Open Graph image
    let ogImage = document.querySelector('meta[property="og:image"]');
    if (!ogImage) {
      ogImage = document.createElement('meta');
      ogImage.setAttribute('property', 'og:image');
      document.head.appendChild(ogImage);
    }
    ogImage.setAttribute('content', `${process.env.PUBLIC_URL}/rw_logo.png`);

    // Add Open Graph type
    let ogType = document.querySelector('meta[property="og:type"]');
    if (!ogType) {
      ogType = document.createElement('meta');
      ogType.setAttribute('property', 'og:type');
      document.head.appendChild(ogType);
    }
    ogType.setAttribute('content', "website");

    // Add canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.rel = "canonical";
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.href = "https://randomwalk.ai/contact-us/";
  }, []);

  
  return (
    <section className="Main2 font-display text-[#666666]">
    {/* <Helmet>
        <title>Connect with Random Walk AI for Expert AI Solutions & Services</title>
        <meta
          property="og:title"
          content="Connect with Random Walk AI for Expert AI Solutions & Services"
        />
        <meta
          name="description"
          content="Looking for AI expertise? Reach out to Random Walk AI for tailored AI solutions. Our expert AI team is ready to assist with your AI-driven business goals."
        />
        <meta
          property="og:description"
          content="Looking for AI expertise? Reach out to Random Walk AI for tailored AI solutions. Our expert AI team is ready to assist with your AI-driven business goals."
        />
        <link rel="canonical" href="https://randomwalk.ai/contact-us/" />
      </Helmet> */}
      <div className=" lg:py-32 bg-gradient_background5 flex ">
        <div className="flex flex-col md:flex-row w-full ">
          <figure className="max-md:hidden  mb-4 md:translate-y-52 lg:translate-y-20  md:mb-0 md:mr-4">
            <img
              src={contact1}
              alt="Conatct_us_img1"
              className="w-full md:w-auto"
            />
          </figure>

          <aside className="flex flex-col pt-24 px-5 md:px-0 md:pt-0 text-[#666666] md:justify-center">
            <p className="md:text-4xl md:pr-28 text-xl font-display">
              <p className="font-extrabold text-4xl md:text-4xl  text-[#666666] block mb-2">
                Contact
              </p>
              <p className="text-indigo-400  text-2xl font-bold md:text-xl block mb-1">
                Find Us On
              </p>
              <p className=" max-md:hidden md:text-sm text-sm mb-3">
                Get in touch with our <br /> team to discuss about
                <br /> your project.
              </p>
              <p className="md:hidden text-base   mb-3">
                Get in touch with our team to discuss about your project.
              </p>
              <p className="text-[#666666]  text-base md:text-sm pb-1">
                <span className="font-bold mr-1">Call us:</span>
                +91 784 575 7355
              </p>
              <p className="text-[#666666]  text-base md:text-sm mb-3">
                <span className="font-bold mr-1">Email:</span>
                enquiry@randomwalk.ai
              </p>

              <p className="flex  flex-row mb-3 ">
                <a
                  href="https://www.linkedin.com/company/randomwalk-ai/mycompany/verification/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={contactt2}
                    alt="Contact Icon"
                    className="h-8 mt-[2px]  w-8 mr-4"
                  />
                </a>
                <a
                  href="https://x.com/i/flow/login?redirect_after_login=%2Frandomwalk_ai"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={contactt3} alt="Contact Icon" className="h-9 w-9" />
                </a>
                <a
                  href="https://www.youtube.com/@Randomwalk_AI"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={contactt4}
                    alt="Contact Icon"
                    className="h-11 w-11   mt-[-4px] ml-4"
                  />
                </a>
              </p>
            </p>
          </aside>

          <section className=" lg:pt-10 p-5  md:py-24  md:w-5/12 md:pr-10  z-20 relative ">
            <figure className=" max-md:hidden  absolute top-0  md:left-2 lg:left-0 -z-10 md:translate-y-20  md:w-10/12 lg:w-auto lg:translate-x-[350px]">
              <img src={contact1} alt="Contact_us_img2" className=" " />
            </figure>
            <form
              onSubmit={handleSubmit}
              className="bg-white 2xl:-mt-5  p-5 md:p-4 shadow-md rounded"
            >
              <div className="mb-4">
                <label
                  htmlFor="name"
                  className="block text-gray-700 text-sm mb-2"
                >
                  First Name
                </label>
                <input
                  type="text"
                  id="firstname"
                  name="firstname"
                  value={firstname}
                  onChange={(e) => setfirstname(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 bg-indigo-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter first name"
                  required
                />
                {errors.firstname && (
                  <p className="text-red-500 text-sm">{errors.firstname}</p>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="lastName"
                  className="block text-gray-700 text-sm mb-2"
                >
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastname4"
                  name="lastName"
                  value={lastname}
                  onChange={(e) => setlastname(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 bg-indigo-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter last name"
                  required
                />
                {errors.lastname && (
                  <p className="text-red-500 text-sm">{errors.lastname}</p>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="number"
                  className="block text-gray-700 text-sm mb-2"
                >
                  Phone Number
                </label>

                <PhoneInput
                  country={"in"}
                  value={phoneNumber}
                  onChange={(number, country) =>
                    handlePhoneNumberChange(number, country)
                  }
                  inputClass="custom-phone-input3 shadow appearance-none border rounded w-full py-2 px-3 bg-indigo-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  buttonClass="border rounded"
                  dropdownClass="border rounded"
                  required
                  enableSearch={true}
                  inputProps={{
                    required: true,
                    autoFocus: false,
                  }}
                />

                {errors.phoneNumber && (
                  <p className="text-red-500 text-sm">{errors.phoneNumber}</p>
                )}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="companyName"
                  className="block text-gray-700 text-sm mb-2"
                >
                  Company Name
                </label>
                <input
                  type="text"
                  id="Companyname"
                  name="companyName"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 bg-indigo-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter company name"
                  required
                />
                {errors.companyName && (
                  <p className="text-red-500 text-sm">{errors.companyName}</p>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-gray-700 text-sm mb-2"
                >
                  Business Email
                </label>
                <input
                  type="email"
                  id="businessemail"
                  name="email"
                  value={businessemail}
                  onChange={(e) => setEmailId(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 bg-indigo-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter email address"
                  required
                />
                {errors.businessemail && (
                  <p className="text-red-500 text-sm">{errors.businessemail}</p>
                )}
              </div>

              <div className="mb-4">
                <label
                  htmlFor="project"
                  className="block text-gray-700 text-sm mb-2"
                >
                  Project Details (Optional){" "}
                </label>
                <input
                  type="text"
                  id="projectdetails"
                  name="project"
                  value={projectdetails}
                  onChange={(e) => setprojectdetails(e.target.value)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 bg-indigo-100 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  placeholder="Enter project details"
                />
                {errors.companyName && (
                  <p className="text-red-500 text-sm">{errors.companyName}</p>
                )}
              </div>
              <div className="disclaimerContainer">
                <label className="checkboxLabel">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    className="checkbox"
                  />
                  <span className="checkboxText">
                    I agree and authorize team to contact me and/or send
                    relevant information over Email, SMS & WhatsApp. This will
                    override the registry with DNC/NDNC.
                  </span>
                </label>
              </div>
              {errors.terms && (
                <p className="text-red-500 text-sm">{errors.terms}</p>
              )}
              <div className="linksContainer">
                <a href="/terms-of-service/" className="link">
                  Terms of Service
                </a>
                <a href="/privacy-policy/" className="link">
                  Privacy Policy
                </a>
              </div>

              <div className="flex items-center justify-center mt-5">
                <button
                  id="submit"
                  type="submit"
                  className={`bg-gray-500 hover:text-white border hover:border-none border-none hover:bg-gray-500 rounded w-full py-3 text-white font-bold focus:outline-none focus:shadow-outline ${
                    !isChecked  || isLoading ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                  disabled={!isChecked || isLoading}
                >
                {isLoading ? (
      <div className="flex items-center justify-center space-x-2">
        <svg
          className="animate-spin h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          />
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          />
        </svg>
        <span>Submitting...</span>
      </div>
    ) : (
      "Submit"
    )}
                </button>
              </div>
            </form>
          </section>
        </div>
      </div>

      {isSuccessOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="relative bg-white p-8 rounded-lg max-w-md w-full text-center">
            <a
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={closeSuccessModal}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </a>
            <h2 className="text-2xl font-bold mb-4">Success!</h2>
            <p className="mb-4">Your request has been sent successfully.</p>
            <button
              id="close"
              className="px-4 py-2 bg-[#C62B6D] text-white rounded"
              onClick={closeSuccessModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
};
export default Contact;
