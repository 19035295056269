import React, { useRef ,useEffect} from "react";
import hang from "../assets/Managed/hanging.webp";
import mann1 from "../assets/Managed/man1.webp";
import mann2 from "../assets/Managed/man2.webp";
import mann3 from "../assets/Managed/man3.webp";
import mann4 from "../assets/Managed/man4.webp";
import man7 from "../assets/Managed/man7.webp";
import mann6 from "../assets/Managed/man6.webp";
import cubes from "../assets/Managed/cubes.webp";
import pink from "../assets/Managed/pink.webp";
import lap from "../assets/Managed/lap.webp";
import bubble1 from "../assets/Managed/bubble1.webp";
import { Helmet } from "react-helmet-async";
import line from "../assets/Managed/line.webp";
import triangle from "../assets/Managed/triangle.webp";

import "./Managed.css";

import Popup from "../components/popup.js";

const Managed = () => {
  const container = useRef();


  useEffect(() => {
    // Set page title
    document.title = "Random Walk | Tailored AI Managed Services for Businesses";

    // Set meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', "Need flexible AI support? Random Walk offers scalable AI managed services with expert support, cost efficiency, and seamless AI integration for a risk-free AI journey.");
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = "Need flexible AI support? Random Walk offers scalable AI managed services with expert support, cost efficiency, and seamless AI integration for a risk-free AI journey.";
      document.head.appendChild(meta);
    }

    // Add or update Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement('meta');
      ogTitle.setAttribute('property', 'og:title');
      document.head.appendChild(ogTitle);
    }
    ogTitle.setAttribute('content', "Random Walk | Tailored AI Managed Services for Businesses");

    // Add or update Open Graph description
    let ogDescription = document.querySelector('meta[property="og:description"]');
    if (!ogDescription) {
      ogDescription = document.createElement('meta');
      ogDescription.setAttribute('property', 'og:description');
      document.head.appendChild(ogDescription);
    }
    ogDescription.setAttribute('content', "Need flexible AI support? Random Walk offers scalable AI managed services with expert support, cost efficiency, and seamless AI integration for a risk-free AI journey.");

    // Add viewport meta tag
    let viewport = document.querySelector('meta[name="viewport"]');
    if (!viewport) {
      viewport = document.createElement('meta');
      viewport.name = "viewport";
      document.head.appendChild(viewport);
    }
    viewport.setAttribute('content', "width=device-width, initial-scale=1");

    // Add theme-color meta tag
    let themeColor = document.querySelector('meta[name="theme-color"]');
    if (!themeColor) {
      themeColor = document.createElement('meta');
      themeColor.name = "theme-color";
      document.head.appendChild(themeColor);
    }
    themeColor.setAttribute('content', "#000000");

    // Add Open Graph image
    let ogImage = document.querySelector('meta[property="og:image"]');
    if (!ogImage) {
      ogImage = document.createElement('meta');
      ogImage.setAttribute('property', 'og:image');
      document.head.appendChild(ogImage);
    }
    ogImage.setAttribute('content', `${process.env.PUBLIC_URL}/rw_logo.png`);

    // Add Open Graph type
    let ogType = document.querySelector('meta[property="og:type"]');
    if (!ogType) {
      ogType = document.createElement('meta');
      ogType.setAttribute('property', 'og:type');
      document.head.appendChild(ogType);
    }
    ogType.setAttribute('content', "website");

    // Add canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.rel = "canonical";
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.href = "https://randomwalk.ai/ai-managed-services/";
  }, []);
  
    useEffect(() => {
      const link = document.createElement("link");
      link.rel = "preload";
      link.as = "image";
      link.href = lap; // Image source
      document.head.appendChild(link);
  
      return () => {
        document.head.removeChild(link); // Cleanup on component unmount
      };
    }, []);
  return (
    <section className="Main2 font-display mt-16  md:mt-0">
      {/* <Helmet>
        <title>Random Walk | Tailored AI Managed Services for Businesses</title>
        <meta property="og:title" content="Random Walk | Tailored AI Managed Services for Businesses" />
        <meta
          name="description"
          content="Need flexible AI support? Random Walk offers scalable AI managed services with expert support, cost efficiency, and seamless AI integration for a risk-free AI journey."
        />
        <meta
          property="og:description"
          content="Need flexible AI support? Random Walk offers scalable AI managed services with expert support, cost efficiency, and seamless AI integration for a risk-free AI journey."
        />
        <link
          rel="canonical"
          href="https://randomwalk.ai/ai-managed-services/"
        />
      </Helmet> */}

      <section class="bg-custom-manage h-72 md:h-screen flex justify-center items-center">
        <article class="flex flex-row items-center text-md font-bold px-8 md:px-0 md:text-4xl lg:text-5xl 2xl:text-6xl lg:pl-10 lg:max-w-screen-2xl">
          <p class="md:text-left font-bold text-[#666666] md:ml-8 md:w-1/2 2xl:w-3/4">
            Your Developers Our AI Developers.
            <br />
            <span class="text-[#C432F9]">Integrated.</span>
          </p>

          <img
            src={cubes}
            alt="Ai_Managed-services"
            class="w-[180px] h-[165px] md:w-[320px] md:h-[550px] 2xl:w-[1200px] 2xl:h-[850px] lg:w-[630px] lg:h-[650px] md:py-32 lg:px-20"
          />
        </article>
      </section>

      <section className=" flex lg:justify-center  ">
        <div className=" w-full lg:max-w-screen-lg 2xl:max-w-screen-2xl mr-0 lg:mr-12 ">
          <div class="lg:flex justify-center items-center ">
            <div class="flex flex-col md:flex-row items-center">
              <div class="font-display text-xs lg:text-xs lg:pt-10   ">
                <img
                  src={pink}
                  alt="AI_Managed_Services_img1"
                  class="  absolute -left-8 lg:-left-10 2xl:-left-12 w-20 2xl:w-32  md:w-fit lg:w-fit  2xl:w-fit"
                />
                <img
                  src={lap}
                  alt="AI_Managed_Services_img2"
                  loading="eager"
          fetchpriority="high" 
                  class="block md:hidden rounded-tl-none rounded-2xl w-[320px] h-[210px] mx-auto mt-10"
                />

                <article className="md:px-5  md:mt-10 mt-10 px-10  pr-10 md:pl-16 ">
                  <h2 className="relative text-[#666666]  font-display font-bold text-sm lg:text-3xl md:text-lg text-left lg:font-bold pb-2 lg:pb-5  ">
                    AI Managed Services
                    <br />
                  </h2>
                  <p className="md:pb-3  lg:text-base md:text-xs text-xs  2xl:text-lg text-[#666666]  pb-2">
                    As a business testing out the waters, you may not have the
                    inclination to deploy and invest in a full-fledged AI team.
                    In other cases, a small AI team at your end may need more
                    resources to bring an AI integration project to fruition in
                    a time-bound manner.
                  </p>
                  <p className="md:pb-3 lg:text-base md:text-xs text-xs 2xl:text-lg text-[#666666]  pb-2 ">
                    Random Walk provides tenured AI professionals who bring vast
                    experience and talent into each engagement, delivering AI
                    services and AI solutions that drive impactful results. The
                    cost savings this model offers mitigate the risk of
                    exploring uncharted territory in AI for business.
                  </p>
                  <p className="md:pb-3 lg:text-base md:text-xs text-xs  2xl:text-lg text-[#666666] pb-2 ">
                    Having worked with businesses similar to yours, we have
                    imbibed best practices and learnings from integrating AI
                    tools into applications. Random Walk’s expert engineers will
                    support your AI development efforts, build a case around new
                    situations, and implement AI integration swiftly.
                  </p>
                  <p className="md:pb-3 lg:text-base md:text-xs text-xs   2xl:text-lg text-[#666666]  pb-2 ">
                    Discover the benefits of AI Managed Services with Random
                    Walk – your trusted partner in navigating the complexities
                    of AI integration.
                  </p>
                </article>
              </div>

              <img
                src={lap}
                alt="AI_Managed_Services_img3"
                loading="eager" 
          fetchpriority="high" 
                class=" hidden md:block rounded-tl-none  rounded-2xl lg:scale-125 2xl:scale-110   md:h-[288px]  mt-10 md:w-[320px]  lg:w-[340px] lg:h-[260px] lg:mt-20 2xl:w-[500px] 2xl:h-[300px] 2xl:ml-20 lg:ml-20 "
              />
            </div>
          </div>
        </div>
      </section>

      <img
        src={hang}
        alt="AI_Managed_Services_img4"
        className="lg:mt-32  absolute left-0 right-0 -ml-screen mr-screen mt-12 w-full"
      />

      <section className="  mt-24 md:mt-20 lg:mt-56">
        <section className="flex  flex-col md:flex-row">
          <figure className="flex md:hidden justify-center items-center">
            <img src={mann1} alt="AI Integration" className="w-[250px] h-[280px] " />
          </figure>

          <article className="w-full lg:mt-10 md:mt-1 lg:w-1/2 bg-white md:px-10 lg:px-16">
            <div className="relative p-5 rounded-md bg-[#ffffff]  z-10">
              <figure className="absolute  w-[240px] h-[300px] right-0 -top-10 md:w-[380px] md:h-[400px] lg:w-[350px] lg:h-[420px] md:-top-20 md:left-0 md:max-w-md lg:max-w-lg">
                <img src={bubble1} alt="Quick_Moblization" />
              </figure>

              <h2 className="md:text-lg lg:text-3xl text-[#666666] font-semibold 2xl:text-4xl z-10 py-6 lg:py-12 md:py-10 px-2 md:px-0 text-left">
                Quick Mobilization
              </h2>
              <p className="text-xs font-display lg:text-base 2xl:text-xl text-[#666666] px-2 md:px-0">
                Random Walk’s AI Managed Services eliminates the need for
                in-house resources. This streamlines AI integration and
                significantly reduces overhead costs while maximizing your ROI.
                <br />
                <br />
                Our team of adept AI developers, architects, NLP engineers,
                AI/ML engineers, data engineers, and data scientists provides
                comprehensive AI services and AI solutions for your business.
                They handle continual monitoring, AI development, optimization,
                and enhancement of integrated systems, ensuring peak performance
                that evolves with your changing business needs.
                <br />
                <br />
              </p>
            </div>
          </article>
          <figure className="hidden  md:block  flex 2xl:mt-20  md:mt-20 lg:mt-32 lg:ml-44">
            <img src={mann1} alt="Quick_Moblization_img1" className="lg:w-[350px] lg:h-[400px] md:w-[370px] md:h-[300px] 2xl:w-[510px] 2xl:h-[540px] " />
          </figure>
        </section>

        <section className="flex flex-col md:flex-row-reverse relative  md:mt-20  lg:mb-32 ">
          <figure className="flex md:hidden justify-center items-center">
            <img src={mann2} alt="Managed_Ai_Integration" className="w-[250px] h-[220px]" />
          </figure>
          <figure className="hidden md:block absolute  bottom-44 right-0 z-0">
            <img
              src={line}
              alt="Managed_Ai_Integration_img1"
              className="relative md:h-[180px] md:w-[70px] lg:w-[90px] lg:h-[320px] 2xl:h-[500px] 2xl:w-[180px]"
               loading="lazy"
            />
          </figure>

          <article className="lg:w-full  lg:ml-20 lg:mt-28">
            <div className="ml-3 relative bg-white z-10 p-5 rounded-md bg-[#ffffff]  ">
              <figure className="absolute  bottom-44 -left-20 md:top-44 md:right-10 lg:-bottom-44 lg:left-32 z-0">
                <img
                  src={bubble1}
                  alt="Managed_Ai_Integration_img2"
                  className="relative w-[240px] h-[300px]  md:w-[380px] md:h-[400px] lg:w-[350px] lg:h-[420px]"
                />
              </figure>

              <h2 className="md:text-lg text-[#666666] lg:text-3xl 2xl:text-4xl font-semibold  py-6 lg:py-12 md:py-10 px-2 md:px-0 text-left">
                Managed AI Integration
              </h2>
              <p className="text-xs text-[#666666] lg:font-display lg:text-base  2xl:text-xl 2xl:max-w-4xl lg:max-w-lg md:max-w-md px-2 md:px-0">
                Offload the burdens of recruiting, onboarding, and managing
                resources by leveraging Random Walk’s AI integration
                specialists, allowing your HR team to focus on core activities.
                <br />
                <br />
                With our AI services and expert AI development team, you can
                scale your workforce up or down rapidly based on project
                requirements. This flexibility eliminates the need for long-term
                commitments while expediting project timelines and meeting
                critical deadlines. Random Walk’s AI managed services provide
                adaptable AI solutions for seamless project execution, ensuring
                the best AI for business outcomes.
                <br />
                <br />
              </p>
            </div>
          </article>

          <figure className="hidden md:block  lg:w-full lg:mt-24 md:mt-16">
            <img
              src={mann2}
              alt="Managed_Ai_Integration_img3"
              className="lg:w-[450px] lg:h-[400px] 2xl:w-[600px] 2xl:h-[600px] md:w-[300px] md:h-[300px]  ml-10 2xl:ml-44"
            />
          </figure>
        </section>

        <section className="flex flex-col  md:flex-row relative  md:mt-20 lg:mt-0">
          <figure className="flex md:hidden justify-center items-center">
            <img
              src={mann3}
              alt="Integrate_with_existing_Team"
              className="w-[300px] h-[180px] "
            />
          </figure>
          <figure className="hidden md:block absolute md:w-20  2xl:w-56 lg:w-44 md:bottom-44   lg:bottom-20 lg:left-0 z-0">
            <img
              src={triangle}
              alt="Integrate_with_existing_Team_img1"
              className="relative"
            />
          </figure>
          <div className="w-full lg:mt-10 lg:w-7/12   z-20 relative p-5 rounded-md bg-[#ffffff]  ">
            <figure className="absolute  -bottom-20 -right-0 md:-bottom-44 md:-left-36  2xl:-bottom-56 z-0">
              <img
                src={bubble1}
                alt="Integrate_with_existing_Team_img2"
                className=" w-[240px] h-[300px]  md:w-[380px] md:h-[400px] lg:w-[350px] lg:h-[420px]"
              />
            </figure>

            <h2 className="md:text-lg text-[#666666] lg:text-3xl 2xl:text-4xl font-semibold  py-6 lg:py-12 md:py-10 px-2 md:px-6 lg:px-10 text-left">
              Integrate With Existing Team
            </h2>
            <p className="text-xs text-[#666666] lg:font-display lg:text-base  2xl:text-xl md:max-w-base 2xl:max-w-4xl lg:max-w-2xl px-2 md:px-6 lg:px-10">
              Bolster your team’s capabilities with specialized AI expertise and
              resources to tackle AI integration projects and challenges.
              <br />
              <br />
              Your existing IT teams may or may not be actively involved in
              AI-related projects. AI might not be the primary focus of your IT
              team at the moment. In such cases, your team can continue
              dedicating their efforts to business-critical tasks while Random
              Walk’s professionals handle AI integration to
              ensure you benefit from the latest AI solutions and advancements.
              Let Random Walk’s AI development experts help your organization
              stay ahead in the evolving landscape of AI for business.
              <br />
              <br />
            </p>
          </div>

          <figure className="hidden md:block flex justify-center items-center w-full md:w-7/12 lg:w-6/12 mt-4 md:mt-32 lg:ml-10">
            <img
              src={mann3}
              alt="Integrate_with_existing_Team_img3"
              className=" lg:w-[500px] lg:h-[300px] md:w-[380px] md:h-[200px] 2xl:w-[600px] 2xl:h-[350px]"
            />
          </figure>
        </section>

        <section className="flex flex-col md:flex-row-reverse relative  md:mt-20 lg:mt-28 lg:mb-32 pt-1 ">
          <figure className="flex md:hidden justify-center items-center">
            <img src={mann4} alt="Lower_Operational_cost" className="w-6/12" />
          </figure>
          <figure className="hidden md:block absolute md:w-16 lg:w-24 2xl:w-44 bottom-44 right-0 z-0">
            <img
              src={line}
              alt="Lower_Operational_cost_img1"
              className="relative"
            />
          </figure>

          <article className="lg:w-full lg:ml-20 lg:mt-28">
            <div className="ml-3 relative bg-white z-20 p-5 rounded-md bg-[#ffffff]  ">
              <figure className="absolute  md:-bottom-44  lg:-bottom-44  md:right-0 lg:left-32 lg:top-72 md:top-56 left-0 z-0">
                <img
                  src={bubble1}
                  alt="Lower_Operational_cost_img2"
                  className="relative  w-[240px] h-[300px]  md:w-[380px] md:h-[400px] lg:w-[350px] lg:h-[420px]"
                />
              </figure>

              <h2 className="md:text-lg text-[#666666] lg:text-3xl font-semibold  2xl:text-4xl py-6  lg:py-9 text-left">
                Lower Operational Cost
              </h2>
              <p className="text-xs lg:font-display text-[#666666] lg:text-base 2xl:text-xl  md:max-w-4xl  ">
                At Random Walk, our AI managed services pricing models are
                designed to offer flexibility tailored to the unique needs of
                each client. For long-term AI integration and AI development
                partnerships, we provide fixed pricing for predictability and
                cost control. For short-term AI services and projects, our time
                and materials billing allows for adjustments as requirements
                evolve.
                <br />
                <br />
                We also provide the option to cap costs on T&M engagements, to
                reduce risk while maintaining agility. Our transparent pricing
                structure aims to facilitate an open dialogue on the best
                approach, aligned with the value we deliver through our
                comprehensive AI solutions.
                <br />
                <br />
              </p>
            </div>
          </article>

          <div className="max-md:hidden lg:w-full lg:mt-10 2xl:mt-8 md:mt-20">
            <img
              src={mann4}
              alt="Lower_Operational_cost_img4"
              className="lg:w-9/12 2xl:w-7/12 md:w-7/12 md:ml-20 lg:ml-20 "
            />
          </div>
        </section>

        <section className="flex flex-col  md:flex-row relative md:mt-16 lg:mt-0">
          <figure className="flex md:hidden justify-center items-center">
            <img
              src={mann6}
              alt="Subject_matter_expertise"
              className="w-6/12"
            />
          </figure>
          <figure className="hidden md:block absolute  md:w-32 2xl:w-64 lg:w-44    bottom-32 left-0 z-0">
            <img
              src={triangle}
              alt="Subject_matter_expertise_img1"
              className="relative"
            />
          </figure>
          <article className="w-full lg:mt-10 lg:w-7/12   z-20 relative p-5 rounded-md bg-[#ffffff]  ">
            <figure className="absolute  md:-bottom-40 md:-left-36  top-40 left-0 z-0">
              <img src={bubble1} alt="Subject_matter_expertise_img2" className=" w-[240px] h-[300px]  md:w-[380px] md:h-[400px] lg:w-[350px] lg:h-[420px]" />
            </figure>

            <h2 className="md:text-lg text-[#666666] lg:text-3xl 2xl:text-4xl font-semibold  py-6 lg:py-12 md:py-10 px-2 md:px-6 lg:px-10 text-left">
              Subject Matter Expertise
            </h2>
            <p className="text-xs text-[#666666] lg:font-display lg:text-base  2xl:text-xl md:max-w-base 2xl:max-w-4xl lg:max-w-2xl px-2 md:px-6 lg:px-10">
              Our expert AI engineers have implemented a large number of AI
              integration projects successfully and will provide you the
              necessary guidance from need assessment to successful AI
              integration and monitoring.
              <br />
              <br />
              Random Walk’s AI expertise is backed by deep domain knowledge and
              a proven track record of delivering successful AI projects across
              industries and various business functions.
            </p>
          </article>

          <figure className="hidden  md:block flex justify-center items-center w-full md:w-6/12 lg:w-6/12 lg:mt-20 md:mt-16 md:mr-8 lg:mr-0 lg:ml-32">
            <img
              src={mann6}
              alt="Subject_matter_expertise_img4"
              className="w-full lg:w-8/12 2xl:w-7/12"
            />
          </figure>
        </section>

        <section className="flex flex-col md:flex-row-reverse relative  md:mt-20 lg:mt-0 lg:mb-32 md:mb-20 mb-10">
          <figure className="flex md:hidden justify-center items-center">
            <img src={man7} alt="AI Integration" className="w-[220px] h-[180px]" />
          </figure>
          <figure className="hidden md:block absolute md:w-16 lg:w-24 2xl:w-44 bottom-44 right-0 z-0">
            <img src={line} alt="Bubble Background" className="relative" />
          </figure>

          <article className="lg:w-full lg:ml-20 lg:mt-28">
            <div className="ml-3 relative bg-white z-0 p-5 rounded-md bg-[#ffffff] ">
              <figure className="absolute md:w-8/12 lg:w-9/12 w-7/12 -bottom-20  right-0 lg:-bottom-44 lg:right-0  2xl:-bottom-56 z-0">
                <img
                  src={bubble1}
                  alt="Bubble Background"
                  className="relative  w-[240px] h-[300px]  md:w-[380px] md:h-[400px] lg:w-[350px] lg:h-[420px] "
                />
              </figure>

              <h2 className="md:text-lg text-[#666666] lg:text-3xl font-semibold  2xl:text-4xl py-6  lg:py-9 text-left">
                Quality Control
              </h2>
              <p className="text-xs lg:font-display text-[#666666] lg:text-base 2xl:text-xl  md:max-w-4xl ">
                Random Walk offers periodic quality reviews for our AI
                integration engineers, tracking key KPIs such as integration
                efficiency, lead times, performance improvements, and
                scalability. We are flexible and welcome KPIs that align with
                your organization’s goals and the specific roles within the AI
                integration team.
                <br />
                <br />
                Our extensive pool of engineers enables us to maintain
                high-quality AI services and AI solutions by matching the right
                skills to your projects. This approach ensures seamless AI
                development and consulting services that align with your
                business culture and objectives, fostering a positive rapport
                and effective collaboration for AI for business success.
                <br />
                <br />
              </p>
            </div>
          </article>

          <figure className="hidden md:block lg:w-full lg:mt-44 md:mt-20 2xl:mt-32">
            <img
              src={man7}
              alt="AI Integration"
              className="lg:w-[450px] lg:h-[320px] md:w-[400px] md:h-[200px] 2xl:w-[600px] 2xl:h-[400px] lg:ml-20 md:ml-5"
            />
          </figure>
        </section>
      </section>

      <section className=" mt-20 ">
        <Popup utmContent={"ai-managed-services"} />
      </section>
    </section>
  );
};
export default Managed;
