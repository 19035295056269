import React, { useState, useEffect, useRef } from "react";
import Footer from "../components/Footer";
import CustomMarkdownRenderer from "../components/Markdown.js";
import podcast1 from "../assets/Podcast/podcast1.webp";
import share from "../assets/Podcast/share.webp";

import transcript from "../assets/Podcast/transcript.webp";

import whatsapp from "../assets/Podcast/whatsapp.webp";
import mail from "../assets/Podcast/email.webp";
import { NavLink } from "react-router-dom";
import instagram from "../assets/Podcast/instagram.webp";
import { Client, Databases, ID } from "appwrite";
import Popup from "../components/popup.js";
import { Helmet } from "react-helmet-async";
import "react-phone-input-2/lib/style.css";
import PhoneInput from "react-phone-input-2";
import useFetch from "../hooks/useFetch.js";

import Lottie from "react-lottie";
import loading from "../pages/loading.json";

import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from "libphonenumber-js";

const Podcast = () => {
  const {
    loading: podcastsLoading,
    data: podcasts,
    error: podcastsError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/podcast/data.json`
  );

  const {
    loading: pastpodcastsLoading,
    data: pastpodcasts,
    error: pastpodcastsError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/pastpodcast/data.json`
  );

  // Lottie animation options
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  console.log("podcast", pastpodcasts);
  const shareButtonRef = useRef(null);
  const shareOptionsRef = useRef({});
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [fullname, setfullname] = useState("");
  const [leavenote, setleavenote] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [phonenumber, setphonenumber] = useState("");
  const [email, setemail] = useState("");

  const [utmParams, setUtmParams] = useState({
    utmSource: "",
    utmMedium: "",
    utmCampaign: "",
    utmId: "",
    utmTerm: "",
    utmContent: "podcast", // Hardcoded as requested
  });

  const [errors, setErrors] = useState({});

  const resetForm = () => {
    setfullname("");
    setleavenote("");
    setphonenumber("");

    setemail("");

    setIsChecked(true);
    setErrors({});
  };
  const client = new Client()
    .setEndpoint(process.env.REACT_APP_ENDPOINT)
    .setProject(process.env.REACT_APP_PROJECT_ID);

  const databases = new Databases(client);

  const handlePhoneNumberChange = (number, country) => {
    setphonenumber(number);

    try {
      // Ensure number starts with +
      const fullNumber = number.startsWith("+") ? number : `+${number}`;

      // Check if the number is valid for the selected country
      if (isValidPhoneNumber(fullNumber)) {
        const phoneNumberObj = parsePhoneNumberFromString(fullNumber);
        const nationalNumber = phoneNumberObj.nationalNumber;

        // Get expected length based on country
        const expectedLength = phoneNumberObj.nationalNumber?.length;
        const actualLength = nationalNumber?.length;

        if (actualLength < expectedLength) {
          setErrors((prev) => ({
            ...prev,
            phonenumber: `Phone number is too short - needs ${expectedLength} digits`,
          }));
        } else if (actualLength > expectedLength) {
          setErrors((prev) => ({
            ...prev,
            phonenumber: `Phone number is too long - should be ${expectedLength} digits`,
          }));
        } else {
          // Clear the error when valid
          setErrors((prev) => ({
            ...prev,
            phonenumber: "",
          }));
        }
      } else {
        setErrors((prev) => ({
          ...prev,
          phonenumber: "Please enter a valid phone number",
        }));
      }
    } catch (error) {
      console.error("Phone validation error:", error);
      setErrors((prev) => ({
        ...prev,
        phonenumber: "Please enter a valid phone number",
      }));
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    setUtmParams({
      utmSource: searchParams.get("utm_source") || "",
      utmMedium: searchParams.get("utm_medium") || "",
      utmCampaign: searchParams.get("utm_campaign") || "",
      utmId: searchParams.get("utm_id") || "",
      utmTerm: searchParams.get("utm_term") || "",
      utmContent: "podcast",
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const fullNumber = phonenumber.startsWith("+")
        ? phonenumber
        : `+${phonenumber}`;
      if (!isValidPhoneNumber(fullNumber)) {
        setErrors((prev) => ({
          ...prev,
          phoneNumber: "Please enter a valid phone number", // Changed from phoneNumber to phonenumberint
        }));
        return;
      }

      const formData = {
        fullname,
        leavenote,
        phonenumber,
        email,
        ...utmParams, // Spread the UTM parameters into the form data
      };
      await Promise.all([
        databases.createDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_ID8,
          ID.unique(),
          formData
        ),
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/submit-podcast`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }).then(async (response) => {
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Failed to submit form");
          }
        }),
      ]);

      setIsSuccessOpen(true);
      resetForm();
    } catch (error) {
      console.error("Error submitting form:", error);
      setErrors({ submit: "Failed to submit form. Please try again." });
    } finally {
      setIsLoading(false);
    }
  };

  const closeSuccessModal = () => {
    setIsSuccessOpen(false);
  };

  const podcastData = podcasts?.[0] || {};
  const pastpodcastData = pastpodcasts || []; // Fetch all past podcasts, not just the first one
  // console.log("PAST DAW: ", pastpodcastData);

  const upcomingimages = Array.isArray(podcastData?.upcoming_images)
    ? podcastData.upcoming_images
    : [];

  const [showShareOptions, setShowShareOptions] = useState(false);
  const [shareOptionsState, setShareOptionsState] = useState({});
  const [showTranscriptModal, setShowTranscriptModal] = useState(false);
  const [showTranscriptModal2, setShowTranscriptModal2] = useState(null); // Set modal index for transcript

  const [showVideoModal, setShowVideoModal] = useState(false);
  const [showVideoModal2, setShowVideoModal2] = useState(null); // Set modal index for video

  const getBackgroundColor = (index) => {
    const imageCount = upcomingimages?.length;

    if (imageCount === 1) {
      return podcastData.onespeaker_color1 || "#FDE9FF";
    } else if (imageCount === 2) {
      return index === 0
        ? podcastData.twospeaker_color1
        : podcastData.twospeaker_color2 || "#FDE9FF";
    } else if (imageCount === 3) {
      if (index === 0) return podcastData.threespeaker_color1 || "#FDE9FF";
      if (index === 1) return podcastData.threespeaker_color2 || "#FDE9FF";
      if (index === 2) return podcastData.threespeaker_color3 || "#FDE9FF";
    } else if (imageCount === 4) {
      if (index === 0) return podcastData.threespeaker_color1 || "#FDE9FF";
      if (index === 1) return podcastData.fourspeaker_color2 || "#FDE9FF";
      if (index === 2) return podcastData.fourspeaker_color3 || "#FDE9FF";
      if (index === 3) return podcastData.fourspeaker_color4 || "#FDE9FF";
    }
    return "#FDE9FF";
  };

  const handleShareClick = () => {
    setShowShareOptions(!showShareOptions);
  };
  const handleShareClick2 = (index, event) => {
    event.stopPropagation();
    setShareOptionsState((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const handleTranscriptClick2 = (index) => {
    setShowTranscriptModal2(index); // Open transcript modal for the clicked past podcast
  };

  const closeTranscriptModal2 = () => {
    setShowTranscriptModal2(null); // Close transcript modal
  };

  const closeVideoModal2 = () => {
    setShowVideoModal2(null); // Close video modal
  };

  const getYouTubeEmbedUrl2 = (url) => {
    if (!url) return null;
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[2]?.length === 11) {
      return `https://www.youtube.com/embed/${match[2]}`;
    }
    return null;
  };

  const getYouTubeThumbnailUrl = (url, quality = "hqdefault") => {
    if (!url) return null;
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[2]?.length === 11) {
      return `https://img.youtube.com/vi/${match[2]}/${quality}.jpg`;
    }
    return null;
  };

  const getYouTubeVideoId = (url) => {
    const regExp = /^.*(youtu\.be\/|v\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return match && match[2]?.length === 11 ? match[2] : null;
  };


  useEffect(() => {
    // Set page title
    document.title = "AI & Future Tech Podcast | Random Walk";

    // Set meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', "Tune in to Random Walk's podcast to hear expert discussions on AI, future tech, and emerging industry trends. Learn from industry leaders and innovators shaping the AI landscape.");
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = "Tune in to Random Walk's podcast to hear expert discussions on AI, future tech, and emerging industry trends. Learn from industry leaders and innovators shaping the AI landscape.";
      document.head.appendChild(meta);
    }

    // Add or update Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement('meta');
      ogTitle.setAttribute('property', 'og:title');
      document.head.appendChild(ogTitle);
    }
    ogTitle.setAttribute('content', "AI & Future Tech Podcast | Random Walk");

    // Add or update Open Graph description
    let ogDescription = document.querySelector('meta[property="og:description"]');
    if (!ogDescription) {
      ogDescription = document.createElement('meta');
      ogDescription.setAttribute('property', 'og:description');
      document.head.appendChild(ogDescription);
    }
    ogDescription.setAttribute('content', "Tune in to Random Walk's podcast to hear expert discussions on AI, future tech, and emerging industry trends. Learn from industry leaders and innovators shaping the AI landscape.");

    // Add viewport meta tag
    let viewport = document.querySelector('meta[name="viewport"]');
    if (!viewport) {
      viewport = document.createElement('meta');
      viewport.name = "viewport";
      document.head.appendChild(viewport);
    }
    viewport.setAttribute('content', "width=device-width, initial-scale=1");

    // Add theme-color meta tag
    let themeColor = document.querySelector('meta[name="theme-color"]');
    if (!themeColor) {
      themeColor = document.createElement('meta');
      themeColor.name = "theme-color";
      document.head.appendChild(themeColor);
    }
    themeColor.setAttribute('content', "#000000");

    // Add Open Graph image
    let ogImage = document.querySelector('meta[property="og:image"]');
    if (!ogImage) {
      ogImage = document.createElement('meta');
      ogImage.setAttribute('property', 'og:image');
      document.head.appendChild(ogImage);
    }
    ogImage.setAttribute('content', `${process.env.PUBLIC_URL}/rw_logo.png`);

    // Add Open Graph type
    let ogType = document.querySelector('meta[property="og:type"]');
    if (!ogType) {
      ogType = document.createElement('meta');
      ogType.setAttribute('property', 'og:type');
      document.head.appendChild(ogType);
    }
    ogType.setAttribute('content', "website");

    // Add canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.rel = "canonical";
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.href = "https://randomwalk.ai/podcasts/";
  }, []);
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Handle main share button
      if (
        shareButtonRef.current &&
        !shareButtonRef.current.contains(event.target) &&
        showShareOptions
      ) {
        setShowShareOptions(false);
      }

      // Handle past podcast share buttons
      Object.keys(shareOptionsState).forEach((index) => {
        if (
          shareOptionsRef.current[index] &&
          !shareOptionsRef.current[index].contains(event.target) &&
          shareOptionsState[index]
        ) {
          setShareOptionsState((prev) => ({
            ...prev,
            [index]: false,
          }));
        }
      });
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showShareOptions, shareOptionsState]);
  const upcomingpodcastHeading =
    podcastData.upcoming_title || "No upcoming podcast details available";
  const upcomingpodcastdesc =
    podcastData.upcoming_desc || "No upcoming podcast details available";
  const transcriptContent =
    podcastData.transcript || "Transcript not available.";
  const transcriptContent2 =
    pastpodcastData.past_transcript || "Transcript not available.";
  const formdetails =
    podcastData.form_details || "No upcoming podcast details available";

  const handleTranscriptClick = () => {
    setShowTranscriptModal(true);
  };

  const closeTranscriptModal = () => {
    setShowTranscriptModal(false);
  };

  const closeVideoModal = () => {
    setShowVideoModal(false);
  };

  const youtubeLink = podcastData.youtube_url || "";
  const pastyoutubeLink = pastpodcastData.pastyoutube_url || "";

  const handleListenClick = () => {
    if (youtubeLink) {
      setShowVideoModal(true);
    } else {
      console.error("No YouTube URL provided");
    }
  };

  const handleListenClick2 = () => {
    if (pastyoutubeLink) {
      setShowVideoModal2(true);
    } else {
      console.error("No YouTube URL provided");
    }
  };

  const getYouTubeEmbedUrl = (url) => {
    if (!url) return null;
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[2]?.length === 11) {
      return `https://www.youtube.com/embed/${match[2]}`;
    }
    return null;
  };

  const thumbnailUrl2 = getYouTubeThumbnailUrl(pastyoutubeLink, "sddefault");

  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  if (podcastsLoading) {
    return (
      <div className="w-full flex justify-center items-center min-h-screen">
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    );
  }

  if (podcastsError) return <div>Error loading podcasts!</div>;
  if (!podcasts) return null;

  if (pastpodcastsLoading) {
    return (
      <div className="w-full flex justify-center items-center min-h-screen">
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    );
  }

  if (pastpodcastsError) return <div>Error loading pastpodcasts!</div>;
  if (!pastpodcasts) return null;

  return (
    <section className="font-display relative">
      {/* <Helmet>
        <title>AI & Future Tech Podcast | Random Walk</title>
        <meta
          property="og:title"
          content="AI & Future Tech Podcast | Random Walk"
        />
        <meta
          name="description"
          content="Tune in to Random Walk's podcast to hear expert discussions on AI, future tech, and emerging industry trends. Learn from industry leaders and innovators shaping the AI landscape."
        />
        <meta
          property="og:description"
          content="Tune in to Random Walk's podcast to hear expert discussions on AI, future tech, and emerging industry trends. Learn from industry leaders and innovators shaping the AI landscape."
        />
        <link rel="canonical" href="https://randomwalk.ai/podcasts/" />
      </Helmet> */}

      <section className="md:py-40 md:px-10 lg:px-20 pt-24   md:pt-32   p-5 flex flex-col lg:flex-col justify-start items-center relative">
        <article className="w-full flex flex-col md:flex-row justify-between items-center relative">
          <div className="w-full flex flex-col md:flex-row relative z-10">
            <img
              src={podcast1}
              alt="Podcast"
              className=" md:w-6/12 lg:w-10/12   flex    md:mb-0"
            />

            <div className="w-full bg-[#F5ECFA] text-[#666666] rounded-3xl  md:rounded-2xl  md:rounded-tl-none  md:rounded-bl-none  md:rounded-tr-3xl md:rounded-br-3xl p-5  md:p-10 lg:p-16 2xl:p-40 ">
              <h2 className=" 2xl:scale-125  md:pb-5 lg:text-xl font-bold text-left">
                Latest Episode
              </h2>
              <p className="  2xl:scale-125">
                <CustomMarkdownRenderer
                  content={upcomingpodcastHeading}
                  setHeadings={() => {}}
                />
              </p>

              <p className="  2xl:scale-125 lg:text-sm md:text-xs mt-5 2xl:mt-16">
                <CustomMarkdownRenderer
                  content={upcomingpodcastdesc}
                  setHeadings={() => {}}
                />
              </p>

              <div className="relative w-full flex justify-center items-center mt-20 mb-10">
                {/* Remove the outer anchor tag and place it around both elements */}
                <a
                  href={youtubeLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="relative block" // Changed to block to ensure proper sizing
                >
                  {/* Thumbnail Image */}
                  <img
                    src={`https://img.youtube.com/vi/${getYouTubeVideoId(
                      youtubeLink
                    )}/0.jpg`}
                    alt="Podcast Video Thumbnail"
                    className="w-60 lg:w-auto 2xl:w-auto object-cover rounded-lg shadow-md"
                  />

                  {/* Play Button Overlay */}
                  <div className="absolute inset-0 flex items-center justify-center">
                    <div className="p-4">
                      <svg
                        className="w-16 h-16 text-white hover:text-gray-200 transition-colors"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        aria-hidden="true"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="2"
                          fill="black"
                          fillOpacity="0.5"
                        />
                        <path
                          fill="currentColor"
                          d="M10 8.27L15 12 10 15.73V8.27z"
                        />
                      </svg>
                    </div>
                  </div>
                </a>
              </div>

              <div className="flex flex-col md:mt-10 relative">
                <article className="flex justify-center items-center mb-20">
                  <div
                    ref={shareButtonRef}
                    className="flex items-center cursor-pointer relative"
                    onClick={handleShareClick}
                  >
                    <img src={share} alt="Share" className="w-5 h-5" />
                    <h3 className="ml-2">Share</h3>
                    {showShareOptions && (
                      <div className="absolute top-full left-0 mt-2 p-2  translate-x-2 shadow-lg z-50  bg-transparent rounded">
                        <div className="flex items-center space-x-2">
                          <a
                            href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                              youtubeLink
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={whatsapp}
                              alt="WhatsApp"
                              className="w-4 h-4"
                            />
                          </a>
                          <a
                            href={`mailto:?subject=${encodeURIComponent(
                              "Check out this podcast"
                            )}&body=${encodeURIComponent(youtubeLink)}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={mail} alt="Email" className="w-4 h-4" />
                          </a>
                          <a
                          
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              const shareText = ` ${youtubeLink}`;
                              navigator.clipboard
                                .writeText(shareText)
                                .then(() => {
                                  alert(
                                    "Copied to clipboard! Now you can open Instagram and share to anyone."
                                  );
                                })
                                .catch((err) => {
                                  console.error("Could not copy text: ", err);
                                });
                            }}
                          >
                            <img
                              src={instagram}
                              alt="Instagram"
                              className="w-4 h-4 cursor-pointer"
                            />
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="mx-3">|</div>
                  <div
                    className="flex items-center cursor-pointer"
                    onClick={handleTranscriptClick}
                  >
                    <img
                      src={transcript}
                      alt="Transcript"
                      className="w-5 h-5"
                    />
                    <h3 className="ml-2">Transcript</h3>
                  </div>
                </article>
              </div>
            </div>
          </div>
        </article>

        <div className="w-full h-[0.5px] bg-black mt-10"></div>
        <div className="w-full text-[#666666] mt-5 flex flex-col md:flex-row">
          <article className="w-full md:w-1/2 p-5">
            <CustomMarkdownRenderer
              content={formdetails}
              setHeadings={() => {}}
            />
          </article>

          <article className="w-full md:w-1/2 p-5">
            <form className="space-y-4" onSubmit={handleSubmit}>
              <div>
                <input
                  id="fullname"
                  type="text"
                  name="fullname"
                  value={fullname}
                  onChange={(e) => setfullname(e.target.value)}
                  placeholder="  Full Name"
                  className="w-full border-white border-2 border-b-[#575758] py-2"
                />
                {errors.fullname && (
                  <p className="text-red-500 text-sm">{errors.fullname}</p>
                )}
              </div>

              <div>
                <input
                  id="email"
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  placeholder=" Email"
                  className="w-full border-white border-2 border-b-[#575758] py-2"
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>

              <div className="phone-input-container">
                <PhoneInput
                  country={"in"}
                  id="phonenumber"
                  type="tel"
                  name="phonenumber"
                  value={phonenumber}
                  onChange={(number, country) =>
                    handlePhoneNumberChange(number, country)
                  }
                  placeholder="  Phone Number"
                  required
                  containerClass="w-full"
                  inputStyle={{
                    width: "100%",
                    height: "40px",
                    fontSize: "16px",
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    borderBottom: "2px solid #575758",
                    borderRadius: "0",
                  }}
                  buttonStyle={{
                    border: "none",
                    background: "none",
                    padding: "0 10px",
                  }}
                  dropdownStyle={{
                    width: "300px",
                  }}
                  enableSearch={true}
                  inputProps={{
                    required: true,
                    autoFocus: false,
                  }}
                />
                {errors.phonenumber && (
                  <p className="text-red-500 text-sm">{errors.phonenumber}</p>
                )}
              </div>

              <div>
                <input
                  id="leaveusanote"
                  type="text"
                  name="leavenote"
                  value={leavenote}
                  onChange={(e) => setleavenote(e.target.value)}
                  placeholder="  Leave us a note"
                  className="w-full border-white border-2 border-b-[#575758] py-2"
                />
                {errors.leavenote && (
                  <p className="text-red-500 text-sm">{errors.leavenote}</p>
                )}
              </div>

              <div className="disclaimerContainercontact">
                <label className="checkboxLabel">
                  <input
                    type="checkbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    className="checkbox"
                  />
                  <span className="checkboxText">
                    I agree and authorize team to contact me and/or send
                    relevant information over Email, SMS & WhatsApp. This will
                    override the registry with DNC/NDNC.
                  </span>
                </label>
              </div>
              {errors.terms && (
                <p className="text-red-500 text-sm">{errors.terms}</p>
              )}

              <div className="linksContainer">
                <a href="/terms-of-service/" className="link">
                  Terms of Service
                </a>
                <a href="/privacy-policy/" className="link">
                  Privacy Policy
                </a>
              </div>

              {/* Centered Submit Button */}
              <div className="flex justify-center items-center">
                <button
                  id="submit"
                  type="submit"
                  className={`bg-white rounded-xl border border-[#969696] text-[#666666] font-semibold px-16 py-2 mt-4 font-display ${
                    !isChecked || isLoading
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  disabled={!isChecked || isLoading}
                >
                  {isLoading ? (
                    <div className="flex items-center justify-center space-x-2">
                      <svg
                        className="animate-spin h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      <span>Submitting...</span>
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </form>
          </article>
        </div>
        <div className="w-full h-[0.5px] bg-black mt-5"></div>

        {isSuccessOpen && (
          <section className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
            <article className="relative bg-white p-8 rounded-lg max-w-md w-full text-center">
              <button
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={closeSuccessModal}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <h2 className="text-2xl font-bold mb-4">Success!</h2>
              <p className="mb-4">Your request has been sent successfully.</p>
              <button
                className="px-4 py-2 bg-[#C62B6D] text-white rounded"
                onClick={closeSuccessModal}
              >
                Close
              </button>
            </article>
          </section>
        )}

        <div className="w-full text-[#666666] mt-10">
          <h2 className="lg:text-lg font-bold pb-5 text-left">
            Recent Episodes
          </h2>
          {pastpodcastData?.length > 0 ? (
            <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
              {pastpodcastData
                .slice()
                .reverse()
                .map((pastpodcast, index) => {
                  const pastDetails =
                    pastpodcast.past_details || "No past details available.";
                  const pastyoutubeLink = pastpodcast.pastyoutube_url || "";
                  const thumbnailUrl2 = getYouTubeThumbnailUrl(
                    pastyoutubeLink,
                    "sddefault"
                  );

                  return (
                    <div
                      key={index}
                      className="bg-[#F5ECFA] rounded-2xl relative mb-10"
                    >
                      <div>
                        {thumbnailUrl2 ? (
                          <a
                            href={pastyoutubeLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="relative cursor-pointer"
                          >
                            <img
                              src={thumbnailUrl2}
                              alt="YouTube thumbnail"
                              className="w-full p-5 flex rounded-lg"
                            />
                            <article className="absolute inset-0 flex items-center justify-center">
                              <div className="p-4">
                                <svg
                                  className="w-16 h-16 text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 24 24"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <circle
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="white"
                                    strokeWidth="2"
                                    fill="transparent"
                                  />
                                  <path d="M10 8.27L15 12 10 15.73V8.27z" />
                                </svg>
                              </div>
                            </article>
                          </a>
                        ) : (
                          <p>No past images available.</p>
                        )}
                      </div>

                      <div className="px-5 text-sm pb-5 pt-3 bg-[#F5ECFA] w-full">
                        <CustomMarkdownRenderer
                          content={pastDetails}
                          setHeadings={() => {}}
                        />
                      </div>

                      <div className="flex justify-center items-center pb-28 relative">
                        <div
                          ref={(el) => (shareOptionsRef.current[index] = el)}
                          className="flex items-center cursor-pointer relative"
                          onClick={(e) => handleShareClick2(index, e)}
                        >
                          <img src={share} alt="Share" className="w-5 h-5" />
                          <h3 className="ml-2">Share</h3>
                          {shareOptionsState[index] && (
                            <div className="absolute top-full left-0 translate-x-2 mt-2 shadow-lg p-2 z-30 bg-transparent rounded">
                              <div className="flex items-center space-x-2">
                                <a
                                  href={`https://api.whatsapp.com/send?text=${encodeURIComponent(
                                    pastyoutubeLink
                                  )}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={whatsapp}
                                    alt="WhatsApp"
                                    className="w-4 h-4"
                                  />
                                </a>
                                <a
                                  href={`mailto:?subject=${encodeURIComponent(
                                    "Check out this podcast"
                                  )}&body=${encodeURIComponent(
                                    pastyoutubeLink
                                  )}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={mail}
                                    alt="Email"
                                    className="w-4 h-4"
                                  />
                                </a>
                                <a
                               
                                  href="#"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const shareText = ` ${upcomingpodcastHeading}\n\n${pastyoutubeLink}`;
                                    navigator.clipboard
                                      .writeText(shareText)
                                      .then(() => {
                                        alert(
                                          "Copied to clipboard! Now you can open Instagram and share to anyone."
                                        );
                                      })
                                      .catch((err) => {
                                        console.error(
                                          "Could not copy text: ",
                                          err
                                        );
                                      });
                                  }}
                                >
                                  <img
                                    src={instagram}
                                    alt="Instagram"
                                    className="w-4 h-4 cursor-pointer"
                                  />
                                </a>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="mx-3">|</div>
                        <div
                          className="flex items-center cursor-pointer"
                          onClick={() => handleTranscriptClick2(index)}
                        >
                          <img
                            src={transcript}
                            alt="Transcript"
                            className="w-5 h-5"
                          />
                          <h3 className="ml-2">Transcript</h3>
                        </div>
                      </div>

                      {showTranscriptModal2 === index && (
                        <div className="fixed inset-0 bg-gray-900 bg-opacity-90 z-50 flex justify-center items-center">
                          <div className="bg-white md:p-8 p-4 rounded-lg max-w-md md:max-w-lg mx-auto ">
                            <h2 className="text-lg font-bold mb-4">
                              Podcast Transcript
                            </h2>
                            <p className="md:text-sm text-xs">
                              <CustomMarkdownRenderer
                                content={
                                  pastpodcast.past_transcript ||
                                  "Transcript not available."
                                }
                                setHeadings={() => {}}
                              />
                            </p>
                            <button
                              className="bg-gray-700 text-white py-2 px-4 rounded-lg mt-4"
                              onClick={closeTranscriptModal2}
                            >
                              Close
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  );
                })}
            </section>
          ) : (
            <p>No past podcasts available.</p>
          )}
        </div>
      </section>

      {showTranscriptModal && (
        <section className="fixed inset-0 bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center">
          <div className="bg-white md:p-8 p-4 rounded-lg md:max-w-lg max-w-md mx-auto">
            <h2 className="text-lg font-bold mb-4">Podcast Transcript</h2>
            <p className="md:text-sm text-xs">
              <CustomMarkdownRenderer
                content={transcriptContent}
                setHeadings={() => {}}
              />
            </p>
            <button
              className="bg-gray-700 text-white py-2 px-4 rounded-lg mt-4"
              onClick={closeTranscriptModal}
            >
              Close
            </button>
          </div>
        </section>
      )}

      <section className=" mt-20 lg:mt-0">
        <Popup utmContent={"podcasts"} />
      </section>
    </section>
  );
};

export default Podcast;
