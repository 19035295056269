import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import eventt from "../assets/Events/event.webp";

import bubbles from "../assets/Events/bubbles.webp";
import { Helmet } from "react-helmet-async";
import useFetch from "../hooks/useFetch.js";

import Popup from "../components/popup.js";

const Events = () => {
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  const openImageModal = (eventIndex, imageIndex) => {
    setSelectedEvent(eventIndex);
    setSelectedImageIndex(imageIndex);
  };

  const closeModal = () => {
    setSelectedEvent(null);
    setSelectedImageIndex(null);
  };

  const [selectedImage, setSelectedImage] = useState(null);

  const [slideIndex, setSlideIndex] = useState(0);
  const [carouselWidth, setCarouselWidth] = useState(0);
  const [itemsPerView, setItemsPerView] = useState(5);

  const {
    loading: eventsLoading,
    data: eventsData,
    error: eventsError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/event/data.json`
  );
  console.log("eventssss:", eventsData);

  const {
    loading: speakersLoading,
    data: speakersData,
    error: speakersError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/speaker/data.json`
  );

  useEffect(() => {
    const updateItemsPerView = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768) {
        setItemsPerView(2);
      } else {
        setItemsPerView(5);
      }
    };

    updateItemsPerView();

    const container = document.querySelector(".carousel-container");

    if (container) {
      setCarouselWidth(container.offsetWidth);
    }

    const handleResize = () => {
      if (container) {
        setCarouselWidth(container.offsetWidth);
      }
      updateItemsPerView();
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const navigateImage = (direction) => {
    if (selectedEvent === null) return;

    const currentEvent = eventsData?.[selectedEvent];
    setSelectedImageIndex((prevIndex) => {
      const newIndex = prevIndex + direction;
      const totalImages = currentEvent.eventImg?.length;
      if (newIndex < 0) return totalImages - 1;
      if (newIndex >= totalImages) return 0;
      return newIndex;
    });
  };

  const slideLeft = () => {
    setSlideIndex((prevIndex) =>
      prevIndex > 0 ? prevIndex - 1 : speakerList?.length - itemsPerView
    );
  };

  const slideRight = () => {
    setSlideIndex((prevIndex) =>
      prevIndex < speakerList?.length - itemsPerView ? prevIndex + 1 : 0
    );
  };

  const activeSlideIndex = Math.floor(slideIndex / itemsPerView);

  const eventList = eventsData || [];
  const speakerList = speakersData || [];

  const getYouTubeVideoId = (url) => {
    const match = url.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/i
    );
    return match ? match[1] : null;
  };

  useEffect(() => {
    const container = document.querySelector(".carousel-container");

    if (container) {
      setCarouselWidth(container.offsetWidth);
    }

    const handleResize = () => {
      if (container) {
        setCarouselWidth(container.offsetWidth);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);



  useEffect(() => {
    // Set page title
    document.title = "Join AI Events for Innovation & Collaboration | Random Walk";

    // Set meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', "Stay updated on AI trends and connect with experts at Random Walk's AI events. Join us for collaboration, innovation, and valuable networking opportunities.");
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = "Stay updated on AI trends and connect with experts at Random Walk's AI events. Join us for collaboration, innovation, and valuable networking opportunities.";
      document.head.appendChild(meta);
    }

    // Add or update Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement('meta');
      ogTitle.setAttribute('property', 'og:title');
      document.head.appendChild(ogTitle);
    }
    ogTitle.setAttribute('content', "Join AI Events for Innovation & Collaboration | Random Walk");

    // Add or update Open Graph description
    let ogDescription = document.querySelector('meta[property="og:description"]');
    if (!ogDescription) {
      ogDescription = document.createElement('meta');
      ogDescription.setAttribute('property', 'og:description');
      document.head.appendChild(ogDescription);
    }
    ogDescription.setAttribute('content', "Stay updated on AI trends and connect with experts at Random Walk's AI events. Join us for collaboration, innovation, and valuable networking opportunities.");

    // Add viewport meta tag
    let viewport = document.querySelector('meta[name="viewport"]');
    if (!viewport) {
      viewport = document.createElement('meta');
      viewport.name = "viewport";
      document.head.appendChild(viewport);
    }
    viewport.setAttribute('content', "width=device-width, initial-scale=1");

    // Add theme-color meta tag
    let themeColor = document.querySelector('meta[name="theme-color"]');
    if (!themeColor) {
      themeColor = document.createElement('meta');
      themeColor.name = "theme-color";
      document.head.appendChild(themeColor);
    }
    themeColor.setAttribute('content', "#000000");

    // Add Open Graph image
    let ogImage = document.querySelector('meta[property="og:image"]');
    if (!ogImage) {
      ogImage = document.createElement('meta');
      ogImage.setAttribute('property', 'og:image');
      document.head.appendChild(ogImage);
    }
    ogImage.setAttribute('content', `${process.env.PUBLIC_URL}/rw_logo.png`);

    // Add Open Graph type
    let ogType = document.querySelector('meta[property="og:type"]');
    if (!ogType) {
      ogType = document.createElement('meta');
      ogType.setAttribute('property', 'og:type');
      document.head.appendChild(ogType);
    }
    ogType.setAttribute('content', "website");

    // Add canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.rel = "canonical";
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.href = "https://randomwalk.ai/playlab/";
  }, []);
  return (
    <section className="Main2 md:max-w-full 2xl:max-w-full  font-display text-[#666666] lg:mt-10 2xl:mt-0 ">
      {/* <Helmet>
        <title>Join AI Events for Innovation & Collaboration | Random Walk</title>
        <meta
          property="og:title"
          content="Join AI Events for Innovation & Collaboration | Random Walk"
        />
        <meta
          name="description"
          content="Stay updated on AI trends and connect with experts at Random Walk's AI events. Join us for collaboration, innovation, and valuable networking opportunities."
        />
        <meta
          property="og:description"
          content="Stay updated on AI trends and connect with experts at Random Walk's AI events. Join us for collaboration, innovation, and valuable networking opportunities."
        />
        <link rel="canonical" href="https://randomwalk.ai/playlab/" />
      </Helmet> */}

      <section className="  bg-gradient_background4">
        <article className="flex flex-wrap items-center">
          <div className=" md:ml-5 lg:ml-20 md:pt-5 pt-16 lg:pt-10 flex items-center py-5 md:py-12">
            <div className="flex flex-row bg-cover bg-no-repeat rounded-2xl pt-10 ">
              <div className="flex  md:flex-row px-3  lg:items-start lg:pl-10 w-full">
                <div className="flex flex-col md:mt-20 md:ml-10  2xl:pt-32">
                  <h2 className="font-display  md:text-2xl lg:text-4xl 2xl:text-5xl font-semibold pb-2 md:pb-4 text-left">
                    Attend a Playlab event
                  </h2>
                  <p className="text-xs font-display md:text-sm lg:text-base 2xl:text-xl text-left">
                    Be part of Random Walk’s AI events, including webinars and
                    in-person gatherings. Whether you like live interaction or
                    prefer on-demand content, you’ll get access to top-notch
                    insights and cutting-edge thought leadership in the AI
                    sector.
                  </p>
                </div>

                <figure className="flex justify-end lg:w-full md:-mt-0 lg:-mt-20  lg:h-auto">
                  <img src={bubbles} alt="Event" />
                </figure>
              </div>
            </div>
          </div>
        </article>
      </section>

      <h2 className="text-center  text-lg font-bold md:text-2xl 2xl:text-3xl lg:text-4xl font-display  md:mt-10 py-5">
        Our Event Philosophy
      </h2>
      <section className="flex hidden md:hidden lg:block justify-center 2xl:px-96 pb-10 lg:px-10">
        <div className="w-full mr-12">
          <div className="w-full mr-12 mt-5">
            <article className="ml-0 flex flex-col lg:flex-row mt-5 pt-1">
              <div className="lg:flex lg:items-stretch lg:w-1/2">
                <figure className="lg:hidden">
                  <img
                    src={eventt}
                    alt="Our_Event"
                    className="w-full md:w-80 md:ml-56 ml-5 mb-5"
                  />
                </figure>

                <figure className="hidden lg:block lg:w-auto lg:mt-5 lg:mr-5 lg:flex-grow">
                  <img
                    src={eventt}
                    alt="Our_Event"
                    className="w-full ml-20 rounded-xl  h-full"
                  />
                </figure>
              </div>

              <article className="w-full md:ml-12 lg:w-1/2 lg:ml-28 lg:flex lg:flex-col lg:justify-between  lg:mt-5 lg:flex-grow">
                <p className="text-xs ml-5 font-display lg:text-sm mb-4">
                  Random Walk’s AI events are more than just gatherings. They
                  are immersive, leaving a lasting impact and surpassing all
                  expectations. Our philosophy for AI events is built on three
                  pillars.
                </p>
                <h2 className="text-xs ml-5  font-bold lg:font-display lg:text-base lg:pb-2 text-left">
                  01. Unforgettable Impact
                </h2>
                <p className="text-xs ml-5  lg:font-display lg:text-sm lg:pb-4">
                  We are dedicated to crafting AI event experiences that imprint
                  <br />a lasting impression, guaranteeing you walk away not
                  just <br />
                  inspired but genuinely enlightened.
                </p>
                <h2 className="text-xs ml-5 font-bold lg:font-display lg:text-base lg:pb-2 text-left">
                  02. Community and Collaboration
                </h2>
                <p className="text-xs ml-5  lg:font-display lg:text-sm lg:pb-4">
                  At Random Walk events, we go beyond imparting knowledge; we’re
                  here to cultivate a close community, nurture connections, and
                  foster fruitful collaborations within the AI industry. Join us
                  for AI gatherings that are more than just events – they’re
                  opportunities to build lasting bonds and engage in meaningful
                  partnerships.
                </p>
                <h2 className="text-xs ml-5  font-bold lg:font-display lg:text-base lg:pb-2 text-left">
                  03. Business Growth Acceleration
                </h2>
                <p className="text-xs ml-5 lg:font-display lg:text-sm">
                  We recognize that AI events should be a worthwhile investment.
                  Our goal is to organize events that not only offer valuable
                  insights but also yield concrete results, propelling your AI
                  initiatives to flourish. Join us at Random Walk Events for a
                  strategic blend of knowledge and actionable outcomes.
                </p>
              </article>
            </article>
          </div>
        </div>
      </section>

      <section className="flex  lg:hidden justify-center 2xl:px-96 pb-10 lg:px-10">
        <article className="ml-0 flex flex-col md:flex-row  pt-1">
          <figure className="md:flex  md:w-1/2">
            <figure className="md:w-auto pb-5 md:pb-0   px-5 md:translate-y-5 object-cover lg:translate-y-0 lg:mt-5 md:pl-5 md:flex-grow">
              <img src={eventt} alt="Our_Event" className=" rounded-xl" />
            </figure>
          </figure>

          <article className="w-full  md:w-1/2  md:flex md:flex-col   md:mt-5 md:flex-grow">
            <p className="text-xs  px-5  pb-3  md:px-0 font-display md:text-sm md:pb-2  ">
              Random Walk’s AI events are more than just gatherings. They are
              immersive, leaving a lasting impact and surpassing all
              expectations. Our philosophy for AI events is built on three
              pillars.
            </p>
            <article className="   px-10 md:px-0 ">
              <h2 className="font-bold  text-xs  md:font-display md:text-base pb-2 md:pb-0 text-left">
                {" "}
                01. Unforgettable Impact
              </h2>

              <p className="text-xs  md:font-display md:text-sm pb-2 md:pb-0">
                We are dedicated to crafting AI event experiences that imprint a
                lasting impression, guaranteeing you walk away not just inspired
                but genuinely enlightened.
              </p>
            </article>

            <article className="px-10 md:px-0    ">
              <h2 className="font-bold  text-xs  md:font-display md:text-base pb-2 md:pb-0 text-left">
                {" "}
                02. Community and Collaboration
              </h2>

              <p className="text-xs  md:font-display md:text-sm pb-2 md:pb-0">
                At Random Walk events, we go beyond imparting knowledge; we’re
                here to cultivate a close community, nurture connections, and
                foster fruitful collaborations within the AI industry. Join us
                for AI gatherings that are more than just events – they’re
                opportunities to build lasting bonds and engage in meaningful
                partnerships.
              </p>
            </article>

            <article className="px-10 md:px-0    ">
              <h2 className="font-bold  text-xs  md:font-display md:text-base pb-2 md:pb-0 text-left">
                {" "}
                03. Business Growth Acceleration
              </h2>

              <p className="text-xs  md:font-display md:text-sm pb-2 md:pb-0">
                We recognize that AI events should be a worthwhile investment.
                Our goal is to organize events that not only offer valuable
                insights but also yield concrete results, propelling your AI
                initiatives to flourish. Join us at Random Walk Events for a
                strategic blend of knowledge and actionable outcomes.
              </p>
            </article>
          </article>
        </article>
      </section>

      <section className="w-full px-5">
        <section className="max-w-[1240px] mx-auto md:pt-10">
          <div className="flex flex-col gap-1 px-4 text-[#666666]">
            {eventsData?.length > 0 ? (
              eventsData?.map((event, eventIndex) => {
                const eventImgs = event.eventImg || [];
                const redirectImgUrl = event.redirectImg?.url;
                const redirectLink = event.redirectLink;
                const videoUrl = event.videoUrl;
                const videoId = videoUrl ? getYouTubeVideoId(videoUrl) : null;

                return (
                  <article key={event.id} className="relative md:px-16">
                    <h3 className="md:text-2xl text-xl lg:text-4xl font-display font-bold mb-4 text-center pb-1">
                      {event.eventTitle}
                    </h3>

                    {videoId && (
                      <div className="mt-4 flex justify-center">
                        <iframe
                          width="1500"
                          height="500"
                          src={`https://www.youtube.com/embed/${videoId}`}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title="YouTube video player"
                        ></iframe>
                      </div>
                    )}

                    <div className="grid grid-cols-1 pt-5 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                      {eventImgs.map((image, imageIndex) => (
                        <img
                          key={imageIndex}
                          src={`${image.url}`}
                          alt="Event"
                          className="w-full h-auto cursor-pointer"
                          onClick={() => openImageModal(eventIndex, imageIndex)}
                        />
                      ))}
                    </div>
                    <article className="flex justify-center">
                      <div className="flex justify-center mt-8 mb-8">
                        {redirectImgUrl && (
                          <NavLink to={redirectLink} className="mx-auto">
                            <img
                              src={`${redirectImgUrl}`}
                              alt="Redirect"
                              className="w-full h-auto"
                              style={{ maxWidth: "500px", cursor: "pointer" }}
                            />
                          </NavLink>
                        )}
                      </div>
                    </article>
                  </article>
                );
              })
            ) : (
              <p>No events available</p>
            )}

            {selectedEvent !== null && selectedImageIndex !== null && (
              <section className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
                <div className="">
                  <a
                    className="absolute top-2 right-2 cursor-pointer text-white text-5xl font-bold"
                    onClick={closeModal}
                  >
                    &times;
                  </a>

                  <a
                    className="absolute left-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-white text-5xl font-bold"
                    onClick={() => navigateImage(-1)}
                  >
                    &#8249;
                  </a>
                  <a
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-white text-5xl font-bold"
                    onClick={() => navigateImage(1)}
                  >
                    &#8250;
                  </a>

                  <img
                    src={
                      eventsData[selectedEvent].eventImg[selectedImageIndex].url
                    }
                    alt="Popup"
                    className="object-contain"
                  />
                </div>
              </section>
            )}

            <h2 className="text-center text-[#666666] text-4xl font-bold md:py-1 font-display">
              Past Speakers
            </h2>

            <section className="relative flex justify-center font-display pb-10">
              <a
                onClick={slideLeft}
                className="absolute rounded-full hover:bg-blue-400 -left-2 top-1/2 transform -translate-y-16 md:-translate-y-20 md:scale-90 lg:scale-100 lg:-translate-y-12 bg-gray-100 hover:text-white text-blue-500 p-1 z-10"
                style={{
                  width: "3rem",
                  height: "3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="rounded-full hover:bg-blue-400 p-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={3}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15 19l-7-7 7-7"
                    />
                  </svg>
                </div>
              </a>

              <article className="carousel-container overflow-hidden w-full lg:max-w-[1200px] 2xl:max-w-[1200px] md:max-w-[1200px]">
                <figure
                  className="carousel-track flex transition-transform duration-300 ease-in-out"
                  style={{
                    transform: `translateX(-${
                      slideIndex * (carouselWidth / itemsPerView)
                    }px)`,
                  }}
                >
                  {speakersData?.map((speaker) => (
                    <figure
                      key={speaker.id}
                      className="flex-shrink-0"
                      style={{ width: `${carouselWidth / itemsPerView}px` }}
                    >
                      <div className="flex flex-col items-center p-2">
                        <img
                          src={`${speaker.speakerImg.url}`}
                          alt={speaker.speakerName}
                          className="md:w-56 md:h-60 lg:w-72 lg:h-96 object-cover mb-4"
                        />
                        <div className="md:text-xs lg:text-base text-xs font-semibold mb-1">
                          {speaker.speakerName}
                        </div>
                        <div className="flex text-xs md:text-xs text-center">
                          {`${speaker.speaker_designation}, ${speaker.speaker_company}`}
                        </div>
                      </div>
                    </figure>
                  ))}
                </figure>
              </article>

              <a
                onClick={slideRight}
                className="absolute hover:bg-blue-400 rounded-full -right-2 top-1/2 -translate-y-16 transform md:-translate-y-20 md:scale-90 lg:scale-100 lg:-translate-y-12 bg-gray-100 text-blue-500 hover:text-white p-1 z-10"
                style={{
                  width: "3rem",
                  height: "3rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div className="rounded-full hover:bg-blue-400 p-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={3}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </a>

              <article className="absolute bottom-0 translate-y-4 left-0 right-0 flex justify-center mb-10">
                {Array.from({
                  length: Math.min(
                    Math.ceil((speakersData?.length ?? 0) / itemsPerView),
                    slideIndex + 1
                  ),
                }).map((_, index) => (
                  <div
                    key={index}
                    className={`w-2 h-2 mt-5 rounded-full mx-1 ${
                      index === activeSlideIndex ? "bg-black" : "bg-gray-300"
                    }`}
                  ></div>
                ))}
              </article>
            </section>
          </div>
        </section>
      </section>

      <section className=" mt-20 ">
        <Popup utmContent={'events'} />
      </section>
    </section>
  );
};
export default Events;
