import React, { useState ,useEffect} from "react";
import casestudy1 from "../assets/Casestudy/casestudy1.webp";
import useFetch from "../hooks/useFetch.js";
import Lottie from "react-lottie";
import pii from "../assets/Blogs/pi.webp";
import loading from "../pages/loading.json";
import rightarrow from "../assets/Blogs/rightarrow.webp";
import { Link } from "react-router-dom";
import Popup from "../components/popup.js";
import { Helmet } from "react-helmet-async";


// function titleToSlug(title) {
//   return title
//     .toLowerCase()
//     .replace(/[^\w\s-]/g, "")
//     .replace(/[\s_-]+/g, "-")
//     .replace(/^-+|-+$/g, "");
// }

function titleToSlug(title) {
  // If title is null or undefined, return a default slug
  if (!title) return "untitled";
  
  return title
    .toLowerCase()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
}

function categoryToSlug(category) {
  return category
    .toLowerCase()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
}

const Casestudy = () => {
  const {
    loading: casestudyLoading,
    data: casestudyData,
    error: casestudyError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/casestudy/data.json`
  );

  console.log("blogs", casestudyData);
  const [selectedCategory, setSelectedCategory] = useState("all");

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // Organize case studies by category
  const groupedCaseStudies = casestudyData
    ? casestudyData.reduce((acc, caseStudy) => {
        const category = caseStudy.case_category || "Others";
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(caseStudy);
        return acc;
      }, {})
    : {};

  const predefinedCategoryOrder = [
    "AI Integration",
    "AI Managed Services",
    "AI Workshop for Corporates",
    "Others",
  ];
  useEffect(() => {
    // Set page title
    document.title = " AI Case Studies | Random Walk Innovations";
  
    // Set meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', "Explore Random Walk's innovative AI case studies across various industries and technologies.");
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = "Explore Random Walk's innovative AI case studies across various industries and technologies.";
      document.head.appendChild(meta);
    }
  
    // Add or update Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement('meta');
      ogTitle.setAttribute('property', 'og:title');
      document.head.appendChild(ogTitle);
    }
    ogTitle.setAttribute('content', " AI Case Studies | Random Walk Innovations");
  
    // Add or update Open Graph description
    let ogDescription = document.querySelector('meta[property="og:description"]');
    if (!ogDescription) {
      ogDescription = document.createElement('meta');
      ogDescription.setAttribute('property', 'og:description');
      document.head.appendChild(ogDescription);
    }
    ogDescription.setAttribute('content', "Explore Random Walk's innovative AI case studies across various industries and technologies.");
  
    // Add viewport meta tag
    let viewport = document.querySelector('meta[name="viewport"]');
    if (!viewport) {
      viewport = document.createElement('meta');
      viewport.name = "viewport";
      document.head.appendChild(viewport);
    }
    viewport.setAttribute('content', "width=device-width, initial-scale=1");
  
    // Add theme-color meta tag
    let themeColor = document.querySelector('meta[name="theme-color"]');
    if (!themeColor) {
      themeColor = document.createElement('meta');
      themeColor.name = "theme-color";
      document.head.appendChild(themeColor);
    }
    themeColor.setAttribute('content', "#000000");
  
    // Add Open Graph image
    let ogImage = document.querySelector('meta[property="og:image"]');
    if (!ogImage) {
      ogImage = document.createElement('meta');
      ogImage.setAttribute('property', 'og:image');
      document.head.appendChild(ogImage);
    }
    ogImage.setAttribute('content', `${process.env.PUBLIC_URL}/rw_logo.png`);
  
    // Add Open Graph type
    let ogType = document.querySelector('meta[property="og:type"]');
    if (!ogType) {
      ogType = document.createElement('meta');
      ogType.setAttribute('property', 'og:type');
      document.head.appendChild(ogType);
    }
    ogType.setAttribute('content', "website");
  
    // Add canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.rel = "canonical";
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.href = "https://randomwalk.ai/casestudy";
  }, []);
  // Filter the predefined category order to include only available categories
  const availableCategories = [
    "all", // Always include "all" at the start
    ...predefinedCategoryOrder.filter(
      (category) =>
        groupedCaseStudies[category] && groupedCaseStudies[category].length > 0
    ),
  ];
  const getFilteredCaseStudies = () => {
    if (selectedCategory === "all") {
      // For "All Categories", show only 3 case studies per category
      return predefinedCategoryOrder.reduce((acc, category) => {
        if (groupedCaseStudies[category]) {
          // Sort by date and take top 3
          acc[category] = groupedCaseStudies[category]
            .sort((a, b) => new Date(b.case_date) - new Date(a.case_date))
            .slice(0, 3);
        }
        return acc;
      }, {});
    }
    // When a specific category is selected, show all case studies for that category
    return {
      [selectedCategory]: groupedCaseStudies[selectedCategory]
        ? groupedCaseStudies[selectedCategory].sort(
            (a, b) => new Date(b.case_date) - new Date(a.case_date)
          )
        : [],
    };
  };

  const filteredCaseStudies = getFilteredCaseStudies();

  if (casestudyLoading) {
    return (
      <div className="w-full flex justify-center items-center min-h-screen">
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    );
  }

  return (
    <div className="">
      {/* <Helmet>
        <title>AI Case Studies | Random Walk Innovations</title>
        <meta
          property="og:title"
          content="AI Case Studies | Random Walk Innovations"
        />
        <meta
          name="description"
          content="Explore Random Walk's innovative AI case studies across various industries and technologies."
        />
      </Helmet> */}

      {/* Hero Section - Kept similar to previous implementation */}
      <section className="font-display bg-[#3E59B7] md:py-20 lg:py-16 2xl:py-32 flex flex-col md:flex-row justify-start items-center 2xl:justify-center px-12 md:px-10">
        <article className="flex items-center mt-24 md:mt-10   lg:mt-0 justify-center text-center md:text-left md:px-0 lg:px-0 lg:pl-16 2xl:pl-0">
          <div className="text-white">
            <p className=" mb-3 md:mb-0 md:text-xs text-xs lg:text-sm 2xl:text-xl inline-block px-2 py-1 bg-white rounded font-bold">
              <span className="bg-gradient-to-r from-[#3E59B7] to-[#1B2751] bg-clip-text text-transparent">
                Case Studies
              </span>
            </p>
            <h2 className="text-xl 2xl:hidden text-center md:text-left   md:text-2xl md:mt-5 lg:text-4xl 2xl:text-6xl font-bold md:mb-6 mb-3">
              Our AI Success Chronicles:
              <br />
              One Story at a Time
            </h2>
            <h2 className="text-xl max-2xl:hidden text-center md:text-left  md:text-2xl md:mt-5 lg:text-4xl 2xl:text-6xl font-bold md:mb-6 mb-3">
              Our AI Success Chronicles:
              <br />
              One Story at a Time
            </h2>
            <p className="md:text-xs text-xs lg:text-lg 2xl:text-xl font-light">
              Witness how Random Walk's AI solutions <br /> deliver significant
              cost savings and operational <br /> breakthroughs across
              industries.
            </p>
          </div>
        </article>

        <figure className="max-md:hidden  md:my-10 md:pl-10">
          <img
            src={casestudy1}
            alt="RandomWalk"
            className="w-[250px] h-[250px] md:w-[300px] md:h-[300px] lg:w-[480px] lg:h-[500px] 2xl:w-[400px] 2xl:h-[400px]"
          />
        </figure>

        <figure className=" flex justify-center items-center block my-5 md:hidden max-w-80 pb-4 w-80 md:p-3">
          <img
            src={casestudy1}
            alt="RandomWalk"
            className="w-[200px] h-[200px]"
          />
        </figure>
      </section>

      {/* Category Filter Buttons */}
      <div className=" max-lg:hidden flex flex-wrap justify-center gap-4 bg-blue-50 pt-10 px-4">
        <div className="bg-white rounded-xl ">
          {availableCategories.map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`px-6 py-2 rounded-full font-bold text-base transition-colors duration-200 ${
                selectedCategory === category
                  ? "bg-white text-[#666666]"
                  : "bg-white text-[#666666] hover:underline hover:underline-gray-500"
              }`}
            >
              {category === "all" ? "All Categories" : category}
            </button>
          ))}
        </div>
      </div>

      <div className="lg:hidden flex flex-wrap justify-center gap-4 bg-blue-50 pt-10 px-4">
        <div className="bg-white  rounded-xl w-full">
          <div
            className="flex flex-nowrap gap-2 md:gap-4 overflow-x-auto py-2 rounded-xl md:py-0"
            style={{
              scrollbarWidth: "none", // For Firefox
              msOverflowStyle: "none", // For IE and Edge
            }}
          >
            {/* Hide scrollbar for WebKit browsers */}
            <style>
              {`
          .flex-nowrap::-webkit-scrollbar {
            display: none; /* Hide scrollbar */
          }
        `}
            </style>
            {availableCategories.map((category) => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={`px-2 md:px-6 py-2 font-bold text-xs text-left md:text-base transition-colors duration-200 whitespace-nowrap ${
                  selectedCategory === category
                    ? "bg-white text-xs md:text-base text-[#666666]"
                    : "bg-white text-xs md:text-base text-[#666666] hover:underline hover:underline-gray-500"
                }`}
              >
                {category === "all" ? "All Categories" : category}
              </button>
            ))}
          </div>
        </div>
      </div>

      {/* Filtered Case Studies */}

      {Object.entries(filteredCaseStudies).map(([category, caseStudies]) => (
        <section
          key={category}
          className="md:pt-10  pt-5 px-5 md:px-0  bg-blue-50 text-[#666666] flex justify-center pb-10"
        >
          <article className="flex px-5 md:px-10 lg:px-20  2xl:px-72 flex-wrap justify-center">
            <div className="category-container w-full">
              <div className="flex items-center lg:mb-5 mb-1 mt-5">
                <h2 className="text-sm text-[#666666] lg:text-xl mb-2 font-bold font-display">
                  {category}
                </h2>
                <img
                  src={pii}
                  alt="Category Arrow"
                  className="ml-2  w-3 h-3 md:h-4 md:w-4 mb-2"
                />
              </div>
              <div className="grid grid-cols-1  md:grid-cols-3 lg:grid-cols-3 gap-6">
                {caseStudies
                  ?.sort(
                    (a, b) => new Date(b.case_date) - new Date(a.case_date)
                  )
                  .map((caseStudy) => (
                    <CaseStudyCard key={caseStudy.id} caseStudy={caseStudy} />
                  ))}
              </div>
            </div>
          </article>
        </section>
      ))}

      <section className="mt-20">
        <Popup utmContent={"casestudy"} />
      </section>
    </div>
  );
};

const CaseStudyCard = ({ caseStudy }) => {
  return (
    <Link
      to={`/casestudy/${titleToSlug(caseStudy.case_title)}`}
      className="bg-white rounded-xl overflow-hidden drop-shadow-xl lg:drop-shadow-xl w-full flex flex-col relative h-full hover:shadow-2xl transition-shadow duration-300"
    >
      <img
        className="object-cover h-56 w-full"
        src={caseStudy.case_img?.url || casestudy1}
        alt={caseStudy.case_title}
      />
      <div className="p-4 flex flex-col flex-grow">
        <h3 className="text-lg font-display font-bold my-3 text-[#666666]">
          {caseStudy.case_title}
        </h3>

        <p
          className="text-black font-display text-sm my-3 overflow-hidden flex-grow"
          style={{
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
          }}
        >
          {caseStudy.case_desc}
        </p>
        <div className="flex justify-between items-center">
          <div className="ml-auto">
            <img src={rightarrow} alt="Case Study Arrow" className="w-4 h-4" />
          </div>
        </div>
      </div>
    </Link>
  );
};

export default Casestudy;
