import React from "react";
import styles from "../styles/HeroSection.module.css";
import logo from "../assets/logo.svg";
import brandImage from "../assets/brand2.webp";
import brandImageMob from "../assets/hero-banner-mobile.webp";
import { isMobile } from "../config.tsx";
import { Helmet } from "react-helmet-async";
import { useEffect, useRef } from "react";

const HeroSection = ({ onOpenLeadForm }) => {
  const renderOnMobile = isMobile();

  useEffect(() => {
    // Set page title
    document.title = "BrandCut | AI Sponsorship Analytics | Random Walk";

    // Set meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', "AI sponsorship analytics platform for real-time brand detection. Measure brand impressions, detect brand logos, and get actionable insights for better sponsorship ROI.");
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = "AI sponsorship analytics platform for real-time brand detection. Measure brand impressions, detect brand logos, and get actionable insights for better sponsorship ROI.";
      document.head.appendChild(meta);
    }

    // Add or update Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement('meta');
      ogTitle.setAttribute('property', 'og:title');
      document.head.appendChild(ogTitle);
    }
    ogTitle.setAttribute('content', "BrandCut | AI Sponsorship Analytics | Random Walk");

    // Add or update Open Graph description
    let ogDescription = document.querySelector('meta[property="og:description"]');
    if (!ogDescription) {
      ogDescription = document.createElement('meta');
      ogDescription.setAttribute('property', 'og:description');
      document.head.appendChild(ogDescription);
    }
    ogDescription.setAttribute('content', "AI sponsorship analytics platform for real-time brand detection. Measure brand impressions, detect brand logos, and get actionable insights for better sponsorship ROI.");

    // Add viewport meta tag
    let viewport = document.querySelector('meta[name="viewport"]');
    if (!viewport) {
      viewport = document.createElement('meta');
      viewport.name = "viewport";
      document.head.appendChild(viewport);
    }
    viewport.setAttribute('content', "width=device-width, initial-scale=1");

    // Add theme-color meta tag
    let themeColor = document.querySelector('meta[name="theme-color"]');
    if (!themeColor) {
      themeColor = document.createElement('meta');
      themeColor.name = "theme-color";
      document.head.appendChild(themeColor);
    }
    themeColor.setAttribute('content', "#000000");

    // Add Open Graph image
    let ogImage = document.querySelector('meta[property="og:image"]');
    if (!ogImage) {
      ogImage = document.createElement('meta');
      ogImage.setAttribute('property', 'og:image');
      document.head.appendChild(ogImage);
    }
    ogImage.setAttribute('content', `${process.env.PUBLIC_URL}/rw_logo.png`);

    // Add Open Graph type
    let ogType = document.querySelector('meta[property="og:type"]');
    if (!ogType) {
      ogType = document.createElement('meta');
      ogType.setAttribute('property', 'og:type');
      document.head.appendChild(ogType);
    }
    ogType.setAttribute('content', "website");

    // Add canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.rel = "canonical";
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.href = "https://randomwalk.ai/brandcut/";
  }, []);


  return (
    <>
      {/* <Helmet>
        <title>BrandCut | AI Sponsorship Analytics | Random Walk</title>
        <meta
          name="description"
          content="AI sponsorship analytics platform for real-time brand detection. Measure brand impressions, detect brand logos, and get actionable insights for better sponsorship ROI."
        />

       
        <meta property="og:title" content="BrandCut | AI Sponsorship Analytics | Random Walk" />
        <meta
          property="og:description"
          content="AI sponsorship analytics platform for real-time brand detection. Measure brand impressions, detect brand logos, and get actionable insights for better sponsorship ROI."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://randomwalk.ai/brandcut/" />

      
        <link rel="canonical" href="https://randomwalk.ai/brandcut/" />
      </Helmet> */}

      <section className={styles.hero}>
        <img className={styles.resp} src={logo} alt="Brand Logo" />
        <div className={styles.content}>
          <img className={styles.logo} src={logo} alt="BrandCut Logo" />
          <h1>
            Measure Your Brand <br />
            Sponsorship Strategy with <br />{" "}
            <span style={{ color: "#394CA7", fontWeight: "bold" }}>
              AI Brand Detection
            </span>
          </h1>

          <button onClick={onOpenLeadForm} className={styles.button}>
            Give it a Try for FREE
          </button>
        </div>
        <div className={styles.image}>
          <img
            src={renderOnMobile ? brandImageMob : brandImage}
            alt="AI Brand Detection"
          />
        </div>
      </section>
    </>
  );
};

export default HeroSection;
