import React, { useEffect } from "react";
import styles from "../styles/SectionFive.module.css";
import img1 from "../assets/i1.svg";
import img2 from "../assets/i2.svg";
import img3 from "../assets/i3.svg";

const SectionFive = ({ onOpenLeadForm }) => {
  useEffect(() => {
    const handleScroll = () => {
      const section = document.querySelector(`.${styles.section}`);
      if (section) {
        const rect = section.getBoundingClientRect();
        const windowHeight = window.innerHeight;
        if (rect.top <= windowHeight && rect.bottom >= 0) {
          section.classList.add(styles.visible);
        } else {
          section.classList.remove(styles.visible);
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className={styles.section}>
    <h2 className={styles.heading}>
      <span className={styles.highlight}>Powerful Features</span> <br /> to
      advanced <br />
      Sponsorship Analytics
    </h2>
    <div className={styles.imageGrid}>
      <div className={`${styles.imageWrapper} ${styles.card1}`}>
        <img className={styles.image} src={img1} alt="Image 1" />
        <div className={`${styles.textCard} ${styles.card1Text}`}>
          <h3 className={styles.cardHeading}>Versatility</h3>
          <p className={styles.description}>
            Applicable across industries and 
            use cases for maximum flexibility in sponsorship analytics
          </p>
        </div>
      </div>
      <div className={`${styles.imageWrapper} ${styles.card2}`}>
        <div className={`${styles.textCard} ${styles.card3Text}`}>
          <h3 className={styles.cardHeading}>Fast and Efficient</h3>
          <p className={styles.description}>
          Save time and resources with automated brand logo detection and AI sponsorship analytics
          </p>
        </div>
        <img className={styles.image} src={img3} alt="Image 3" />
      </div>
      <div className={`${styles.imageWrapper} ${styles.card3}`}>
        <img className={styles.image} src={img2} alt="Image 2" />
        <div className={`${styles.textCard} ${styles.card2Text}`}>
          <h3 className={styles.cardHeading}>Actionable Insights</h3>
          <p className={styles.description}>
          Make data-driven decisions to <br/>
          enhance brand visibility and ROI
          </p>
        </div>
      </div>
    </div>
    <button onClick={onOpenLeadForm} className={styles.button}>
      See How It Works
    </button>
  </section>
  );
};

export default SectionFive;
