import React, { useState, useEffect, useRef, Link, useCallback } from "react";
import { Client, Databases, ID } from "appwrite";
import { BiChevronLeft } from "react-icons/bi";
import { BiChevronRight } from "react-icons/bi";
import { CSSTransition } from "react-transition-group";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./Fortunecookie.css";
import useFetch from "../hooks/useFetch.js";
import arrowup from "../assets/FortuneCookie/arrowup.webp";
import arrowdown from "../assets/FortuneCookie/Arrowdown.webp";
import pinkarrow from "../assets/FortuneCookie/pinkarrow.webp";
import { Helmet } from "react-helmet-async";

import llmc from "../assets/FortuneCookie/llmc3.webp";
import aug from "../assets/FortuneCookie/aug3.webp";
import lock from "../assets/FortuneCookie/lock3.webp";
import chain from "../assets/FortuneCookie/chain3.webp";
import search from "../assets/FortuneCookie/search3.webp";
import multi from "../assets/FortuneCookie/multi3.webp";

import itGif from "../assets/FortuneCookie/it.gif";
import hrGif from "../assets/FortuneCookie/hr.gif";
import salesGif from "../assets/FortuneCookie/sales.gif";
import marketingGif from "../assets/FortuneCookie/marketing.gif";
import financeGif from "../assets/FortuneCookie/finance.gif";
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from "libphonenumber-js";
import spiral from "../assets/FortuneCookie/spiral.svg";
import enrichLLMs from "../assets/FortuneCookie/enrichLLMs.webp";
import trainLLM from "../assets/FortuneCookie/trainLLM3.webp";
import pattern from "../assets/FortuneCookie/Pattern.svg";

import salesModel from "../assets/FortuneCookie/salesModel.webp";
import hrModel from "../assets/FortuneCookie/HRModel.webp";
import renameImage from "../assets/FortuneCookie/rename.webp";
import marketingModel from "../assets/FortuneCookie/marketingModel.webp";
import rename2Image from "../assets/FortuneCookie/rename2.webp";
import itPrompt from "../assets/FortuneCookie/ITPrompt.webp";
import hrPrompt from "../assets/FortuneCookie/HRPrompt.webp";
import salesPrompt from "../assets/FortuneCookie/salesPrompt.webp";
import marketingPrompt from "../assets/FortuneCookie/marketingPrompt.webp";
import financePrompt from "../assets/FortuneCookie/financePrompt.webp";
import itPromptBg from "../assets/FortuneCookie/ITPromptBg.webp";
import hrPromptBg from "../assets/FortuneCookie/HRPromptBg.webp";
import salesPromptBg from "../assets/FortuneCookie/salesPromptBg.webp";
import financePromptBg from "../assets/FortuneCookie/financePromptBg.webp";
import VDandKG from "../assets/FortuneCookie/VDandKG.webp";
import home1 from "../assets/FortuneCookie/home1.webp";
import home2 from "../assets/FortuneCookie/home2.webp";
import homepage from "../assets/FortuneCookie/Homepage.mp4";
import Lottie from "react-lottie";
import loading from "../pages/loading.json";

const Fortunecookie = ({ utmContent }) => {
  const {
    loading: blogsLoading,
    data: blogs,
    error: blogsError,
  } = useFetch(
    `https://devrw.blob.core.windows.net/rw-website/strapi-data/blog/data.json`
  );
  useEffect(() => {
    // Set page title
    document.title =
      "AI Fortune Cookie | Secure Knowledge Models for Enterprises | Random Walk";

    // Set meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute(
        "content",
        "Fortune Cookie uses generative AI for efficient data handling, retrieval, data analytics and visualization. It provides secure, flexible, and cost-effective insights while keeping your data in-house and secure."
      );
    } else {
      const meta = document.createElement("meta");
      meta.name = "description";
      meta.content =
        "Fortune Cookie uses generative AI for efficient data handling, retrieval, data analytics and visualization. It provides secure, flexible, and cost-effective insights while keeping your data in-house and secure.";
      document.head.appendChild(meta);
    }

    // Add or update Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement("meta");
      ogTitle.setAttribute("property", "og:title");
      document.head.appendChild(ogTitle);
    }
    ogTitle.setAttribute(
      "content",
      "AI Fortune Cookie | Secure Knowledge Models for Enterprises | Random Walk"
    );

    // Add or update Open Graph description
    let ogDescription = document.querySelector(
      'meta[property="og:description"]'
    );
    if (!ogDescription) {
      ogDescription = document.createElement("meta");
      ogDescription.setAttribute("property", "og:description");
      document.head.appendChild(ogDescription);
    }
    ogDescription.setAttribute(
      "content",
      "Fortune Cookie uses generative AI for efficient data handling, retrieval, data analytics and visualization. It provides secure, flexible, and cost-effective insights while keeping your data in-house and secure."
    );

    // Add viewport meta tag
    let viewport = document.querySelector('meta[name="viewport"]');
    if (!viewport) {
      viewport = document.createElement("meta");
      viewport.name = "viewport";
      document.head.appendChild(viewport);
    }
    viewport.setAttribute("content", "width=device-width, initial-scale=1");

    // Add theme-color meta tag
    let themeColor = document.querySelector('meta[name="theme-color"]');
    if (!themeColor) {
      themeColor = document.createElement("meta");
      themeColor.name = "theme-color";
      document.head.appendChild(themeColor);
    }
    themeColor.setAttribute("content", "#000000");

    // Add Open Graph image
    let ogImage = document.querySelector('meta[property="og:image"]');
    if (!ogImage) {
      ogImage = document.createElement("meta");
      ogImage.setAttribute("property", "og:image");
      document.head.appendChild(ogImage);
    }
    ogImage.setAttribute("content", `${process.env.PUBLIC_URL}/rw_logo.png`);

    // Add Open Graph type
    let ogType = document.querySelector('meta[property="og:type"]');
    if (!ogType) {
      ogType = document.createElement("meta");
      ogType.setAttribute("property", "og:type");
      document.head.appendChild(ogType);
    }
    ogType.setAttribute("content", "website");

    // Add canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement("link");
      canonicalLink.rel = "canonical";
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.href = "https://randomwalk.ai/fortunecookie/";
  }, []);
  // Lottie animation options
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loading,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const formatUrlFriendlyTitle = (title) => {
    return title
      .toLowerCase()
      .replace(/\s+/g, "-")
      .replace(/[^a-z0-9-]/g, "");
  };

  const podcastData = blogs || [];
  const filteredBlogs = podcastData
    ?.filter((blog) => blog.blogCategory === "KMS AND RAG")
    ?.sort((a, b) => new Date(b.blogDate) - new Date(a.blogDate)) // Sort by date descending
    ?.slice(0, 5); // Take only the first 4 blogs

  const [activeIdx, setActiveIdx] = useState(0);

  const handleNext = useCallback(() => {
    setActiveIdx((prevIdx) => (prevIdx + 1) % filteredBlogs?.length); // Wraps to 0 after the last blog
  }, [filteredBlogs?.length]);

  const handlePrev = useCallback(() => {
    setActiveIdx(
      (prevIdx) => (prevIdx - 1 + filteredBlogs?.length) % filteredBlogs?.length
    ); // Wraps to last index if going backward
  }, [filteredBlogs?.length]);

  const getTransitionClass = (idx) => {
    if (idx === activeIdx) return "opacity-100 z-10"; // Current blog visible
    return "opacity-0 z-0"; // All other blogs hidden
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmailId] = useState("");

  const [utmParams, setUtmParams] = useState({
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_id: "",
    utm_term: "",
    utm_content: "",
  });
  const [errors, setErrors] = useState({});

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };
  const resetForm = () => {
    setName("");
    setDesignation("");
    setCompanyName("");

    setEmailId("");
    setPhoneNumber("");

    setIsChecked(true);
    setErrors({});
  };
  const closeModal = () => {
    setIsModalOpen(false);
    resetForm();
  };

  const client = new Client()
    .setEndpoint(process.env.REACT_APP_ENDPOINT)
    .setProject(process.env.REACT_APP_PROJECT_ID);

  const databases = new Databases(client);

  const handlePhoneNumberChange = (number, country) => {
    setPhoneNumber(number);

    try {
      // Ensure number starts with +
      const fullNumber = number.startsWith("+") ? number : `+${number}`;

      // Check if the number is valid for the selected country
      if (isValidPhoneNumber(fullNumber)) {
        const phoneNumberObj = parsePhoneNumberFromString(fullNumber);
        const nationalNumber = phoneNumberObj.nationalNumber;

        // Get expected length based on country
        const expectedLength = phoneNumberObj.nationalNumber?.length;
        const actualLength = nationalNumber?.length;

        if (actualLength < expectedLength) {
          setErrors((prev) => ({
            ...prev,
            phoneNumber: `Phone number is too short - needs ${expectedLength} digits`,
          }));
        } else if (actualLength > expectedLength) {
          setErrors((prev) => ({
            ...prev,
            phoneNumber: `Phone number is too long - should be ${expectedLength} digits`,
          }));
        } else {
          // Clear the error when valid
          setErrors((prev) => ({
            ...prev,
            phoneNumber: "",
          }));
        }
      } else {
        setErrors((prev) => ({
          ...prev,
          phoneNumber: "Please enter a valid phone number",
        }));
      }
    } catch (error) {
      console.error("Phone validation error:", error);
      setErrors((prev) => ({
        ...prev,
        phoneNumber: "Please enter a valid phone number",
      }));
    }
  };

  useEffect(() => {
    const captureUtmParams = () => {
      const params = new URLSearchParams(window.location.search);
      const utmFields = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_id",
        "utm_term",
        "utm_content",
      ];

      const newUtmParams = { utm_content: utmContent };
      utmFields.forEach((field) => {
        newUtmParams[field] = params.get(field) || "";
      });
      setUtmParams(newUtmParams);
    };

    captureUtmParams();
    window.addEventListener("popstate", captureUtmParams);

    return () => {
      window.removeEventListener("popstate", captureUtmParams);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const newErrors = {};

    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
    }

    if (Object.keys(newErrors)?.length > 0) {
      setErrors(newErrors);
      return;
    }

    try {
      const fullNumber = phoneNumber.startsWith("+")
        ? phoneNumber
        : `+${phoneNumber}`;
      if (!isValidPhoneNumber(fullNumber)) {
        setErrors((prev) => ({
          ...prev,
          phoneNumber: "Please enter a valid phone number", // Changed from phoneNumber to phonenumberint
        }));
        return;
      }
      const formData = {
        name,
        designation,
        companyName,
        phoneNumber,
        email,
        utmSource: utmParams.utm_source,
        utmMedium: utmParams.utm_medium,
        utmCampaign: utmParams.utm_campaign,
        utmId: utmParams.utm_id,
        utmTerm: utmParams.utm_term,
        utmContent: "fortunecookie", // Set the utmContent to "homepage"
      };
      await Promise.all([
        databases.createDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_ID1,
          ID.unique(),
          formData
        ),
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/submit-fortunecookie`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }).then(async (response) => {
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Failed to submit form");
          }
        }),
      ]);
      // Close the modal and show success message
      setIsModalOpen(false);
      setIsSuccessOpen(true);
      setIsLoading(false);
      resetForm();
    } catch (error) {
      console.error("Error creating document:", error);
    }
  };

  const closeSuccessModal = () => {
    setIsSuccessOpen(false);
  };

  const signupRef = useRef(null);

  useEffect(() => {
    // Check if the URL contains #signup
    if (window.location.hash === "#signup") {
      signupRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  const [isHovered, setIsHovered] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  const [currentIndex, setCurrentIndex] = useState(0);

  // const [activeIdx, setActiveIdx] = useState(0);
  const [intervalId, setIntervalId] = useState(null);

  const tabContent = {
    "Refine Your Objectives with our Workshop": [
      "Clarify your goals through interactive workshops",
      "Develop custom solutions with our industry experts",
    ],
    "Data Source Evaluation and Enhancement": [
      "Evaluate data sources for quality and relevance",
      "Streamline updates for seamless knowledge graph integration",
    ],
    "Vector Database and Knowledge Graph Creation": [
      "Integrate diverse data into knowledge graphs",
      "Utilize graph databases for storage and vector databases for swift analysis",
    ],
    "Defining Database Queries": [
      "Refine queries and gain insights from compiled data",
      "Use scalable graph database for seamless information integration",
    ],
    "Custom LLMs and Natural Language Queries": [
      "Enhance data accessibility with custom LLMs and knowledge graphs",
      "Empower decision-making and innovation",
    ],
  };
  const tabTexts = ["Now", "Week 1", "Week 2", "Week 3", "Week 4"];

  useEffect(() => {
    const id = setInterval(() => {
      setCurrentTab((currentTab + 1) % tabs2?.length);
    }, 5000);
    setIntervalId(id);

    return () => clearInterval(id);
  }, [currentTab]);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTab((currentTab + 1) % tabs?.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [currentTab]);

  const tabs = [
    "Information Technology",
    "Human Resources",
    "Sales",
    "Marketing",
    "Finance",
  ];
  const tabGifs = [itGif, hrGif, salesGif, marketingGif, financeGif];
  const tabs2 = [
    "Refine Your Objectives with our Workshop",
    "Data Source Evaluation and Enhancement",
    "Vector Database and Knowledge Graph Creation",
    "Defining Database Queries",
    "Custom LLMs and Natural Language Queries",
  ];
  const tabText = [
    "This data visualization tool optimizes tasks like software provisioning, password resets, and troubleshooting, for efficient IT operations. ",
    "Manage leave request, access payroll and benefits, share updates and offer personalized HR assistance.",
    "Access account details, authorize discounts, track metrics, and streamline client communication for business intelligence and reporting.",
    "Leverage business intelligence software to track campaign performance, access brand guidelines, and engage audiences effectively.",
    "Streamline purchase orders, generate financial reports, and manage risk with enterprise-grade data visualization tool using Gen AI.",
  ];

  const tabModels = [
    salesModel,
    hrModel,
    renameImage,
    marketingModel,
    rename2Image,
  ];
  const tabPrompt = [
    itPrompt,
    hrPrompt,
    salesPrompt,
    marketingPrompt,
    financePrompt,
  ];
  const tabPromptBg = [
    itPromptBg,
    salesPromptBg,
    financePromptBg,
    hrPromptBg,
    financePromptBg,
  ];

  const [previousTab, setPreviousTab] = useState(null);
  const handleNextTab = () => {
    setCurrentTab((currentTab + 1) % tabs?.length);
  };

  const handleTabChange = (index) => {
    setPreviousTab(currentTab);
    setCurrentTab(index);
  };

  const [transitionDirection, setTransitionDirection] = useState("forward");
  useEffect(() => {
    if (previousTab !== null) {
      setTransitionDirection(currentTab > previousTab ? "forward" : "backward");
    }
    setPreviousTab(currentTab);
  }, [currentTab]);

  const handleTabClick = (index) => {
    if (index > currentTab) {
      setTransitionDirection("forward");
    } else if (index < currentTab) {
      setTransitionDirection("backward");
    }
    setCurrentTab(index);
  };

  const [currentImage, setCurrentImage] = useState(1); // State to track which image is active
  const [isAutoSwitching, setIsAutoSwitching] = useState(true); // State to track if auto-switching is active

  const handleImageChange = (imageNumber) => {
    setCurrentImage(imageNumber); // Update the current image state
    setIsAutoSwitching(false); // Stop auto-switching when a button is clicked
  };

  useEffect(() => {
    if (!isAutoSwitching) return; // Stop auto-switching if the state is false

    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage === 1 ? 2 : 1));
    }, 6000); // 6 seconds interval

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [isAutoSwitching]); // Add isAutoSwitching as a dependency

  const titleText =
    currentImage === 1
      ? "From simple and direct queries on organizational data."
      : "To complex industry specific and business function specific use cases.";

  const descriptionText =
    currentImage === 1
      ? "Retrieve critical information from your files, databases, and systems, streamlining daily operations with real-time responses through our data visualization tool powered by generative AI."
      : "We offer industry-specific use cases and demos to address your unique challenges, showing how data visualization tools can optimize data for your specific needs.";

  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  if (blogsLoading) {
    return (
      <div className="w-full flex justify-center items-center min-h-screen">
        <Lottie options={defaultOptions} height={400} width={400} />
      </div>
    );
  }
  if (blogsError) return <div>Error loading blogs!</div>;
  if (!blogs) return null;

  return (
    <section className="font-display pt-4">
      {/* <Helmet>
        <title>
          AI Fortune Cookie | Secure Knowledge Models for Enterprises | Random Walk
        </title>
        <meta
          property="og:title"
          content="AI Fortune Cookie | Secure Knowledge Models for Enterprises | Random Walk"
        />
        <meta
          name="description"
          content="Fortune Cookie uses generative AI for efficient data handling, retrieval, data analytics and visualization. It provides secure, flexible, and cost-effective insights while keeping your data in-house and secure."
        />
        <meta
          property="og:description"
          content="Fortune Cookie uses generative AI for efficient data handling, retrieval, data analytics and visualization. It provides secure, flexible, and cost-effective insights while keeping your data in-house and secure."
        />
        <link rel="canonical" href="https://randomwalk.ai/fortunecookie/" />
      </Helmet> */}
      <section
        id="t1"
        className="max-md:hidden bg-custom-gradient10 relative min-h-fit h-fit flex items-center justify-center text-white max-md:flex-col snap-center snap-always pb-32 pt-12 md:pt-0"
      >
        <article className="z-10 flex flex-grow flex-col items-start mt-10 pl-5 md:pl-5 lg:ml-5 2xl:ml-36 lg:-mt-12 2xl:-mt-16">
          <h2 className=" text-left text-md md:text-lg lg:text-2xl font-semibold 2xl:text-5xl py-2 font-display lg:mb-3 max-w-lg">
            {titleText}
          </h2>
          <p className="text-sm font-bold text-xs lg:text-sm md:text-xs font-medium 2xl:text-lg py-1 pb-5 font-display max-w-lg 2xl:max-w-2xl ">
            {descriptionText}
          </p>
          <a
            className="text-[#666666] text-xs md:text-xs font-display lg:text-base 2xl:text-xl font-bold bg-white hover:bg-blue-800 hover:text-white hover:border-white border border-transparent rounded-md py-3 px-10"
            onClick={handleButtonClick}
          >
            Schedule a Call
          </a>
        </article>

        {/* Image Display */}
        <img
          src={currentImage === 1 ? home1 : home2}
          alt="AI Fortune Cookie"
          className="hidden md:block p-0 md:pt-10 md:p-0 lg:mr-10 md:mr-5 mt-8 px-5 object-contain w-[500px] h-[300px] md:w-[550px] md:h-[300px] lg:w-[750px] lg:h-[450px] 2xl:w-[1200px] 2xl:h-[700px] lg:mb-10 fade-in max-md:order-1 transition-opacity duration-500 ease-in-out"
        />

        {/* Image Switch Buttons */}
        <article className="absolute md:bottom-24 lg:bottom-32 flex justify-center space-x-4">
          <a
            className={`w-2 h-2 rounded-full ${
              currentImage === 1 ? "bg-blue-600" : "bg-gray-400"
            }`}
            onClick={() => handleImageChange(1)}
            aria-label="Show Image 1"
          />
          <a
            className={`w-2 h-2 rounded-full ${
              currentImage === 2 ? "bg-blue-600" : "bg-gray-400"
            }`}
            onClick={() => handleImageChange(2)}
            aria-label="Show Image 2"
          />
        </article>
      </section>
      {/* Mobile Version */}
      <section
        id="t1"
        className="md:hidden bg-custom-gradient10 min-h-fit h-fit flex flex-col items-center justify-center text-white pb-32 pt-12 md:pt-0"
      >
        {/* Image */}
        <img
          src={currentImage === 1 ? home1 : home2}
          alt="AI Fortune Cookie"
          className="p-0 md:pt-10 md:p-0 mt-8 px-5 object-contain w-[430px] h-[290px] max-w-lg fade-in transition-opacity duration-500 ease-in-out"
        />

        {/* Image Switch Buttons (Centered) */}
        <article className="flex justify-center items-center space-x-4 mt-4">
          <a
            className={`w-2 h-2 rounded-full ${
              currentImage === 1 ? "bg-blue-600" : "bg-gray-400"
            }`}
            onClick={() => handleImageChange(1)}
            aria-label="Show Image 1"
          />
          <a
            className={`w-2 h-2 rounded-full ${
              currentImage === 2 ? "bg-blue-600" : "bg-gray-400"
            }`}
            onClick={() => handleImageChange(2)}
            aria-label="Show Image 2"
          />
        </article>

        {/* Content Below */}
        <h2 className="text-md mt-6 md:text-xl px-5 lg:text-2xl font-semibold 2xl:text-5xl py-2 font-display mb-3 max-w-lg text-left">
          {titleText}
        </h2>
        <p className="text-sm font-bold text-xs px-5 lg:text-sm font-medium 2xl:text-lg py-1 pb-5 font-display max-w-lg text-left">
          {descriptionText}
        </p>
        <div className="w-full flex justify-start">
          <a
            className="text-[#666666] text-xs md:text-xs ml-5 font-display lg:text-base 2xl:text-xl font-bold bg-white hover:bg-blue-800 hover:text-white hover:border-white border border-transparent rounded-md py-3 px-10"
            onClick={handleButtonClick}
          >
            Schedule a Call
          </a>
        </div>
      </section>
      <section class="min-h-full  flex flex-col items-center justify-center snap-center snap-always relative">
        <article class="relative w-full h-0 -translate-y-full z-30 ">
          <div class="triangle"></div>
        </article>
        <article class="relative w-full h-0 -translate-y-full">
          <div class="triangle !border-b-[100px] !border-b-primary opacity-50"></div>
        </article>
        <article class="relative w-full h-0 -translate-y-full">
          <div class="triangle !border-b-[120px] !border-b-blue opacity-20"></div>
        </article>

        <article class="container md:-mt-20 h-full flex items-center justify-center z-10 hidden md:block">
          <iframe
            src="https://www.youtube.com/embed/r95b9heYgMg?autoplay=1&mute=1&loop=1&playlist=r95b9heYgMg"
            frameborder="0"
            allowfullscreen
            class="md:w-10/12 md:h-auto w-11/12 h-auto aspect-video rounded-2xl object-contain md:m-32 "
            title="Fortune Cookies Video"
          ></iframe>
        </article>

        <img
          src={spiral}
          loading="lazy"
          alt="Fortune Cookie Youtube Video"
          class="absolute left-0 -bottom-56 w-9/12 h-auto md:w-[390px] lg:w-[600px] md:h-auto 2xl:w-[750px] 2xl:h-auto 2xl:-mt-40 md:-ml-5 object-fill opacity-32 pointer-events-none hidden md:block"
        />
      </section>
      <section class=" relative bg-[#F7FDFE] flex items-center justify-center  bg-rev snap-center snap-always z-0">
        <section class="flex flex-col gap-4 container items-center justify-center h-fit z-50">
          <header class=" top text-[#353535]">
            <p class="md:text-lg px-8 pt-8 text-base md:px-32 md:mt-0 text-[#666666]">
              A secure chat-based platform allows employees to perform tasks,
              search for data, run queries, get alerts, and generate content
              across numerous enterprise applications. It integrates data
              visualization tools using generative AI, enabling users to gain
              deeper insights and leverage AI-driven analytics for performance
              evaluation.
            </p>
          </header>

          <section class=" p-8 md:px-24 md:mt-0 grid grid-cols-1 md:grid-cols-3 gap-4 sm:gap-12 text-[#303030]">
            <article class="p-1 md:p-4 rounded-lg flex gap-[1px] flex-col">
              <div class="bg-white  flex flex-row items-center border glass">
                <img
                  src={llmc}
                  alt=" Customized LLMs"
                  class="w-[40px] h-[40px] md:w-[40px] md:h-[40px] object-contain md:scale-75 m-1"
                />
                {/* <video
                  src={llmc}
                  alt="Customized LLMs"
                  className="w-[40px] h-[40px] md:w-[40px] md:h-[40px] object-contain md:scale-75 m-1"
                  autoPlay
                  loop
                  muted
                  playsInline
                /> */}
                <div class="w-1 block border-x h-full mr-2 -skew-x-12"></div>
                <h4 class="max-sm:text-lg text-[#666666]  2xl:text-xl font-medium">
                  Customized LLMs
                </h4>
              </div>
              <p class="text-xs  2xl:text-base flex flex-row items-center mt-1 pt-1 border-t border-l border-white/70 p-2 h-ful text-[#666666]">
                Implement customized LLMs and select models for an efficient,
                cost-effective system.
              </p>
            </article>

            <article class="p-1 md:p-4 rounded-lg flex gap-[1px] flex-col">
              <div class="bg-white  flex flex-row items-center border glass">
                <img
                  src={aug}
                  alt=" Augmented Analytics"
                  class="w-[40px] h-[40px] md:w-[40px] md:h-[40px]  object-contain md:scale-75 m-1"
                />
                {/* <video
                  src={aug}
                  autoPlay
                  loop
                  muted
                  playsInline
                  className="w-[40px] h-[40px] md:w-[40px] md:h-[40px] object-contain md:scale-75 m-1"
                /> */}
                <div class="w-1 block border-x h-full mr-2 -skew-x-12"></div>
                <h4 class="max-sm:text-lg text-[#666666] 2xl:text-xl font-medium">
                  Augmented Analytics
                </h4>
              </div>
              <p class="text-xs  2xl:text-base  flex flex-row items-center mt-1 pt-1 border-t border-l border-white/70 p-2 h-full text-[#666666]">
                Efficiently analyze vast data sets to uncover hidden insights
                for smarter decision-making.
              </p>
            </article>

            <article class="p-1 md:p-4 rounded-lg flex gap-[1px] flex-col">
              <div class="bg-white  flex flex-row items-center border glass">
                <img
                  src={lock}
                  alt=" Data Security"
                  class="w-[40px] h-[40px] md:w-[40px] md:h-[40px] object-contain md:scale-75 m-1"
                />
                {/* <video
                  src={lock}
                  alt="Data Security"
                  className="w-[40px] h-[40px] md:w-[40px] md:h-[40px] object-contain md:scale-75 m-1"
                  autoPlay
                  loop
                  muted
                  playsInline
                /> */}
                <div class="w-1 block border-x h-full mr-2 -skew-x-12"></div>
                <h4 class="max-sm:text-lg text-[#666666] 2xl:text-xl font-medium">
                  Data Security
                </h4>
              </div>
              <p class="text-xs 2xl:text-base flex flex-row items-center mt-1 pt-1 border-t border-l border-white/70 p-2 h-full text-[#666666]">
                Implement data security to safeguard sensitive information and
                prevent breaches.
              </p>
            </article>

            <article class="p-1 md:p-4 rounded-lg flex gap-[1px] flex-col">
              <div class=" bg-white  flex flex-row items-center border glass">
                <img
                  src={chain}
                  alt="    Link All Data Sources"
                  class="w-[40px] h-[40px] md:w-[40px] md:h-[40px]  object-contain md:scale-75 m-1"
                />
                {/* <video
                  src={chain}
                  alt="Link All Data Sources"
                  className="w-[40px] h-[40px] md:w-[40px] md:h-[40px] object-contain md:scale-75 m-1"
                  autoPlay
                  loop
                  muted
                  playsInline
                /> */}
                <div class="w-1 block border-x h-full mr-2 -skew-x-12"></div>
                <h4 class="max-sm:text-lg 2xl:text-xl text-[#666666] font-medium">
                  Link All Data Sources
                </h4>
              </div>
              <p class="text-xs  2xl:text-base flex flex-row items-center mt-1 pt-1 border-t border-l border-white/70 p-2 h-full text-[#666666]">
                Transform isolated data into semantic knowledge graphs and
                vector databases.
              </p>
            </article>

            <article class="p-1 md:p-4 rounded-lg flex gap-[1px] flex-col">
              <div class="bg-white  flex flex-row items-center border glass">
                <img
                  src={search}
                  alt="Enterprise Search"
                  class="w-[40px] h-[40px] md:w-[40px] md:h-[40px] object-contain md:scale-75 m-1"
                />
                {/* <video
                  src={search}
                  alt="Enterprise Search"
                  className="w-[40px] h-[40px] md:w-[40px] md:h-[40px] object-contain md:scale-75 m-1"
                  autoPlay
                  loop
                  muted
                  playsInline
                /> */}
                <div class="w-1 block border-x h-full mr-2 -skew-x-12"></div>
                <h4 class="max-sm:text-lg 2xl:text-xl text-[#666666] font-medium">
                  Enterprise Search
                </h4>
              </div>
              <p class="text-xs  2xl:text-base flex flex-row items-center mt-1 pt-1 border-t border-l border-white/70 p-2 h-full text-[#666666]">
                Improve organization-wide search functionality to access
                relevant information.
              </p>
            </article>

            <article class="p-1 md:p-4 rounded-lg flex gap-[1px] flex-col">
              <div class="bg-white  flex flex-row items-center border glass">
                <img
                  src={multi}
                  alt="Tailored UX/UI"
                  className="w-[40px] h-[40px] md:w-[40px] md:h-[40px] object-contain m-1"
                />
                {/* <video
                  src={multi}
                  alt="Tailored UX/UI"
                  className="w-[40px] h-[40px] md:w-[40px] md:h-[40px] object-contain m-1"
                  autoPlay
                  loop
                  muted
                  playsInline
                /> */}

                <div class="w-1 block border-x h-full mr-2 -skew-x-12"></div>
                <h4 class="max-sm:text-lg 2xl:text-xl text-[#666666] font-medium">
                  Tailored UX/UI
                </h4>
              </div>
              <p class="text-xs  2xl:text-base flex flex-row items-center mt-1 pt-1 border-t border-l border-white/70 p-2 h-full text-[#666666]">
                Enhance employee experience with a UX for follow-ups, summaries,
                and data.
              </p>
            </article>
          </section>
        </section>
      </section>{" "}
      <section id="t3">
        <article className="relative bg-custom-gradient11 flex py-10 md:py-0 md:p-0 md:px-10 md:pb-0 lg:px-32 2xl:px-96 items-center min-h-0 max-sm:min-h-[10rem] h-full justify-center text-white overflow-clip max-md:pr-1 2xl:pt-20 pt-5 md:pt-0 lg:pt-16">
          <figure className="absolute md:top-0 top-[70px] right-3 z-50 arrow-button-wrapper">
            <img
              src={pinkarrow}
              alt="Organizational Use Cases"
              className="md:hidden w-5 h-5 cursor-pointer"
              onClick={handleNextTab}
            />
          </figure>
          <header className="absolute max-md:hidden  top-0 2xl:top-3 right-3  p-4">
            <p className="text-sm font-bold text-pink-500 lg:text-base 2xl:text-xl ">
              Organizational Use Cases
            </p>
          </header>

          <section className="relative flex flex-col items-center p-3 md:p-0 justify-center w-full">
            <p className="-mt-3 md:hidden pb-6  lg:-mt-10 lg:ml-[900px] ml-52 lg:text-xl text-pink-600  text-sm   font-bold">
              Organizational Use Cases
            </p>

            <article className="relative w-full ">
              <article className=" max-md:hidden md:pb-0 md:mt-12 lg:mt-0 mt-0 2xl:-mt-3 relative flex items-center justify-center gap-8  w-full">
                <div
                  className="absolute bottom-0 left-0 h-1 bg-pink-500 transition-transform duration-300 ease-in-out"
                  style={{
                    transform: `translateX(${
                      currentTab * (100 / tabs?.length)
                    }%)`,
                  }}
                ></div>
                {tabs.map((tab, i) => (
                  <a
                    key={i}
                    className={`tab lg:min-w-40 min-w-40 md:min-w-20 text-center  rounded-md p-2 text-sm md:text-md  font-semibold transition-transform duration-300 ease-in-out ${
                      currentTab === i
                        ? "scale-105 bg-[#C62B6D] text-center text-white"
                        : "bg-transparent"
                    }`}
                    onClick={() => handleTabClick(i)}
                  >
                    {tab}
                  </a>
                ))}
              </article>
            </article>

            <article className="   md:mt-28 lg:mt-24 md:pb-10  grid flex-grow grid-cols-1 grid-rows-1 w-full">
              {tabs.map(
                (tab, idx) =>
                  currentTab === idx && (
                    <div
                      key={idx}
                      className="flex items-start justify-center tab-content flex-col col-span-full row-span-full relative w-full h-full max-md:pb-4 snap-center snap-always"
                    >
                      <p className="text-sm font-bold py-2 ml-4">{tabs[idx]}</p>
                      <div className="flex flex-col md:flex-row">
                        <div className="flex flex-row md:flex-col">
                          <img
                            src={tabGifs[idx]}
                            alt={tabs[idx]}
                            className="w-32 h-32 object-contain text-ellipsis"
                          />
                          <div
                            className="w-11/12 ml-3 md:w-2/6 md:mr-20 text-sm z-40 flex min-h-36 mb-2 mt-3"
                            onClick={handleButtonClick}
                          >
                            {tabText[idx]}
                          </div>
                          <div className="md:-mt-10 lg:-mt-10   max-md:hidden ">
                            <a
                              onMouseEnter={() => setIsHovered(true)}
                              onMouseLeave={() => setIsHovered(false)}
                              className={`py-3 md:px-8  lg:px-10 border-t  border-b border-r border-l border-white ${
                                isHovered
                                  ? "bg-white text-black"
                                  : "bg-custom-gradient11 text-white"
                              } md:text-sm rounded-md transition-colors duration-300`}
                              onClick={handleButtonClick}
                            >
                              Talk to our AI Experts
                            </a>
                          </div>
                        </div>
                        <div className="lg:-mt-10  block md:hidden ">
                          <a
                            onMouseEnter={() => setIsHovered(true)}
                            onMouseLeave={() => setIsHovered(false)}
                            className={`py-3 px-10 border-t  border-b border-r border-l border-white ${
                              isHovered
                                ? "bg-white text-black"
                                : "bg-custom-gradient11 text-white"
                            } md:text-sm rounded-md transition-colors duration-300`}
                            onClick={handleButtonClick}
                          >
                            Talk to our AI Experts
                          </a>
                        </div>
                        <div className="flex py-1 mt-20 md:mt-0">
                          <img
                            src={tabModels[idx]}
                            loading="lazy"
                            alt="Organizatial Use Cases People"
                            className={`absolute max-md:hidden lg:w-4/12 2xl:w-4/12 pointer-events-none w-1/2 md:w-4/12 object-cover -bottom-10 md:left-1/2 right-0 translate-x-1/3 md:-translate-x-1/2 z-10 ${
                              idx === 2 || idx === 4
                                ? "scale-125 origin-bottom"
                                : ""
                            }`}
                          />
                          <img
                            src={tabPromptBg[idx]}
                            alt="Organizatial Use Cases Background_image"
                            className={`absolute pointer-events-none w-8/12 md:w-7/12 lg:w-5/12 object-cover max-md:scale-150 -bottom-10 right-0 ${
                              idx === 3 ? "translate-y-36" : ""
                            }`}
                          />
                          <img
                            src={tabPrompt[idx]}
                            alt="Organizatial Use Cases Description"
                            className="max-md:relative max-md:w-8/12 mr-10  md:mr-16 lg:mr-0   md:w-5/12  lg:w-4/12  2xl:w-4/12  lg:-translate-x-5 lg:-translate-y-1/4 2xl:-translate-x-6 2xl:-translate-y-28 md:absolute max-md:pb-5 max-md:translate-x-1/4 items-center max-md:scale-150 pointer-events-none  object-cover md:right-16 md:translate-x-14 md:-translate-y-1/4"
                          />
                        </div>
                      </div>
                    </div>
                  )
              )}
            </article>

            <article className="md:hidden ml-5 tabsContainer mt-10 md:mt-0 flex items-center justify-start gap-4 w-full pb-0">
              {tabs.map((tab, i) => (
                <a
                  key={i}
                  className={`tab min-w-1 z-10 rounded-full p-2 text-sm font-semibold hover:scale-110 transition-transform duration-300 ${
                    currentTab === i
                      ? "scale-105 text-primary p-[0.4rem] bg-pink-500"
                      : "p-[0.3rem] bg-[#dadada]"
                  }`}
                  onClick={() => handleTabClick(i)}
                />
              ))}
            </article>
          </section>
        </article>
      </section>
      <section class=" w-full p-6 lg:px-16 md:pb-10 md:pt-5 lg:pt-10 flex flex-col   snap-center snap-always z-20">
        <h3 class="md:text-2xl text-xl font-bold 2xl:text-2xl mt-2 text-[#666666] md:mt-0 ">
          The Art and Science of RAG Systems
        </h3>

        <article class="sec1  mt-8 bg-custom-gradient12 rounded-2xl flex max-md:flex-col overflow-hidden">
          <figure class="image  bg-[#BFDDE1] min-w-0 p-4 flex-grow justify-center items-center flex">
            <img
              src={VDandKG}
              alt="random"
              class="object-contain w-[2500px] h-[170px] md:w-[1050px] md:h-[320px] lg:w-[900px] lg:h-[350px] 2xl:w-[780px] 2xl:h-[400px]"
            />
          </figure>

          <article class="desc p-6 w-full md:w-1/2 min-w-0 flex flex-col justify-center min-h-full">
            <p class="py-2 font-bold md:text-xl text-sm text-[#666666] 2xl:text-2xl ">
              Combining Vector Database and Knowledge Graphs
            </p>
            <p class="py-1 text-sm font-light 2xl:text-base text-[#666666] font-normal">
              Vector databases allow for high-speed similarity searches across
              large datasets. They are particularly useful for tasks like
              semantic search, recommendation systems, and anomaly detection,
              enhancing business intelligence and reporting through data
              visualization using generative AI.
            </p>
            <p class="py-1 text-sm font-light 2xl:text-base text-[#666666] font-normal">
              Knowledge graphs excel at revealing relationships and
              dependencies, which can be crucial for understanding context or
              the relational dynamics in data, such as hierarchical structures
              or associative properties.
            </p>
          </article>
        </article>

        <article class="sec2  text-[#666666] bg-custom-gradient14 rounded-2xl flex max-md:flex-col overflow-hidden mt-4">
          <figure class="image  bg-[#F7E3EC] min-w-0 p-4 flex-grow justify-center items-center flex">
            <img
              src={enrichLLMs}
              alt="sec2img"
              class="object-contain w-[550px] h-[170px] md:w-[1050px] md:h-[320px] lg:w-[1050px] lg:h-[320px] 2xl:w-[950px] 2xl:h-[400px] "
            />
          </figure>

          <article class="desc p-6 w-full md:w-7/12 justify-center flex flex-col items-start">
            <p class="py-2 md:text-xl text-sm font-bold 2xl:text-2xl">
              Enrich LLMs Understanding with Semantics
            </p>
            <p class="py-1 text-sm font-light 2xl:text-base text-[#666666] font-normal">
            RAGs enhance the understanding of LLMs by imbuing them with semantic depth. As LLMs engage with the semantic layer facilitated by RAGs, the querying process becomes more streamlined, ensuring that context and queries are aligned for accuracy. 
            </p>
            <p class="py-1 text-sm font-light 2xl:text-base text-[#666666] font-normal">
            This approach helps LLMs to access information from databases seamlessly, enhancing their ability to comprehend the intricacies of language. By integrating semantics, RAGs ensure that queries and context are perfectly aligned, improving the accuracy of LLM-generated responses. Our data visualization tool using Gen AI ensures that your enterprise data becomes actionable and insightful. 
            </p>
          </article>
        </article>

        <article class="sec1  bg-[#F3F1D8] rounded-2xl flex max-md:flex-col overflow-hidden mt-10">
          <figure class="image bg-[#CECAA1] min-w-0 p-4 flex-grow justify-center items-center flex">
            <img
              src={trainLLM}
              alt="random"
              class="object-contain w-[2500px] h-[230px] md:w-[1050px] md:h-[320px] lg:w-[900px] lg:h-[450px] 2xl:w-[800px] 2xl:h-[550px] "
            />
          </figure>
          <article class="desc p-6 w-full md:w-1/2 min-w-0 flex flex-col justify-center min-h-full">
            <p class="py-2 md:text-xl font-bold text-sm 2xl:text-2xl">
              Train LLM with Enterprise Data
            </p>
            <p class="py-1 text-sm font-light 2xl:text-base text-[#666666] font-normal">
            RAG complements the training of LLMs with enterprise data by providing structured frameworks, leveraging data visualization tools using generative AI to enable smarter decisions. RAG uses knowledge graphs and semantic retrieval to improve LLMs' understanding of enterprise-specific context, enabling them to generate more accurate and relevant responses based on the specific nuances of the enterprise domain. 
            </p>
            <p class="py-1 text-sm font-light 2xl:text-base text-[#666666] font-normal">
            This integration between RAG and enterprise data training ensures that LLMs know what's important to the organization and can provide helpful insights accordingly. 
            </p>
          </article>
        </article>
      </section>
      <section
        id="t4"
        className="flex md:pb-10 pb-5 flex-col overflow-hidden items-center justify-center bg-white text-[#666666] lg:pb-10 snap-center snap-always pt-5 lg:pt-8 md:pt-0"
      >
        <article className="lg:px-7 px-4 container relative flex items-center justify-center flex-col gap-8 min-h-fit">
          <h2 className="text-[#666666] text-base md:text-2xl lg:text-3xl lg:max-w-2xl text-center font-bold mt-0 md:mt-20 lg:mt-10 md:pb-5 ">
            From Idea To Production in just a few weeks
          </h2>

          <article className="dots progress max-md:hidden mt-8 md:mt-19 lg:mt-10 pb-20 bar flex items-center justify-between max-w-[65rem] gap-2 w-full relative">
            {tabs2.map((tab, i) => (
              <div
                className="grid h-6 w-6 p-0 grid-cols-1 grid-rows-1 relative"
                key={i}
              >
                {i === 0 && (
                  <div className="text-xs md:text-sm absolute text-nowrap -translate-y-6 font-bold">
                    Now
                  </div>
                )}
                {i === 1 && (
                  <div className="text-xs md:text-sm absolute text-nowrap translate-y-7 -translate-x-3 font-bold">
                    Week 1
                  </div>
                )}
                {i === 2 && (
                  <div className="text-xs md:text-sm absolute text-nowrap -translate-y-6 -translate-x-3 font-bold">
                    Week 2
                  </div>
                )}
                {i === 3 && (
                  <div className="text-xs md:text-sm absolute text-nowrap translate-y-7 -translate-x-3 font-bold">
                    Week 3
                  </div>
                )}
                {i === 4 && (
                  <div className="text-xs md:text-sm absolute text-nowrap -translate-y-6 -translate-x-6 font-bold">
                    4-6 Weeks
                  </div>
                )}

                <a
                  onClick={() => handleTabClick(i)}
                  className={` relative h-6 w-6 rounded-full max-md:hidden z-20  transition-all flex items-center justify-center col-span-full row-span-full ${
                    i <= currentTab
                      ? "bg-[#3E59B7]  text-white"
                      : "bg-[#E0E0E7]  text-[#707070]"
                  }`}
                >
                  <div
                    className={`h-3 w-3 rounded-full ${
                      i === currentTab ? "bg-white" : "bg-white"
                    }`}
                  ></div>
                </a>
              </div>
            ))}

            {currentTab === 0 && (
              <figure className="absolute left-0 transform top-[-23px] md:top-[-40px] translate-x-4 md:translate-x-6 lg:translate-x-8  md:scale-115">
                <img
                  src={arrowup}
                  alt="Arrow Up"
                  className=" lg:w-[220px] h-auto md:w-40 md:h-10 text-[#3E59B7]"
                />
              </figure>
            )}
            {currentTab === 1 && (
              <figure className="absolute md:bottom-0 bottom-0 md:left-0 md:scale-125 transform  md:translate-x-[200px] lg:translate-x-[300px] translate-x-[115px] -translate-y-[60px] lg:-translate-y-[30px] md:-translate-y-10">
                <img
                  src={arrowdown}
                  alt="Arrow Down"
                  className=" lg:w-[185px] h-auto md:w-36 md:h-auto text-[#3E59B7]"
                />
              </figure>
            )}
            {currentTab === 2 && (
              <figure
                className={`absolute ${
                  currentTab % 2 === 0 ? "" : "top-6"
                } md:-top-11 top-[-23px] left-0 md:left-1 md:scale-125 transform -translate-x-24 md:-translate-x-40  lg:-translate-x-56`}
                style={{
                  width: "calc(100% / 4)",
                  marginLeft: `${(currentTab + 1) * (100 / 4)}%`,
                }}
              >
                <img
                  src={arrowup}
                  alt="Arrow Up"
                  className=" lg:w-[180px] h-auto md:w-32 md:h-10 text-[#3E59B7]"
                />
              </figure>
            )}
            {currentTab === 3 && (
              <figure className="absolute bottom-20 md:bottom-[0px] left-0 md:left-0 transform md:-translate-y-10 md:translate-x-[550px]  lg:translate-x-[810px] scale-125 lg:-translate-y-8 translate-x-[320px]">
                <img
                  src={arrowdown}
                  alt="Arrow Down"
                  className=" lg:w-[180px] h-7 md:w-32  lg:h-auto md:h-auto text-[#3E59B7]"
                />
              </figure>
            )}

            <div className="line progress w-full h-1 bg-[#E0E0E7] absolute"></div>
            <div
              className="line progress h-1 absolute left-0 transition-all"
              style={{
                width: `${currentTab * 25}%`,
                backgroundColor:
                  currentTab === tabs2?.length - 1 ? "#3E59B7" : "#3E59B7",
              }}
            ></div>
          </article>

          <article className="relative max-lg:hidden w-full flex md:-mt-8 lg:mt-0 2xl:mt-0 -mt-10 md:justify-start flex-col md:flex-row lg:px-24 2xl:px-52  lg:-translate-y-8 ">
            {tabs2.map((tab, index) => {
              const isEntering = currentTab === index;
              return (
                <CSSTransition
                  key={index}
                  in={isEntering}
                  timeout={500}
                  classNames={
                    transitionDirection === "forward"
                      ? "slide"
                      : "slide-backward"
                  }
                  unmountOnExit
                >
                  <div
                    className={`relative  w-full rounded-xl transition-transform md:justify-start duration-500 ${
                      isEntering
                        ? "bg-[#3E59B7] text-white  lg:w-5/12"
                        : "hidden"
                    }`}
                    style={{
                      height: "auto",
                      transform: `translateX(${index * 155}px)`,
                    }}
                  >
                    <div className="p-5">
                      <div
                        className={`text-xs -ml-1 md:text-sm lg:text-base font-bold ${
                          isEntering ? "text-left" : "text-center"
                        }`}
                      >
                        {tab}
                      </div>
                      {isEntering && (
                        <ul className="text-xs md:text-sm lg:text-sm font-light mt-1 list-disc ml-3">
                          {tabContent[tab].map((content, idx) => (
                            <li key={idx}>{content}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </CSSTransition>
              );
            })}
          </article>

          <article className="relative max-md:hidden lg:hidden  w-full flex md:-mt-8 lg:mt-5 2xl:mt-0 -mt-10 md:justify-start flex-col md:flex-row lg:pr-0 md:pr-5 lg:-translate-y-8 ">
            {tabs2.map((tab, index) => {
              const isEntering = currentTab === index;
              return (
                <CSSTransition
                  key={index}
                  in={isEntering}
                  timeout={500}
                  classNames={
                    transitionDirection === "forward"
                      ? "slide"
                      : "slide-backward"
                  }
                  unmountOnExit
                >
                  <div
                    className={`relative  w-full rounded-xl transition-transform md:justify-start duration-500 ${
                      isEntering
                        ? "bg-[#3E59B7] text-white w-auto  md:w-7/12"
                        : "hidden"
                    }`}
                    style={{
                      height: "auto",
                      transform: `translateX(${index * 80}px)`,
                    }}
                  >
                    <div className="p-5">
                      <div
                        className={`text-xs md:text-md lg:text-base font-bold ${
                          isEntering ? "text-left" : "text-center"
                        }`}
                      >
                        {tab}
                      </div>
                      {isEntering && (
                        <ul className="text-xs md:text-xs lg:text-sm font-light mt-2 list-disc pl-2">
                          {tabContent[tab].map((content, idx) => (
                            <li key={idx}>{content}</li>
                          ))}
                        </ul>
                      )}
                    </div>
                  </div>
                </CSSTransition>
              );
            })}
          </article>

          <article className="flex flex-row md:hidden gap-3 overflow-x-auto w-full max-md:hidden">
            {tabs2.map((tab, index) => (
              <a
                key={index}
                onClick={() => handleTabClick(index)}
                className={`px-3 py-1 whitespace-nowrap rounded-lg ${
                  index === currentTab
                    ? "bg-[#3E59B7] text-white"
                    : "bg-[#E0E0E7] text-[#707070]"
                }`}
              >
                {tab}
              </a>
            ))}
            from idea to
          </article>

          <article className="flex flex-row md:hidden gap-3 overflow-x-auto w-full hide-scrollbar">
            {tabs2.map((tab, index) => (
              <div key={index} className="flex flex-col items-center">
                <p className="mb-2 text-center text-xs translate-y-11 mr-3">
                  {tabTexts[index]}
                </p>
                <div
                  className={`relative z-0 flex-grow w-0 min-w-32 mt-10 mb-12 rounded-xl bg-[#3E59B7] text-white`}
                >
                  {index === tabs2?.length - 1 ? null : index % 2 ? (
                    <img
                      src={arrowup}
                      alt=" From Idea To Production in just a few weeks img1"
                      className="w-32 absolute -top-[55px] z-20 -left-20"
                    />
                  ) : (
                    <img
                      src={arrowdown}
                      alt=" From Idea To Production in just a few weeks img2"
                      className="w-32 absolute -bottom-8 z-20 left-52"
                    />
                  )}
                  <div className={`p-5 text-left`}>
                    <p className="text-xs lg:text-md font-semibold">{tab}</p>
                  </div>
                </div>
              </div>
            ))}
          </article>
        </article>
      </section>
      <section className="min-h-fit px-20 py-10 text-[#666666] bg-[#EBF7FF] flex items-center justify-center snap-center snap-always">
        <section className="h-full container py-12 flex flex-col gap-6 text-[#666666]">
          <h4 className="capitalize font-bold max-md:text-lg text-base">
            RECENTLY POSTED RESOURCES
          </h4>

          <article className="flex gap-2 items-center justify-center md:flex-row flex-col-reverse">
            <div className="left h-full flex flex-col items-left justify-center gap-4 text-left flex-grow w-full">
              <div className="leftContainer min-h-96 md:min-h-80 flex flex-col items-center md:items-start justify-between flex-grow">
                <div className="w-4/5 md:w-3/5  pt-8 md:pt-0 pb-2 flex-grow grid grid-cols-1 grid-rows-1  relative">
                  {filteredBlogs.map((blog, idx) => (
                    <a
                      key={idx}
                      className={`flex w-full flex-col col-span-full row-span-full transition-opacity duration-300 absolute inset-0 ${getTransitionClass(
                        idx
                      )}`}
                    >
                      <h2 className=" text-left text-sm md:text-lg lg:text-2xl font-bold text-xl">
                        {blog.blogTitle || "No blog title"}
                      </h2>
                      <p className=" text-xs line-clamp-5 pt-3 md:text-xs font-semibold lg:text-sm">
                        {blog.blogDesc || "No description available"}
                      </p>
                    </a>
                  ))}
                </div>

                <div className="pt-8">
                  <a
                    className="p-2 px-2 text-sm border border-[#a1a1a1] text-[#3b3b3b] rounded-lg"
                    href={`/blog/${formatUrlFriendlyTitle(
                      filteredBlogs[activeIdx]?.blogTitle || ""
                    )}/`}
                  >
                    Learn More
                  </a>
                </div>

                <div className="flex gap-2 pt-10">
                  <button
                    className="aspect-square w-8 bg-gray-400 border border-none rounded-xl flex items-center justify-center hover:scale-105"
                    onClick={handlePrev}
                  >
                    <BiChevronLeft />
                  </button>
                  <button
                    className="aspect-square w-8 bg-gray-400 border border-none rounded-xl flex items-center justify-center hover:scale-105"
                    onClick={handleNext}
                  >
                    <BiChevronRight />
                  </button>
                </div>
              </div>
            </div>
            <div className="right flex flex-col aspect-square grid grid-cols-1 grid-rows-1 overflow-hidden rounded-3xl md:-mt-24 -mt-0 relative w-96">
              {filteredBlogs.map((blog, idx) => (
                <div
                  key={idx}
                  className={`col-span-full row-span-full transition-opacity duration-300 absolute inset-0 ${getTransitionClass(
                    idx
                  )}`}
                  href={blog.link || "#"}
                >
                  <img
                    src={blog.coverImg?.url || ""}
                    loading="lazy"
                    alt={blog.blogTitle || "Blog Cover Image"}
                    className="object-cover w-[400px] h-[400px]  md:w-[500px] md:h-[250px] lg:w-[600px] lg:h-[280px] 2xl:w-[700px] 2xl:h-[300px]"
                  />
                </div>
              ))}
            </div>
          </article>

          <article className=" max-md:hidden cardHolder font-medium w-full flex overflow-x-auto gap-4 pt-6">
            {filteredBlogs.map((blog, idx) => (
              <a
                key={idx}
                className="border-t-[1px] border-black pt-6 text-sm"
                href={`/blog/${formatUrlFriendlyTitle(blog.blogTitle)}/`}
              >
                <h2 className=" text-left pb-2 md:text-xs lg:text-sm">
                  {blog.blogTitle}
                </h2>
                <p className="md:text-xs lg:text-sm">{blog.blogDate}</p>
              </a>
            ))}
          </article>

          <article className="md:hidden cardHolder font-medium w-full grid grid-cols-2 gap-4 pt-6 relative">
            {filteredBlogs.map((blog, idx) => (
              <a
                key={idx}
                className={`border-t-[1px] border-black pt-6 text-sm ${
                  idx === 4 ? "col-span-full flex flex-col items-left" : ""
                }`}
                href={`/blog/${formatUrlFriendlyTitle(blog.blogTitle)}/`}
              >
                <h2 className=" text-left pb-2 md:text-xs lg:text-sm">
                  {blog.blogTitle}
                </h2>

                {idx === 4 ? (
                  <p className="block pt-2 md:text-xs lg:text-sm">
                    {blog.blogDate}
                  </p>
                ) : (
                  <p className="md:text-xs lg:text-sm">{blog.blogDate}</p>
                )}
              </a>
            ))}
          </article>
        </section>
      </section>
      <div>
        {isModalOpen && (
          <div className="fixed z-50 inset-0 md:p-0 p-10  bg-black bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-[#F1F6FF]   p-5 rounded-lg max-h-screen   max-w-md w-full">
              <button
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={closeModal}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <div className="text-lg md:text-xl text-center font-bold mb-4">
                Experience the Power of Data <br />
                With
                <span className="text-[#C62B6D] font-bold"> AI Fortune </span>
                Cookie
              </div>
              <form onSubmit={handleSubmit}>
                <div className="max-h-[400px] overflow-y-auto custom-scrollbar pr-4 ">
                  <div className="mb-4">
                    <label className="text-gray-700">Name</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="w-full px-3 py-2 border rounded"
                      required
                    />
                    {errors.name && (
                      <p className="text-red-500 text-sm">{errors.name}</p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className=" text-gray-700">Email Address</label>
                    <input
                      type="email"
                      id="emailaddress"
                      name="email"
                      value={email}
                      onChange={(e) => setEmailId(e.target.value)}
                      className="w-full px-3 py-2 border rounded"
                      required
                    />
                    {errors.email && (
                      <p className="text-red-500 text-sm">{errors.email}</p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label id="phonenumber" className=" text-gray-700">
                      Phone Number
                    </label>
                    <PhoneInput
                      country={"in"}
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      inputClass="custom-phone-input2"
                      buttonClass="border rounded"
                      dropdownClass="border rounded"
                    />
                    {errors.phoneNumber && (
                      <p className="text-red-500 text-sm">
                        {errors.phoneNumber}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="text-gray-700">Designation</label>
                    <input
                      type="text"
                      id="designation"
                      required
                      name="designation"
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                      className="w-full px-3 py-2 border rounded"
                    />
                    {errors.designation && (
                      <p className="text-red-500 text-sm">
                        {errors.designation}
                      </p>
                    )}
                  </div>

                  <div className="mb-4">
                    <label className=" text-gray-700">Company Name</label>
                    <input
                      type="text"
                      id="Companyname"
                      name="companyName"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className="w-full px-3 py-2 border rounded"
                      required
                    />
                    {errors.companyName && (
                      <p className="text-red-500 text-sm">
                        {errors.companyName}
                      </p>
                    )}
                  </div>

                  <div className="disclaimerContainers">
                    <label className="checkboxLabel">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        className="checkbox"
                      />
                      <span className="checkboxText">
                        I agree and authorize team to contact me and/or send
                        relevant information over Email, SMS & WhatsApp. This
                        will override the registry with DNC/NDNC.
                      </span>
                    </label>
                  </div>
                  {errors.terms && (
                    <p className="text-red-500 text-sm">{errors.terms}</p>
                  )}
                  <div className="linksContainer">
                    <a href="/terms-of-service/" className="link">
                      Terms of Service
                    </a>
                    <a href="/privacy-policy/" className="link">
                      Privacy Policy
                    </a>
                  </div>
                </div>
                <div className="mb-4 mt-6">
                  <button
                    id="submit"
                    type="submit"
                    className={`w-full px-32 py-2 bg-[#C62B6D] text-white rounded transition-opacity ${
                      !isChecked || isLoading
                        ? "opacity-50 cursor-not-allowed"
                        : "hover:opacity-90"
                    }`}
                    disabled={!isChecked || isLoading}
                  >
                    {isLoading ? (
                      <div className="flex items-center justify-center space-x-2">
                        <svg
                          className="animate-spin h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          />
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          />
                        </svg>
                        <span>Submitting...</span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        )}

        {isSuccessOpen && (
          <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
            <div className="relative bg-white p-8 rounded-lg max-w-md w-full text-center">
              <button
                className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
                onClick={closeSuccessModal}
              >
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <h2 className="text-2xl font-bold mb-4">Success!</h2>
              <p className="mb-4">Your request has been sent successfully.</p>
              <button
                id="close"
                className="px-4 py-2 bg-[#C62B6D] text-white rounded"
                onClick={closeSuccessModal}
              >
                Close
              </button>
            </div>
          </div>
        )}
      </div>
      <section className="lg:px-16 md:pb-10 pt-10  md:py-10  px-5 md:p-0 pb-10 2xl:mx-auto 2xl:mr-36 2xl:container md:px-5  bg-white">
        <article className="relative w-full bg-custom-gradient50 rounded-xl">
          <figure className="absolute max-md:hidden lg:left-12 md:left-8 top-0 bottom-0 flex items-center">
            <img
              src={pattern}
              alt=" Experience the Power of 
                Data with AI Fortune Cookie "
              className="h-auto md:w-7/12 lg:w-8/12 object-cover opacity-50"
            />
          </figure>

          <article className="relative   md:px-0 md:py-0 lg:px-4 lg:py-3">
            <div className="absolute lg:top-72 2xl:top-72 top-5  left-7 lg:left-20 md:top-72 md:left-16 font-extrabold lg:text-3xl text-xl text-white">
              <p className="lg:text-3xl text-xl   md:text-2xl  2xl:text-4xl font-bold">
                Experience the Power of <br />
                Data with AI Fortune Cookie <br />
              </p>
              <p className="lg:text-base md:text-xs text-sm 2xl:text-lg font-medium">
                Access your AI Potential in just 15 mins!
              </p>
              <br />
              <br />
            </div>

            <article className="flex md:justify-end justify-center">
              <div className="flex-1 max-w-md lg:mr-10 flex justify-center">
                <form
                  ref={signupRef}
                  onSubmit={handleSubmit}
                  className="p-8 pt-32 md:pt-5 md:py-4 md:ml-32 lg:mx-5 lg:py-6 lg:p-0 relative rounded-lg w-full max-w-sm"
                >
                  <div className="mb-4">
                    <label className="block text-white">Name</label>
                    <input
                      id="name"
                      type="text"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="w-full bg-[#7187ED] px-3 py-2 border rounded"
                      required
                    />
                    {errors.name && (
                      <p className="text-red-500 text-sm">{errors.name}</p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="block text-white">Email Address</label>
                    <input
                      id="emailaddress"
                      type="email"
                      name="email"
                      value={email}
                      onChange={(e) => setEmailId(e.target.value)}
                      className="w-full px-3 bg-[#7187ED] py-2 border rounded"
                      required
                    />
                    {errors.email && (
                      <p className="text-red-500 text-sm">{errors.email}</p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label id="phonenumber" className="block text-white">
                      Phone Number
                    </label>
                    <PhoneInput
                      country={"in"}
                      value={phoneNumber}
                      onChange={handlePhoneNumberChange}
                      inputClass="custom-phone-input"
                      buttonClass="border rounded"
                      dropdownClass="border rounded"
                    />
                    {errors.phoneNumber && (
                      <p className="text-red-500 text-sm">
                        {errors.phoneNumber}
                      </p>
                    )}
                  </div>
                  <div className="mb-4">
                    <label className="block text-white">Designation</label>
                    <input
                      id="designation"
                      type="text"
                      name="designation"
                      required
                      value={designation}
                      onChange={(e) => setDesignation(e.target.value)}
                      className="w-full px-3 bg-[#7187ED] py-2 border rounded"
                    />
                    {errors.designation && (
                      <p className="text-red-500 text-sm">
                        {errors.designation}
                      </p>
                    )}
                  </div>

                  <div className="mb-4">
                    <label className="block text-white">Company Name</label>
                    <input
                      id="Companyname"
                      type="text"
                      name="companyName"
                      value={companyName}
                      onChange={(e) => setCompanyName(e.target.value)}
                      className="w-full px-3 bg-[#7187ED] py-2 border rounded"
                      required
                    />
                    {errors.companyName && (
                      <p className="text-red-500 text-sm">
                        {errors.companyName}
                      </p>
                    )}
                  </div>
                  <div className="disclaimerContainer">
                    <label className="checkboxLabel-fc">
                      <input
                        type="checkbox"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                        className="checkbox-fc"
                      />
                      <span className="checkboxText-fc">
                        I agree and authorize team to contact me and/or send
                        relevant information over Email, SMS & WhatsApp. This
                        will override the registry with DNC/NDNC.
                      </span>
                    </label>
                  </div>
                  {errors.terms && (
                    <p className="text-red-500 text-sm">{errors.terms}</p>
                  )}
                  <div className="linksContainer">
                    <a href="/terms-of-service/" className="link-fc">
                      Terms of Service
                    </a>
                    <a href="/privacy-policy/" className="link-fc">
                      Privacy Policy
                    </a>
                  </div>

                  <div className="mb-4 mt-6">
                    <button
                      id="submit"
                      type="submit"
                      className={`w-full px-32 py-2 bg-[#C62B6D] text-white rounded transition-opacity ${
                        !isChecked || isLoading
                          ? "opacity-50 cursor-not-allowed"
                          : "hover:opacity-90"
                      }`}
                      disabled={!isChecked || isLoading}
                    >
                      {isLoading ? (
                        <div className="flex items-center justify-center space-x-2">
                          <svg
                            className="animate-spin h-5 w-5 text-white"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            />
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            />
                          </svg>
                          <span>Submitting...</span>
                        </div>
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </article>
          </article>
        </article>
      </section>
    </section>
  );
};

export default Fortunecookie;
