
import { NavLink } from 'react-router-dom';
import React from 'react';
 import footer from "../assets/Footer/footer.webp";
 import linkedinn from '../assets/Footer/linkedin.webp';
import twitterr from '../assets/Footer/twitter.webp';
import youtube from '../assets/Footer/youtube.webp';
 
const Footer = () => {
  return (
    <footer className="bg-gradient_background8 font-display text-white py-10 px-4 ">
      <figure className="flex flex-row justify-between items-center ">

        <img src={footer} loading="lazy" alt="Logo" className="max-md:hidden lg:h-[35px] lg:w-[310px] md:w-[250px] md:h-[40px] lg:px-20  md:px-10  " />
        <img src={footer} loading="lazy" alt="Logo" className="md:hidden w-[130px] h-[30px]" />


        <div className="md:flex md:flex-row mb-3 mt-3 flex flex-row items-center md:px-5 px-10">
 

      <a href="https://www.linkedin.com/company/randomwalk-ai/mycompany/verification/" target="_blank" rel="noopener noreferrer">
        <img src={linkedinn} alt="LinkedIn" className="max-md:hidden lg:h-[24px] lg:w-[30px] md:w-[24px] md:h-[24px] " />
      </a>
      <a href="https://x.com/i/flow/login?redirect_after_login=%2Frandomwalk_ai" target="_blank" rel="noopener noreferrer">
        <img src={twitterr} alt="Twitter" className="max-md:hidden lg:h-[20px] lg:w-[20px] ml-4 md:w-[20px] md:h-[20px] " />
      </a>
      <a href="https://www.youtube.com/@Randomwalk_AI" target="_blank" rel="noopener noreferrer">
        <img src={youtube} alt="YouTube" className="max-md:hidden lg:h-[32px] lg:w-[32px] ml-4 md:w-[24px] md:h-[30px]" />
      </a>

      <a href="https://www.linkedin.com/company/randomwalk-ai/mycompany/verification/" target="_blank" rel="noopener noreferrer">
        <img src={linkedinn} alt="LinkedIn" className="md:hidden  w-[24px] h-[24px]" />
      </a>
      <a href="https://x.com/i/flow/login?redirect_after_login=%2Frandomwalk_ai" target="_blank" rel="noopener noreferrer">
        <img src={twitterr} alt="Twitter" className="md:hidden ml-4  w-[20px] h-[24px]" />
      </a>
      <a href="https://www.youtube.com/@Randomwalk_AI" target="_blank" rel="noopener noreferrer">
        <img src={youtube} alt="YouTube" className="md:hidden  ml-4 w-[28px] h-[28px]" />
      </a>


</div>
 
      </figure>
     
      <article className="flex flex-wrap justify-between mt-5 gap-2 lg:px-20  md:px-10">
        <div className="w-44 md:w-1/4 lg:w-40 ">
          <h4 className="mb-3 font-bold">Services</h4>
          <div className="text-[#C5C5C5]">
            <div className='pb-5 text-sm'><NavLink to="/ai-integration/" className="hover:text-white">AI Integration</NavLink></div>
            <div className='pb-5 text-sm'><NavLink to="/ai-managed-services/" className="hover:text-white">AI Managed Services</NavLink></div>
            <div className='pb-5 text-sm'><NavLink to="/ai-workshop-for-corporates/" className="hover:text-white">AI workshop for corporates</NavLink></div>
          </div>
        </div>
       
        <div className="w-36 md:w-1/4 lg:w-40">
          <h4 className="mb-3 font-bold">Products</h4>
          <div className="text-[#C5C5C5]">
            <div className='pb-5  text-sm'><NavLink to="/ai-readiness-index/" className="hover:text-white">AI Readiness Index</NavLink></div>
            <div className='pb-5  text-sm'><NavLink to="/brandcut/" className="hover:text-white">BrandCut</NavLink></div>
            <div className='pb-5  text-sm'><NavLink to="fortunecookie" className="hover:text-white">AI Fortune Cookie</NavLink></div>
          </div>
        </div>
        <div className="w-44 md:w-44 lg:w-20">
          <h4 className="mb-3 font-bold">Company</h4>
          <div className="text-[#C5C5C5]">
            <div className='pb-5  text-sm' ><NavLink to="/about-us/" className="hover:text-white">About Us</NavLink></div>
            <div className='pb-5 text-sm '><NavLink to="/careers/" className="hover:text-white">Careers</NavLink></div>
       
          </div>
        </div>
   
        <div className="w-36 md:w-1/4 lg:w-20 md:mt-10 lg:mt-0">
          <h4 className="mb-3 font-bold">Resources</h4>
          <div className="text-[#C5C5C5]">
            <div className='pb-5  text-sm'><NavLink to="/blog/" className="hover:text-white">Blog</NavLink></div>
            <div className='pb-5  text-sm'><NavLink to="/brochures/" className="hover:text-white">Brochures</NavLink></div>
            <div className='pb-5  text-sm'><NavLink to="/casestudy/" className="hover:text-white">Case Study</NavLink></div>
          </div>
        </div>
        <div className="w-44 md:w-1/4 lg:w-20 md:mt-10 lg:mt-0">
          <h4 className="mb-3 font-bold">Events</h4>
          <div className="text-[#C5C5C5]">
            <div className='pb-5  text-sm' ><NavLink to="/podcasts/" className="hover:text-white">Podcasts</NavLink></div>
            <div className='pb-5 text-sm '><NavLink to="/webinars/" className="hover:text-white">Webinars</NavLink></div>
            <div className='pb-5  text-sm'><NavLink to="/playlab/" className="hover:text-white">Events</NavLink></div>
           
          </div>
        </div>
       
        <div className="w-36 md:w-1/4 lg:w-56 md:mt-10 lg:mt-0">
          <h4 className="mb-3 font-bold">Support</h4>
          <div className="text-[#C5C5C5]">
            <div className='pb-5  text-sm'><NavLink to="/contact-us/" className="hover:text-white">Contact Us</NavLink></div>
            <div className='pb-5  text-sm'><NavLink to="/terms-of-service/" className="hover:text-white">Terms of Service</NavLink></div>
            <div className='pb-5  text-sm'><NavLink to="/privacy-policy/" className="hover:text-white">Privacy Policy</NavLink></div>
          </div>
        </div>
      </article>
     
   
      <div className="border-t w-full my-3 border-gray-300 "></div>
     
      <p className="text-center text-xs">© Random Walk. All Rights Reserved 2024.</p>
    </footer>
  );
}
 
export default Footer;