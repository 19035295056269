import React from "react";
import Popup from "../components/popup.js";
import { useState, useEffect, useRef } from "react";

import conn1 from "../assets/Integration/con1.webp";
import intbubble from "../assets/Integration/intbubble.webp";
import intbubble2 from "../assets/Integration/intbubble2.webp";
import homeflowmobile from "../assets/Integration/mobile2.webp";
import { Helmet } from "react-helmet";
import lightning from "../assets/Integration/lightning.webp";
import circle from "../assets/Integration/circle.webp";
import sensitive from "../assets/Integration/sensitive.webp";
import msg from "../assets/Integration/msg.webp";
import plus from "../assets/Integration/plus.webp";
import minus from "../assets/Integration/minus.webp";

import home2 from "../assets/Integration/home2.webp";

import heroo from "../assets/Integration/hero.svg";

const Integration = () => {
  const [lastX, setLastX] = useState(null);
  const handleMouseMove = (e) => {
    const container = document.getElementById("scroll-container");

    if (lastX !== null) {
      const movement = e.clientX - lastX;
      if (movement < 0) {
        container.scrollLeft += Math.abs(movement) * 2;
      }
    }

    setLastX(e.clientX);
  };

  const Dropdown = ({ question, answer, image, isOpen, onClick }) => {
    const contentRef = useRef(null);
    const [height, setHeight] = useState(0);

    React.useEffect(() => {
      if (contentRef.current) {
        setHeight(contentRef.current.scrollHeight);
      }
    }, [answer]);

    return (
      <div className="my-4 relative">
        <div
          className={` rounded-lg overflow-hidden ${isOpen ? "bg-white" : ""}`}
        >
          <a
            className={`bg-indigo-100 w-full text-xs 2xl:text-lg text-left font-display md:text-sm lg:text-sm p-2 md:py-4 md:px-4 hover:bg-indigo-200 hover:text-[#666666] flex justify-between items-center cursor-pointer transition-colors duration-300 ${
              isOpen ? "bg-indigo-200 rounded-t-lg" : "rounded-lg"
            }`}
            onClick={onClick}
          >
            <h2>{question}</h2>
            <img
              src={isOpen ? minus : plus}
              alt="Toggle Icon"
              className={`ml-2 w-6 h-6 transition-transform duration-300 ${
                isOpen ? "rotate-180" : ""
              }`}
            />
          </a>
          <div
            className={`overflow-hidden transition-all duration-300 ease-in-out`}
            style={{ maxHeight: isOpen ? `${height}px` : "0" }}
          >
            <div
              ref={contentRef}
              className="text-xs bg-gray-100 2xl:text-lg py-6 px-4 font-display md:text-sm lg:text-sm"
            >
              <p className="opacity-100 transition-opacity duration-300">
                {answer}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    // Set page title
    document.title = "Random Walk | Leading AI Integration Provider";

    // Set meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', "Streamline your operations with Random Walk’s AI integration services. Benefit from expert AI consultations, AI assessments, and AI workshops to implement AI seamlessly into your business processes.");
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = "Streamline your operations with Random Walk’s AI integration services. Benefit from expert AI consultations, AI assessments, and AI workshops to implement AI seamlessly into your business processes.";
      document.head.appendChild(meta);
    }

    // Add or update Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement('meta');
      ogTitle.setAttribute('property', 'og:title');
      document.head.appendChild(ogTitle);
    }
    ogTitle.setAttribute('content', "Random Walk | Leading AI Integration Provider");

    // Add or update Open Graph description
    let ogDescription = document.querySelector('meta[property="og:description"]');
    if (!ogDescription) {
      ogDescription = document.createElement('meta');
      ogDescription.setAttribute('property', 'og:description');
      document.head.appendChild(ogDescription);
    }
    ogDescription.setAttribute('content', "Streamline your operations with Random Walk’s AI integration services. Benefit from expert AI consultations, AI assessments, and AI workshops to implement AI seamlessly into your business processes.");

    // Add viewport meta tag
    let viewport = document.querySelector('meta[name="viewport"]');
    if (!viewport) {
      viewport = document.createElement('meta');
      viewport.name = "viewport";
      document.head.appendChild(viewport);
    }
    viewport.setAttribute('content', "width=device-width, initial-scale=1");

    // Add theme-color meta tag
    let themeColor = document.querySelector('meta[name="theme-color"]');
    if (!themeColor) {
      themeColor = document.createElement('meta');
      themeColor.name = "theme-color";
      document.head.appendChild(themeColor);
    }
    themeColor.setAttribute('content', "#000000");

    // Add Open Graph image
    let ogImage = document.querySelector('meta[property="og:image"]');
    if (!ogImage) {
      ogImage = document.createElement('meta');
      ogImage.setAttribute('property', 'og:image');
      document.head.appendChild(ogImage);
    }
    ogImage.setAttribute('content', `${process.env.PUBLIC_URL}/rw_logo.png`);

    // Add Open Graph type
    let ogType = document.querySelector('meta[property="og:type"]');
    if (!ogType) {
      ogType = document.createElement('meta');
      ogType.setAttribute('property', 'og:type');
      document.head.appendChild(ogType);
    }
    ogType.setAttribute('content', "website");

    // Add canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.rel = "canonical";
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.href = "https://randomwalk.ai/ai-integration/";
  }, []);


  const DropdownList = () => {
    const [openIndex, setOpenIndex] = useState(null);
    const dropdowns = [
      {
        question: "What Does Random Walk AI Integration Offer?",
        answer:
          "With a multitude of AI software available in the market, determining the best fit for your needs can be overwhelming. Instead of integrating all tools, analyzing your business challenges and requirements enables the selection of suitable options. For expert guidance in AI integration and tailored AI services, consider partnering with Random Walk to advise, guide, and lead your organization through the AI transformation process.",
        image: "arrowdown.png",
      },
      {
        question: "What Types Of Systems Could AI Be Integrated With?",
        answer:
          "Evaluate your existing business systems, such as data management platforms or operational tools, to identify areas for AI integration and enhanced performance. From CRM to ERP and everything in between, integrating an AI solution can improve operational efficiency. Select an AI solution that aligns with your specific business requirements and can seamlessly integrate with your current systems. For expert AI services and support, seek guidance from Random Walk or consult with an AI consultant if you lack in-house expertise.",
        image: "arrowdown.png",
      },
      {
        question: "How Much Does AI Integration Cost?",
        answer:
          "The pricing of AI consulting services for businesses is influenced by factors like project scale, complexity, API configuration, and specific customization requirements. Through our partnerships with leading AI tool developers, we offer competitive pricing for seamless AI integration for business operations. Our comprehensive AI consulting services ensure that your organization benefits from tailored AI solutions that align with your objectives. For an accurate quote, reach out to us, and we’ll collaborate with you to provide a customized pricing solution based on your needs and business goals.",
        image: "arrowdown.png",
      },
      {
        question:
          "Why Should You Partner With Random Walk For Your Next AI Integration Project?",
        answer:
          "Random Walk serves as a hub, partnering with multiple AI tool developers to deliver top-tier AI consulting services for businesses. Through our extensive network, we deliver seamless AI integration and offer expert AI consulting services to support your projects from start to finish. We ensure that your unique business needs are met with precision, streamlining operations and enhancing efficiency with comprehensive support. Choose the ideal AI tools for your business with expert guidance from Random Walk, where we collaborate to implement custom AI solutions.",
        image: "arrowdown.png",
      },
      {
        question: "Does Random Walk Provide After-Service Support?",
        answer:
          "Random Walk offers complimentary after-service support to our customers, ensuring a seamless experience with our AI integration services for enterprises. Our dedicated team is committed to providing ongoing assistance and guidance, whether you need help with AI consulting services for businesses or maintenance of custom AI development services. Count on us to address any issues or questions that may arise post-implementation, providing expert support for AI integration. We prioritize your satisfaction, delivering reliable AI services and comprehensive AI solutions.",
        image: "arrowdown.png",
      },
    ];

    return (
      <div className="text-[#666666]">
        {dropdowns.map((dropdown, index) => (
          <Dropdown
            key={index}
            question={dropdown.question}
            answer={dropdown.answer}
            image={dropdown.image}
            isOpen={index === openIndex}
            onClick={() => setOpenIndex(index === openIndex ? null : index)}
          />
        ))}
      </div>
    );
  };

  const [activeButton, setActiveButton] = useState(1);
  const [content, setContent] = useState("");
  const [image, setImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [rightSideText, setRightSideText] = useState("");
  const [activeWorkshop, setActiveWorkshop] = useState(null);
  const [buttonText, setButtonText] = useState({
    work1: "",
    work2: "",
    work3: "",
    work4: "",
  });
  const [textVisibility, setTextVisibility] = useState({
    work1: false,
    work2: false,
    work3: false,
    work4: false,
  });
  const [formData, setFormData] = useState({
    name: "",
    email: "",
  });

  useEffect(() => {
    const link = document.createElement("link");
    link.rel = "preload";
    link.as = "image";
    link.href = conn1; // Image source
    document.head.appendChild(link);

    return () => {
      document.head.removeChild(link); // Cleanup on component unmount
    };
  }, []);

  useEffect(() => {
    handleButtonClick(workshops[0]);
  }, []);

  const handleButtonClick = (workshop) => {
    setTextVisibility((prevState) => ({
      ...Object.fromEntries(
        Object.keys(prevState).map((key) => [
          key,
          key === workshop.id ? !prevState[key] : false,
        ])
      ),
    }));

    setActiveWorkshop(workshop.id === activeWorkshop ? null : workshop.id);

    setButtonText((prevState) => ({
      ...prevState,
      [workshop.id]: !prevState[workshop.id] ? workshop.text : "",
    }));

    setSelectedImage(workshop.image);

    const workshopTexts = {
      work1: (
        <>
          <div className="font-display max-md:hidden 2xl:pb-9 lg:pb-10">
            <div className="mt-4 flex items-center justify-center">
              <img
                src={lightning}
                alt="Workshop Icon"
                className="w-20 md:w-24"
              />
            </div>
            <p className="font-display  font-grayy text-sm md:text-base font-bold">
              Boost Sales with AI Powered Predictive Analytics
            </p>
            <p className="md:text-xs lg:text-base  pb-5 ">
              Our AI consulting and integration services will allow you to
              forecast demand and analyze customer behavior accurately with
              predictive models. Harness the potential of recommendation engines
              to boost cross-selling and upselling opportunities.
            </p>

            <ul className="list-disc text-xs md:text-xs lg:text-base  pl-6 lg:text-md">
              <li className="p-0">Predictive analysis</li>
              <li className="p-0">Lead scoring</li>
              <li className="p-0">Personalization</li>
              <li className="p-0">AI Chatbots</li>
              <li className="p-0">Sales recommendations </li>
              <li className="p-0">CRM Automation</li>
              <li className="p-0">Competitive insights</li>
              <li className="p-0">Pricing optimization</li>
              <li className="p-0">Customer insights</li>
              <li className="p-0">Workflow automation</li>
            </ul>
          </div>

          <div className="font-display md:hidden 2xl:pb-5 lg:pb-4">
            <p className="font-display font-grayy text-sm md:text-base font-bold">
              Boost Sales with AI Powered Predictive Analytics
            </p>
            <p className="lg:text-base text-xs pb-5">
              Our AI consulting and integration services will allow you to
              forecast demand and analyze customer behavior accurately with
              predictive models. Harness the potential of recommendation engines
              to boost cross-selling and upselling opportunities.
            </p>
            <ul className="list-disc text-xs pl-6 lg:text-md">
              <li className="p-0">Predictive analysis</li>
              <li className="p-0">Lead scoring</li>
              <li className="p-0">Personalization</li>
              <li className="p-0">AI Chatbots</li>
              <li className="p-0">Sales recommendations</li>
              <li className="p-0">CRM Automation</li>
              <li className="p-0">Competitive insights</li>
              <li className="p-0">Pricing optimization</li>
              <li className="p-0">Customer insights</li>
              <li className="p-0">Workflow automation</li>
            </ul>
          </div>
        </>
      ),

      work2: (
        <>
          <div className="font-display max-md:hidden 2xl:pb-9 lg:pb-10">
            <div className="mt-4 flex items-center justify-center">
              <img
                src={circle}
                alt="Workshop Icon"
                className="md:w-[180px] md:h-[180px]"
              />
            </div>
            <p className="font-display  font-grayy text-sm md:text-base font-bold">
              Revolutionize Visual Object Recognition with our visual AI
              services
            </p>
            <p className=" md:text-xs lg:text-base text-xs pb-5 ">
              Employ state-of-the-art deep learning models like YOLOV8 for
              real-time object detection. Use image classification with models
              such as Inception to identify and track objects in various
              scenarios. Enhance security, automation, and decision-making
              across industries with the assistance of visual AI services.
            </p>

            <ul className="list-disc  md:text-xs lg:text-base text-xs  pl-6 lg:text-md">
              <li className="p-0">Inward inventory</li>
              <li className="p-0">Image classification</li>
              <li className="p-0">Customizable models</li>
              <li className="p-0">Merchandising - customer flow</li>
              <li className="p-0">
                Employee tracking (Safety, attendance, count etc.)
              </li>
              <li className="p-0">Vehicle or object counting</li>
            </ul>
          </div>

          <div className="font-display md:hidden 2xl:pb-5 lg:pb-4">
            <p className="font-display font-grayy text-sm md:text-base font-bold">
              Revolutionize Visual Object Recognition with our visual AI
              services
            </p>
            <p className="lg:text-base text-xs pb-5">
              Employ state-of-the-art deep learning models like YOLOV8 for
              real-time object detection. Use image classification with models
              such as Inception to identify and track objects in various
              scenarios. Enhance security, automation, and decision- making
              across industries with the assistance of visual AI services.
            </p>
            <ul className="list-disc text-xs pl-6 lg:text-md">
              <li className="p-0">Inward inventory</li>
              <li className="p-0">Image classification</li>
              <li className="p-0">Customizable models</li>
              <li className="p-0">Merchandising - customer flow</li>
              <li className="p-0">
                Employee tracking (Safety, attendance, count etc.)
              </li>
              <li className="p-0">Vehicle or object counting</li>
            </ul>
          </div>
        </>
      ),
      work3: (
        <>
          <div className="font-display max-md:hidden 2xl:pb-9 lg:pb-10">
            <div className="mt-4 flex items-center justify-center">
              <img
                src={sensitive}
                alt="Workshop Icon"
                className=" md:w-[115px] md:h-[160px]"
              />
            </div>
            <p className="font-display  font-grayy text-sm md:text-base font-bold">
              AI-Driven Content Moderation for Enhanced User Safety
            </p>
            <p className=" md:text-xs lg:text-base text-xs pb-5 ">
              Random Walk's content moderation service could be used to apply
              Convolutional Neural Networks (CNN) to scrutinize multimedia
              content for inappropriate or harmful material. Employ Natural
              Language Processing (NLP) models such as BERT for text-based
              content filtering, guaranteeing a secure online environment.
            </p>

            <ul className="list-disc  md:text-xs lg:text-base text-xs  pl-6 lg:text-md">
              <li className="p-0">Automated filtering</li>
              <li className="p-0">Image recognition</li>
              <li className="p-0">Profanity detection</li>
              <li className="p-0">Real-time monitoring</li>
              <li className="p-0">Custom rules</li>
              <li className="p-0">Multilingual moderation</li>
              <li className="p-0">User profiling</li>
              <li className="p-0">Context analysis</li>
              <li className="p-0">Post-moderation</li>
              <li className="p-0">Scalable solution</li>
            </ul>
          </div>

          <div className="font-display md:hidden 2xl:pb-5 lg:pb-4">
            <p className="font-display font-grayy text-sm md:text-base font-bold">
              AI-Driven Content Moderation for Enhanced User Safety
            </p>
            <p className="lg:text-base text-xs pb-5">
              Random Walk's content moderation service could be used to apply
              Convolutional Neural Networks (CNN) to scrutinize multimedia
              content for inappropriate or harmful material. Employ Natural
              Language Processing (NLP) models such as BERT for text-based
              content filtering, guaranteeing a secure online environment.
            </p>
            <ul className="list-disc text-xs pl-6 lg:text-md">
              <li className="p-0">Automated filtering</li>
              <li className="p-0">Image recognition</li>
              <li className="p-0">Profanity detection</li>
              <li className="p-0">Real-time monitoring</li>
              <li className="p-0">Custom rules</li>
              <li className="p-0">Multilingual moderation</li>
              <li className="p-0">User profiling</li>
              <li className="p-0">Context analysis</li>
              <li className="p-0">Post-moderation</li>
              <li className="p-0">Scalable solution</li>
            </ul>
          </div>
        </>
      ),
      work4: (
        <>
          <div className="font-display max-md:hidden 2xl:pb-9 lg:pb-10">
            <div className="mt-4 flex items-center justify-center">
              <img
                src={msg}
                alt="Workshop Icon"
                className=" md:w-[120px] md:h-[130px]  "
                loading="lazy"
              />
            </div>
            <p className="font-display  font-grayy text-sm md:text-base font-bold">
              AI-Enhanced Customer Service Experience
            </p>
            <p className=" md:text-xs lg:text-base text-xs pb-5 ">
              Make your customer service smart with AI integration. Deploy
              Natural Language Processing (NLP) models for chatbots, use
              Recurrent Neural Networks (RNN) for real-time sentiment analysis,
              and develop predictive models for personalized customer support.
            </p>

            <ul className="list-disc  md:text-xs lg:text-base text-xs  pl-6 lg:text-md">
              <li className="p-0">AI Chatbots</li>
              <li className="p-0">Virtual avatars</li>
              <li className="p-0">Virtual agents</li>
              <li className="p-0">Sentiment analysis</li>
              <li className="p-0">Self-service portals</li>
              <li className="p-0">Knowledge base</li>
              <li className="p-0">Automated FAQs</li>
              <li className="p-0">Social media monitoring</li>
              <li className="p-0">Voice recognition</li>
            </ul>
          </div>

          <div className="font-display md:hidden 2xl:pb-5 lg:pb-4">
            <p className="font-display font-grayy text-sm md:text-base font-bold">
              AI-Enhanced Customer Service Experience
            </p>
            <p className="lg:text-base text-xs pb-5">
              Make your customer service smart with AI integration. Deploy
              Natural Language Processing (NLP) models for chatbots, use
              Recurrent Neural Networks (RNN) for real-time sentiment analysis,
              and develop predictive models for personalized customer support.
            </p>
            <ul className="list-disc text-xs pl-6 lg:text-md">
              <li className="p-0">AI Chatbots</li>
              <li className="p-0">Virtual avatars</li>
              <li className="p-0">Virtual agents</li>
              <li className="p-0">Sentiment analysis</li>
              <li className="p-0">Self-service portals</li>
              <li className="p-0">Knowledge base</li>
              <li className="p-0">Automated FAQs</li>
              <li className="p-0">Social media monitoring</li>
              <li className="p-0">Voice recognition</li>
            </ul>
          </div>
        </>
      ),
    };

    setRightSideText(workshopTexts[workshop.id]);
  };

  const workshops = [
    {
      id: "work1",
      buttonText: "Sales and Marketing",
      image: lightning,
    },
    {
      id: "work2",
      buttonText: "Visual Object Recognition",
      image: circle,
    },
    {
      id: "work3",
      buttonText: "Content Moderation",
      image: sensitive,
    },
    {
      id: "work4",
      buttonText: "Customer Service",
      image: msg,
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    window.location.href =
      "https://randomwalk.ai/wp-content/uploads/2024/01/Random-Walk-Common-AI-Brochure-v3.pdf";
  };

  return (
    <section className="Main2 font-display">
      {/* <Helmet>
        <title>Random Walk | Leading AI Integration Provider</title>
        <meta
          property="og:title"
          content="Random Walk | Leading AI Integration Provider"
        />
        <meta
          name="description"
          content="Streamline your operations with Random Walk’s AI integration services. Benefit from expert AI consultations, AI assessments, and AI workshops to implement AI seamlessly into your business processes."
        />
        <meta
          property="og:description"
          content="Streamline your operations with Random Walk’s AI integration services. Benefit from expert AI consultations, AI assessments, and AI workshops to implement AI seamlessly into your business processes."
        />
        <link rel="canonical" href="https://randomwalk.ai/ai-integration/" />
      </Helmet> */}

      <section className="bg-bloghome pt-20 h-auto md:pb-20  lg:h-screen flex flex-col justify-center items-center">
        <h1 className="text-[#666666] text-xl md:text-3xl pt-10 text-center  font-bold lg:text-5xl lg:text-center lg:pt-32 pb-5">
          AI Capabilities At Warp Speed <br />
          With <span className="text-[#4C00FE]">No Delivery Risk. </span>
        </h1>

        <p className="md:hidden block text-[#666666] font-medium  px-5 lg:px-0  text-xs text-center  font-display lg:text-base lg:text-center pb-5 lg:pb-10">
          With our deep expertise in AI technologies and development, we provide
          AI integration with thorough quality checks, ensuring a smooth AI
          implementation without delivery risks.
        </p>
        <p className="text-[#666666] font-medium max-md:hidden  px-5 lg:px-0  text-xs text-center  font-display lg:text-base lg:text-center pb-5 lg:pb-10">
          With our deep expertise in AI technologies and development, we provide
          AI integration with thorough quality checks,
          <br /> ensuring a smooth AI implementation without delivery risks.
        </p>

        <img
          src={heroo}
          alt="AI_Integration"
          className="block md:hidden w-[450px] h-[120px] pb-8 "
        />
        <img
          src={heroo}
          alt="AI_Integration"
          className="max-md:hidden  h-[200px] w-[1000px] mt-5 "
        />
      </section>

      <section className="flex justify-center">
        <article className="w-full  md:px-20  lg:py-10 2xl:py-32 lg:px-36 2xl:px-56">
          <img
            src={conn1}
            alt="AI Integration"
            loading="eager" 
          fetchpriority="high" 
            className="md:hidden w-[280px] h-[300px] py-8 h-auto mx-auto "
          />

          <div className="md:flex px-10 pb-10 md:py-5   md:px-0 justify-center items-center ">
            <div className=" text-[#666666] md:text-left">
              <h2 className="font-display md:pb-5 text-xl md:text-3xl font-semibold pb-2 2xl:text-4xl text-left ">
                AI Integration
              </h2>
              <p className="lg:text-sm text-xs 2xl:text-xl md:mr-5 lg:mr-16 font-display  md:pb-2">
                Random Walk’s AI integration services allow you to tap the
                potential of AI in different departments of your business,
                including marketing, customer support, sales, accounting, and
                more.
              </p>
              <p className="lg:text-sm text-xs 2xl:text-xl  md:mr-5 lg:mr-16 font-display  md:pb-2">
                To evaluate the possible effects of AI implementation in your
                operations, we conduct comprehensive AI assessments including
                data audits, feasibility checks, and brainstorming sessions to
                identify key efficiency gains and guide tailored AI
                implementations.
              </p>
              <p className="lg:text-sm text-xs 2xl:text-xl  md:mr-5 lg:mr-16 font-display  md:pb-2">
                Benefit from AI consulting services and workshops facilitated by
                our experienced technology and domain experts to establish a
                roadmap for seamless AI integration into your operations.
              </p>
            </div>

            <img
              src={conn1}
              alt="AI_Integration"
              loading="priority"
              fetchpriority="high"
              className="max-md:hidden md:w-[280px] md:h-[280px] lg:w-[405px] h-[350px]"
            />
          </div>
        </article>
      </section>

      <section className="md:px-20 md:mt-0 lg:px-28 2xl:px-56">
        <article className="max-md:hidden text-center md:px-1 md:pt-5 rounded-2xl bg-[#3c5cb4] md:text-left font-display">
          <div className="flex flex-col  md:flex-row justify-center md:justify-between mt-5 lg:mt-8 px-4 md:px-10 lg:px-5 2xl:px-5">
            <p className="flex-1 text-base md:text-xs lg:text-base 2xl:text-xl lg:text-md text-white px-2 lg:px-4">
              Choose the ideal AI tools for your business with expert guidance
              from Random Walk, where we collaborate to implement custom AI
              solutions.
            </p>
            <p className="flex-1 px-2 text-base md:text-xs lg:text-base 2xl:text-xl lg:text-md text-white lg:px-4 mt-4 md:mt-0">
              Our engineers specialize in seamless AI integration, accelerating
              connections between AI tools and existing software.
            </p>
            <p className="flex-1 px-2 lg:px-4 text-base lg:text-base 2xl:text-xl md:text-xs lg:text-md text-white mt-4 md:mt-0">
              From business functions like marketing, HR and finance to
              different industries like retail and pharma, find the right AI
              tool.
            </p>
          </div>

          <figure className=" lg:scale-110">
            <img src={home2} alt="AI_Integration" loading="lazy" />
          </figure>
        </article>
      </section>

      <figure className="md:hidden block text-center   bg-[#3c5cb4] md:text-left font-display overflow-x-scroll">
        <div className="w-[200%] inline-block pt-10 ">
          <img src={homeflowmobile} alt="AI_Integration" className="z" />
        </div>
      </figure>

      <section className="relative max-md:hidden bg-no-repeat md:px-16 lg:mx-20 2xl:px-40 lg:pt-10 bg-cover flex justify-center items-center rounded-2xl">
        <div className="relative w-full">
          <img
            src={intbubble}
            alt="Bubble Background"
            className="absolute z-0 w-[350px]  h-[400px] md:w-[350px] md:w-[400px] lg:w-[420px] lg:h-[500px] md:-top-20 md:-left-32 lg:-top-10 lg:-left-28 left-0"
          />

          <article className="flex z-40 justify-center items-start h-full">
            <div className="flex lg:pb-10 pl-5 flex-col items-center relative z-10 space-y-5 md:mr-10 lg:mr-20 pt-10 md:pt-20 md:w-full">
              {workshops.map((workshop) => (
                <a
                  key={workshop.id}
                  className={`font-display text-[#666666] hover:bg-white text-xs px-5 py-4 md:py-6 md:px-12 md:pr-20 flex items-center justify-center w-full border rounded-none border-gray-900 border-opacity-20 relative z-10 transition-all duration-300 focus:outline-none cursor-pointer ${
                    activeWorkshop === workshop.id
                      ? "bg-white md:scale-110 scale-100 md:translate-x-5 shadow-2xl"
                      : "bg-white"
                  }`}
                  onClick={() => handleButtonClick(workshop)}
                >
                  <header
                    className={`flex items-center flex-col transition-transform duration-300 ${
                      activeWorkshop === workshop.id
                        ? "transform translate-x-2 md:translate-x-5"
                        : ""
                    }`}
                  >
                    <div className="flex items-center justify-center">
                      <span
                        className={`font-bold text-xs overflow-hidden whitespace-nowrap overflow-ellipsis ${
                          activeWorkshop === workshop.id
                            ? "md:text-sm font-bold translate-x-1 md:translate-x-2 lg:text-xl md:scale-110 text-[#C62B6D]"
                            : "md:text-sm lg:text-lg font-bold text-[#666666]"
                        }`}
                      >
                        {workshop.buttonText}
                      </span>
                    </div>
                    {activeWorkshop === workshop.id && (
                      <span className="text-[#666666] lg:text-sm text-xs overflow-hidden whitespace-nowrap overflow-ellipsis">
                        {workshop.textBelow}
                      </span>
                    )}
                  </header>
                </a>
              ))}
            </div>

            <div className="flex-grow relative py-10 flex justify-center items-center">
              <div className="bg-cover relative bg-center">
                <img
                  src={intbubble2}
                  alt="Ai_integration_img1"
                  loading="lazy"
                  className="absolute  md:w-[300px] md:h-[350px] md:bottom-0 md:left-28 lg:w-[380px] lg:h-[400px] lg:-bottom-28 lg:left-36 2xl:top-40"
                />

                <div className="border bg-white shadow-sm border-white flex flex-col items-center justify-center md:h-full md:w-full">
                  {rightSideText && (
                    <article className="p-6 md:text-sm rounded-lg md:w-auto text-[#666666] flex items-center justify-center">
                      <p>{rightSideText}</p>
                    </article>
                  )}
                </div>
              </div>
            </div>
          </article>
        </div>
      </section>

      <section className="relative md:hidden bg-no-repeat md:px-16 lg:mx-20 2xl:px-40 lg:pt-10 bg-cover flex justify-center items-center rounded-2xl">
        <div className="relative overflow-x-auto">
          <img
            src={intbubble}
            alt="Bubble Background"
            className="absolute z-0 w-6/12 md:w-6/12 lg:w-5/12 md:-top-20 md:-left-32 lg:-top-10 lg:-left-28 left-0"
          />

          <div className="flex flex-col md:flex-row z-40 justify-center items-start h-full">
            <article className="block md:hidden relative h-5/6  flex flex-col justify-start   bg-cover bg-center">
              <div
                id="scroll-container"
                className="mt-10 px-8 flex flex flex-row gap-4 overflow-x-auto scroll-smooth pb-10"
              >
                <div className="w-5/12 border-2 border-gray-100 text-white p-6 rounded-lg shadow-lg flex-shrink-0">
                  <figure className="flex justify-center items-center">
                    <img
                      src={lightning}
                      alt="Sales_and_Marketing"
                      className="w-20 object-cover z-10"
                    />
                  </figure>

                  <h2 className="text-base text-[#C62B6D] font-bold  font-display mt-10 mr-10">
                    Sales and Marketing
                  </h2>
                  <p className="font-display text-sm text-left  font-semibold text-[#666666] mt-5 ">
                    Boost Sales with AI Powered Predictive Analytics.
                  </p>
                  <p className="text-xs  pt-5  text-[#000000] ">
                    Our AI consulting and integration services will allow you to
                    forecast demand and analyze customer behavior accurately
                    with predictive models. Harness the potential of
                    recommendation engines to boost cross-selling and upselling
                    opportunities.
                  </p>
                  <ul className="list-disc  md:text-xs text-[#000000] lg:text-base text-xs  pl-6 lg:text-md">
                    <li className="p-0">Inward inventory</li>
                    <li className="p-0">Image classification</li>
                    <li className="p-0">Customizable models</li>
                    <li className="p-0">Merchandising - customer flow</li>
                    <li className="p-0">
                      Employee tracking (Safety, attendance, count etc.)
                    </li>
                    <li className="p-0">Vehicle or object counting</li>
                  </ul>
                </div>
                <article className="w-5/12 border-2 border-gray-100 text-white p-6 rounded-lg shadow-lg flex-shrink-0">
                  <figure className="flex justify-center items-center">
                    <img
                      src={circle}
                      alt="Visual_object_recognition"
                      className="w-[130px] h-[120px] object-cover z-10"
                    />
                  </figure>

                  <h2 className="text-base text-[#C62B6D] font-bold  font-display mt-12 mr-10">
                    Visual Object Recognition
                  </h2>
                  <p className="font-display text-sm text-left  font-semibold text-[#666666] mt-5 ">
                    Revolutionize Visual Object Recognition with our visual AI
                    services
                  </p>
                  <p className="text-xs  pt-5  text-[#000000] ">
                    Employ state-of-the-art deep learning models like YOLOV8 for
                    real-time object detection. Use image classification with
                    models such as Inception to identify and track objects in
                    various scenarios. Enhance security, automation, and
                    decision- making across industries with the assistance of
                    visual AI services.
                  </p>
                  <ul className="list-disc  md:text-xs lg:text-base text-xs  text-[#000000]  pl-6 lg:text-md">
                    <li className="p-0">Inward inventory</li>
                    <li className="p-0">Image classification</li>
                    <li className="p-0">Customizable models</li>
                    <li className="p-0">Merchandising - customer flow</li>
                    <li className="p-0">
                      Employee tracking (Safety, attendance, count etc.)
                    </li>
                    <li className="p-0">Vehicle or object counting</li>
                  </ul>
                </article>
                <article className="w-5/12 border-2 border-gray-100 text-white  rounded-lg shadow-lg flex-shrink-0 ">
                  <figure className="flex justify-center items-center">
                    <img
                      src={sensitive}
                      alt="Content_Moderation"
                      className="w-[130px] h-[170px] object-cover z-10"
                      loading="lazy"
                    />
                  </figure>

                  <h2 className="text-base text-[#C62B6D] font-bold  font-display mt-8 mr-10">
                    Content Moderation
                  </h2>
                  <p className="font-display text-sm text-left  font-semibold text-[#666666] mt-5  px-6 ">
                    AI-Driven Content Moderation for Enhanced User Safety
                  </p>
                  <p className="text-xs  pt-5  text-[#000000] px-6 ">
                    Random Walk's content moderation service could be used to
                    apply Convolutional Neural Networks (CNN) to scrutinize
                    multimedia content for inappropriate or harmful material.
                    Employ Natural Language Processing (NLP) models such as BERT
                    for text-based content filtering, guaranteeing a secure
                    online environment.
                  </p>
                  <ul className="list-disc  md:text-xs lg:text-base text-xs  text-[#000000]  pl-6 lg:text-md pb-4">
                    <li className="p-0">Inward inventory</li>
                    <li className="p-0">Image classification</li>
                    <li className="p-0">Customizable models</li>
                    <li className="p-0">Merchandising - customer flow</li>
                    <li className="p-0">
                      Employee tracking (Safety, attendance, count etc.)
                    </li>
                    <li className="p-0">Vehicle or object counting</li>
                  </ul>
                </article>
                <article className="w-5/12 border-2 border-gray-100 text-white  rounded-lg shadow-lg flex-shrink-0 ">
                  <figure className="flex justify-center items-center">
                    <img
                      src={msg}
                      alt="Customer_service"
                      loading="lazy"
                      className="w-[140px] h-[140px] object-cover z-10 mt-7"
                    />
                  </figure>

                  <h2 className="text-base text-[#C62B6D] font-bold  font-display mt-8 mr-10">
                    Customer Service
                  </h2>
                  <p className="font-display text-sm text-left  font-semibold text-[#666666] mt-5  px-6 ">
                    AI-Enhanced Customer Service Experience
                  </p>
                  <p className="text-xs  pt-5  text-[#000000] px-6 ">
                    Make your customer service smart with AI integration. Deploy
                    Natural Language Processing (NLP) models for chatbots, use
                    Recurrent Neural Networks (RNN) for real-time sentiment
                    analysis, and develop predictive models for personalized
                    customer support.
                  </p>
                  <ul className="list-disc  md:text-xs lg:text-base text-xs text-[#000000] pl-6 lg:text-md">
                    <li className="p-0">AI Chatbots</li>
                    <li className="p-0">Virtual avatars</li>
                    <li className="p-0">Virtual agents</li>
                    <li className="p-0">Sentiment analysis</li>
                    <li className="p-0">Self-service portals</li>
                    <li className="p-0">Knowledge base</li>
                    <li className="p-0">Automated FAQs</li>
                    <li className="p-0">Social media monitoring</li>
                    <li className="p-0">Voice recognition</li>
                  </ul>
                </article>
              </div>
            </article>

            <aside className="hidden md:flex md:flex-row z-40 justify-center items-start h-full">
              <div className="flex lg:pb-10 pl-5 flex-col items-center relative z-10 space-y-5 md:mr-10 lg:mr-20 pt-10 md:pt-20 md:w-full">
                {workshops.map((workshop) => (
                  <button
                    key={workshop.id}
                    className={`font-display text-[#666666] hover:bg-white text-xs px-5 py-4 md:py-6 md:px-12 md:pr-20 flex items-center justify-center w-full border rounded-none border-gray-900 border-opacity-20 relative z-10 transition-all duration-300 focus:outline-none ${
                      activeWorkshop === workshop.id
                        ? "bg-white md:scale-110 scale-100 md:translate-x-5 shadow-2xl text-pink-600"
                        : "bg-white"
                    }`}
                    onClick={() => handleButtonClick(workshop)}
                  ></button>
                ))}
              </div>

              <aside className="flex-grow relative py-10 flex justify-center items-center md:flex md:justify-center">
                <div className="bg-cover relative bg-center">
                  <img
                    src={intbubble2}
                    alt="Bubble Background"
                    className="absolute md:w-9/12 md:bottom-0 md:left-28 lg:w-10/12 lg:-bottom-28 lg:left-36 2xl:top-40"
                  />
                  <article className="border bg-white shadow-sm border-white flex flex-col items-center justify-center md:h-full md:w-full">
                    {rightSideText && (
                      <article className="hidden md:flex p-6 md:text-sm rounded-lg md:w-auto text-[#666666] items-center justify-center">
                        {rightSideText}
                      </article>
                    )}
                  </article>
                </div>
              </aside>
            </aside>
          </div>
        </div>
      </section>
      <section className=" cursor-pointer bg-no-repeat text-[#666666] bg-cover lg:px-24 md:px-10 rounded-2xl 2xl:w-auto 2xl:mx-40 ">
        <article className="container mx-auto p-4">
          <h2 className="text-center  md:text-2xl lg:text-2xl font-grayy lg:my-2 lg:pb-3 font-bold font-display  text-[#C62B6D]  2xl:text-3xl ">
            Frequently Asked Questions
          </h2>
          <DropdownList />
        </article>
      </section>

      <section className=" mt-20 ">
        <Popup utmContent={"ai-integration"} />
      </section>
    </section>
  );
};
export default Integration;
