import React from "react";
import "./About.css";
import Popup from "../components/popup.js";
import { useEffect, useRef } from "react";
import aboutt2 from "../assets/About/new.webp";
import aboutbg from "../assets/About/aboutbg2.webp";
import aboutbg4img from "../assets/About/aboutbg4img2.webp";
import aboutbg6img from "../assets/About/aboutbg6img2.webp";
import aboutbg8img from "../assets/About/aboutbg8img2.webp";
import pinkblue from "../assets/About/pinkblue.webp";
import bluepink from "../assets/About/bluepink2.webp";
import { Helmet } from "react-helmet-async";

const About = () => {


  useEffect(() => {
    // Set page title
    document.title = "About Us | Random Walk";

    // Set meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', "Random Walk is on a mission to democratize AI, offering AI solutions and AI services to businesses and individuals, helping them understand AI and utilize AI tools for growth and innovation.");
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = "Random Walk is on a mission to democratize AI, offering AI solutions and AI services to businesses and individuals, helping them understand AI and utilize AI tools for growth and innovation.";
      document.head.appendChild(meta);
    }

    // Add or update Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement('meta');
      ogTitle.setAttribute('property', 'og:title');
      document.head.appendChild(ogTitle);
    }
    ogTitle.setAttribute('content', "About Us | Random Walk");

    // Add or update Open Graph description
    let ogDescription = document.querySelector('meta[property="og:description"]');
    if (!ogDescription) {
      ogDescription = document.createElement('meta');
      ogDescription.setAttribute('property', 'og:description');
      document.head.appendChild(ogDescription);
    }
    ogDescription.setAttribute('content', "Random Walk is on a mission to democratize AI, offering AI solutions and AI services to businesses and individuals, helping them understand AI and utilize AI tools for growth and innovation.");

    // Add viewport meta tag
    let viewport = document.querySelector('meta[name="viewport"]');
    if (!viewport) {
      viewport = document.createElement('meta');
      viewport.name = "viewport";
      document.head.appendChild(viewport);
    }
    viewport.setAttribute('content', "width=device-width, initial-scale=1");

    // Add theme-color meta tag
    let themeColor = document.querySelector('meta[name="theme-color"]');
    if (!themeColor) {
      themeColor = document.createElement('meta');
      themeColor.name = "theme-color";
      document.head.appendChild(themeColor);
    }
    themeColor.setAttribute('content', "#000000");

    // Add Open Graph image
    let ogImage = document.querySelector('meta[property="og:image"]');
    if (!ogImage) {
      ogImage = document.createElement('meta');
      ogImage.setAttribute('property', 'og:image');
      document.head.appendChild(ogImage);
    }
    ogImage.setAttribute('content', `${process.env.PUBLIC_URL}/rw_logo.png`);

    // Add Open Graph type
    let ogType = document.querySelector('meta[property="og:type"]');
    if (!ogType) {
      ogType = document.createElement('meta');
      ogType.setAttribute('property', 'og:type');
      document.head.appendChild(ogType);
    }
    ogType.setAttribute('content', "website");

    // Add canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.rel = "canonical";
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.href = "https://randomwalk.ai/about-us/";
  }, []);


  return (
    <div className="Main2 cus4:container cus4:mx-auto font-display">
      <Helmet>
        <title>About Us | Random Walk</title>
        <meta property="og:title" content="About Us | Random Walk" />
        <meta
          name="description"
          content="Random Walk is on a mission to democratize AI, offering AI solutions and AI services to businesses and individuals, helping them understand AI and utilize AI tools for growth and innovation."
        />
        <meta
          property="og:description"
          content="Random Walk is on a mission to democratize AI, offering AI solutions and AI services to businesses and individuals, helping them understand AI and utilize AI tools for growth and innovation."
        />
        <link
          rel="canonical"
          href="https://randomwalk.ai/about-us/ "
        />
      </Helmet>

      <div className="relative h-96 md:h-screen pt-44  2xl:pt-28 md:pt-0 bg-no-repeat bg-cover bg-custom-gradients">
        <img
          src={aboutbg}
          alt="Background Image"
          loading="lazy"
          className="absolute inset-0 w-full md:object-cover h-full z-0"
        />
        <div className="text-white font-display text-left md:ml-10 lg:ml-32 2xl:ml-60 px-6 lg:px-0 relative z-10 md:pt-72  2xl:pt-[300px]">
          <div className="text-2xl md:text-3xl lg:text-5xl 2xl:text-6xl font-bold pb-2  md:pb-5 lg:pb-10">
            About Us
          </div>
          <div className="text-xs lg:text-base  max-lg:hidden 2xl:text-xl font-display text-left">
            Random Walk is on a mission to democratize AI, making it easily
            accessible to individuals and businesses through an efficient
            <br /> platform that facilitates understanding, exploration, and
            utilization of AI tools.
          </div>
          <div className="text-xs lg:text-base md:text-sm lg:hidden 2xl:text-xl font-display text-left">
            Random Walk is on a mission to democratize AI, making it easily
            accessible to individuals and businesses through an efficient
            platform that facilitates understanding, exploration, and
            utilization of AI tools.
          </div>
        </div>
      </div>



      <div className="md:flex text-[#666666] md:justify-left md:items-start mt-5 md:mt-20">
        <div className="flex flex-col-reverse md:flex-row justify-left items-start relative">
          <div className="font-display text-xs lg:text-xs md:pt-10 md:pb-20 pb-12 z-20 text-white">
            <div className="bg-no-repeat bg-cover rounded-tr-2xl rounded-br-2xl md:max-w-4xl h-auto max-w-96 max-h-sm px-5 md:px-5 lg:px-16 mr-5 md:ml-0 bg-custom-gradients relative z-0">
              <div className="font-bold text-lg pb-4 md:text-3xl md:pt-10 md:pb-5 pt-5 md:font-bold 2xl:text-4xl">
                What We Do
              </div>
              <div className="lg:text-sm text-xs 2xl:text-base pb-4 max-w-lg">
                <span className="font-medium lg:text-base 2xl:text-xl text-sm">
                  AI Integration Services:
                </span>{" "}
                Random Walk provides an all-encompassing 360-degree experience
                in AI integration, blending domain expertise, extensive AI tool
                knowledge, and seamless AI integration capabilities for a
                holistic solution.
              </div>
              <div className="lg:text-sm text-xs 2xl:text-base pb-4 max-w-lg">
                <span className="font-medium lg:text-base  2xl:text-xl text-sm">
                  AI Managed Services:
                </span>{" "}
                Random Walk’s AI Managed Service delivers technology-agnostic AI
                solutions featuring on-demand resources, minimal upfront
                investment, and swift AI integration for a seamless experience.
              </div>
              <div className="lg:text-sm text-xs 2xl:text-base pb-4 max-w-lg">
                <span className="font-medium lg:text-base 2xl:text-xl text-sm">
                  AI Workshop:
                </span>{" "}
                Explore Random Walk’s Corporate AI training service, designed to
                unravel AI complexities in various business functions. Engage in
                industry-oriented sessions and brief courses that demand minimal
                time commitment, tailored for executive-level AI training.
              </div>
              <div className="lg:text-sm text-xs 2xl:text-base pb-10 max-w-lg">
                <span className="font-medium lg:text-base 2xl:text-xl text-sm">
                  AI Consulting:
                </span>{" "}
                Random Walk’s AI consulting service, crafted to demystify AI in
                various business areas. Engage in short, industry-focused
                sessions that won’t demand much of your time. Tailored for
                executives, our AI consulting offers a straightforward
                understanding of how AI can benefit your business.
              </div>
            </div>
          </div>

          <img
            src={aboutt2}
            alt="AI Integration"
            className="2xl:ml-[350px] px-16 py-5 2xl:scale-125 lg:ml-20 md:max-w-sm md:pt-20 2xl:pt-32  md:p-0"
          />
        </div>
      </div>

      <div className="text-[#666666] relative  ">
        <div className="bg-white opacity-72 rounded-2xl 2xl:mt-10 pt-10 md:pt-5  pb-5 px-5 bg-no-repeat lg:pt-10 lg:pb-8 lg:px-5 absolute -top-12 left-1/2 transform -translate-x-1/2 z-10">
          <div className="text-center text-lg text-nowrap font-bold text-[#666666]  2xl:text-4xl font-display md:text-xl lg:text-3xl ">
            Why The Name Random Walk?
          </div>
        </div>
        <div className="text-[#666666] 2xl:pt-32 pt-10">
          <div className="flex flex-wrap md:flex-row items-center">
            <div className="md:w-1/2 flex items-center justify-center p-10">
              <img
                src={aboutbg4img}
                alt="Description of Image"
                className="md:w-auto md:h-auto 2xl:w-10/12 lg:pr-32"
              />
            </div>
            <img
              src={pinkblue}
              alt="Overlapping Image"
              className="absolute  max-md:hidden bottom-32  right-0 w-5/12 h-auto z-0"
            />
            <img
              src={pinkblue}
              alt="Overlapping Image"
              className="absolute top-[330px]   md:hidden -left-2 w-8/12 h-auto z-0"
            />
            <div className="md:w-1/2  flex flex-col opacity-56 bg-[#ffffff] p-5 md:mb-0 ml-auto relative z-0">
              <div className="font-display text-lg lg:pb-2 lg:text-3xl 2xl:text-3xl font-bold text-[#666666] mb-4 lg:mb-4 relative z-20 ">
                Reflects Exploration
              </div>
              <div className="text-xs  2xl:text-xl  text-[#666666] lg:text-base 2xl:max-w-2xl font-display lg:max-w-lg relative z-20">
                <p>
                  Random Walk is a mathematical concept that describes a path
                  where each step is taken randomly. It signifies exploration
                  and the willingness to try different paths and options. By
                  naming our AI integration services “Random Walk,” we want to
                  convey the idea of continuously exploring and adapting to new
                  possibilities and AI solutions. Think of our AI consulting
                  services and executive training as your guides in this
                  exploration, paving the way for seamless integration and
                  continuous adaptation in the dynamic landscape of AI.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-[#666666] bg-no-repeat bg-cover pt-10 md:pt-24  ">
        <div className="flex flex-wrap md:flex-nowrap md:flex-row flex-col-reverse items-center">
          <div className="w-full md:w-3/5  flex flex-col p-5 lg:mb-0 relative z-0 md:mt-56 md:-ml-20">
            <img
              src={bluepink}
              alt="Overlapping Image"
              className="absolute -bottom-16 md:bottom-12 lg:-bottom-10 left-0  w-6/12 md:w-8/12 2xl:w-7/12 h-auto"
            />
            <div className="flex flex-col md:-mt-56 md:pl-20   2xl:ml-0 p-5 lg:mb-0 lg:pb-0 w-full bg-[#ffffff] opacity-56 backdrop-blur">
              <div className="font-display text-lg lg:pb-2 lg:text-3xl 2xl:text-3xl font-bold text-[#666666] mb-4 lg:mb-4 z-20 lg:px-16 lg:py-10">
                Serendipity And Discovery
              </div>
              <div className="text-xs lg:text-base 2xl:text-xl 2xl:max-w-2xl font-display lg:max-w-2xl text-[#666666]  z-20 lg:px-16">
                <p>
                  Random Walk suggests a lack of predetermined direction or
                  rigid structure. In the context of our AI integration service,
                  it symbolizes flexibility and adaptability to various data
                  sources, systems, and workflows. It implies that we can
                  seamlessly navigate through different paths and dynamically
                  adjust our approach based on the ever-changing needs and
                  challenges involved in AI transformation.
                </p>
              </div>
            </div>
          </div>

          <div className="flex flex-1 items-center justify-center w-full md:w-auto">
            <img
              src={aboutbg6img}
              alt="Description of Image"
              className="w-3/5 md:ml-0"
            />
          </div>
        </div>
      </div>

      <div className="text-[#666666] md:pt-20 lg:pt-32 2xl:pt-20 relative ">
        <div className="flex flex-wrap md:flex-row items-center ">
          <div className="md:w-1/2 flex items-center justify-center p-10">
            <img
              src={aboutbg8img}
              alt="Description of Image"
              className="w-auto h-auto lg:pr-32"
            />
          </div>
          <img
            src={pinkblue}
            alt="Overlapping Image"
            className="absolute md:top-36 lg:top-20 right-0 w-6/12 mt-16 md:mt-0 md:w-6/12 h-auto z-0"
          />
          <div className="md:w-1/2 flex flex-col    opacity-56 bg-[#ffffff] p-5 lg:mb-0 ml-auto relative z-20 ">
            <div className="font-display text-lg lg:pb-2 lg:text-3xl 2xl:text-3xl font-bold text-[#666666] mb-4 lg:mb-7 z-20">
              Flexibility And Adaptability
            </div>
            <div className="text-xs  2xl:text-xl  text-[#666666] lg:text-base 2xl:max-w-2xl font-display lg:max-w-lg">
              <p>
                Random Walks often lead to unexpected discoveries. By naming our
                AI services “Random Walk,” we aim to convey the idea of
                uncovering valuable insights, patterns, or connections that AI
                can offer to your specific business niche. Whether it’s in
                marketing, HR, or industries like retail and pharma, AI has
                immense potential to transform the business landscape, waiting
                to be explored.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className=" mt-20 ">
        <Popup utmContent={"about-us"} />
      </div>
    </div>
  );
};

export default About;
