import React from "react";
import styles from "../styles/SectionFour.module.css";
import card1Image from "../assets/b1.gif";
import card2Image from "../assets/b2.gif";
import card3Image from "../assets/b3.gif";
import card4Image from "../assets/b4.gif";

const SectionFour = () => {
  return (
    <section className={styles.section}>
      <div className={styles.heading}>
        <span className={styles.highlight}>4 Advantages of BrandCut </span>
      </div>
      <div className={styles.grid}>
        <div className={styles.card}>
          <img className="w-[80px] h-[80px] md:w-[100px] md:h-[100px]" src={card1Image} alt="Card 1" />
          <div className={styles.cardContent}>
            <h3 className={styles.cardHeading}>Industry-Leading Accuracy</h3>
            <p className={styles.cardText}>
              Advanced algorithms ensure precise logo detection in dynamic
              environments
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <img className="w-[80px] h-[80px] md:w-[100px] md:h-[80px]" src={card2Image} alt="Card 2" />
          <div className={styles.cardContent}>
            <h3 className={styles.cardHeading}>Comprehensive Analytics</h3>
            <p className={styles.cardText}>
              Detailed metrics including duration, competitor analysis, and
              quadrant analysis
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <img className="w-[80px] h-[80px] md:w-[100px] md:h-[100px]" src={card3Image} alt="Card 3" />
          <div className={styles.cardContent}>
            <h3 className={styles.cardHeading}>Real-time Detection</h3>
            <p className={styles.cardText}>
              Instantaneous analysis of logo visibility across media channels
            </p>
          </div>
        </div>
        <div className={styles.card}>
          <img className="w-[80px] h-[80px] md:w-[80px] md:h-[80px]" src={card4Image} alt="Card 4" />
          <div className={styles.cardContent}>
            <h3 className={styles.cardHeading}>Customizable Solutions</h3>
            <p className={styles.cardText}>
              Tailored to your specific needs for seamless integration and
              scalability
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SectionFour;