import React, { useEffect, useMemo, useState, useRef } from "react";
import { Client, Databases, ID } from "appwrite";
import styles from "../styles/ContactSection.module.css";
import { useNavigate } from "react-router";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  parsePhoneNumberFromString,
  isValidPhoneNumber,
} from "libphonenumber-js";
const ContactSection = ({ visible, onClose, onSubmit }, { utmContent }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSuccessOpen, setIsSuccessOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmailId] = useState("");

  const [utmParams, setUtmParams] = useState({
    utm_source: "",
    utm_medium: "",
    utm_campaign: "",
    utm_id: "",
    utm_term: "",
    utm_content: "",
  });
  const [errors, setErrors] = useState({});

  const resetForm = () => {
    setName("");
    setDesignation("");
    setCompanyName("");

    setEmailId("");
    setPhoneNumber("");

    setIsChecked(true);
    setErrors({});
  };
  const handleButtonClick = () => {
    setIsModalOpen(true);
    resetForm();
  };

  const closeModal = () => {
    setIsModalOpen(false);
    resetForm();
  };

  const client = new Client()
    .setEndpoint(process.env.REACT_APP_ENDPOINT)
    .setProject(process.env.REACT_APP_PROJECT_ID);

  const databases = new Databases(client);

  const handlePhoneNumberChange = (number, country) => {
    setPhoneNumber(number);

    try {
      const fullNumber = number.startsWith("+") ? number : `+${number}`;
      if (isValidPhoneNumber(fullNumber)) {
        const phoneNumberObj = parsePhoneNumberFromString(fullNumber);
        const nationalNumber = phoneNumberObj.nationalNumber;
        const expectedLength = phoneNumberObj.nationalNumber?.length;
        const actualLength = nationalNumber?.length;
        if (actualLength < expectedLength) {
          setErrors((prev) => ({
            ...prev,
            phoneNumber: `Phone number is too short - needs ${expectedLength} digits`,
          }));
        } else if (actualLength > expectedLength) {
          setErrors((prev) => ({
            ...prev,
            phoneNumber: `Phone number is too long - should be ${expectedLength} digits`,
          }));
        } else {
          setErrors((prev) => ({
            ...prev,
            phoneNumber: "",
          }));
        }
      } else {
        setErrors((prev) => ({
          ...prev,
          phoneNumber: "Please enter a valid phone number",
        }));
      }
    } catch (error) {
      console.error("Phone validation error:", error);
      setErrors((prev) => ({
        ...prev,
        phoneNumber: "Please enter a valid phone number",
      }));
    }
  };

  useEffect(() => {
    const captureUtmParams = () => {
      const params = new URLSearchParams(window.location.search);
      const utmFields = [
        "utm_source",
        "utm_medium",
        "utm_campaign",
        "utm_id",
        "utm_term",
        "utm_content",
      ];

      const newUtmParams = { utm_content: utmContent };
      utmFields.forEach((field) => {
        newUtmParams[field] = params.get(field) || "";
      });
      setUtmParams(newUtmParams);
    };

    captureUtmParams();
    window.addEventListener("popstate", captureUtmParams);

    return () => {
      window.removeEventListener("popstate", captureUtmParams);
    };
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    const newErrors = {};

    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone number is required";
    }

    if (Object.keys(newErrors)?.length > 0) {
      setErrors(newErrors);
      setIsLoading(false);
      return;
    }

    try {
      const fullNumber = phoneNumber.startsWith("+")
        ? phoneNumber
        : `+${phoneNumber}`;

      if (!isValidPhoneNumber(fullNumber)) {
        setErrors((prev) => ({
          ...prev,
          phoneNumber: "Please enter a valid phone number",
        }));
        setIsLoading(false);
        return;
      }

      const formData = {
        name,
        designation,
        companyName,
        phoneNumber,
        email,
        utmSource: utmParams.utm_source,
        utmMedium: utmParams.utm_medium,
        utmCampaign: utmParams.utm_campaign,
        utmId: utmParams.utm_id,
        utmTerm: utmParams.utm_term,
        utmContent: "brandcut",
      };

      await Promise.all([
        databases.createDocument(
          process.env.REACT_APP_DATABASE_ID,
          process.env.REACT_APP_COLLECTION_ID7,
          ID.unique(),
          formData
        ),
        fetch(`${process.env.REACT_APP_BACKEND_URL}/api/submit-brandcut`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }).then(async (response) => {
          if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.message || "Failed to submit form");
          }
        }),
      ]);

      setIsModalOpen(false);
      setIsSuccessOpen(true);
      resetForm();
      setIsLoading(false);
    } catch (error) {
      console.error("Error creating document:", error);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
 const closeSuccessModal = () => {
    setIsSuccessOpen(false);
  };
  const signupRef = useRef(null);

  useEffect(() => {
    if (window.location.hash === "#signup") {
      signupRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  }, []);

  const [isChecked, setIsChecked] = useState(true);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  return (
    <section className={styles.contactSection}>
      <div className={styles.content}>
        <div className="lg:-translate-y-16">
          <h2 className={styles.heading}>
            Unlock the Power of AI <br />
            Talk to our experts
          </h2>
          <p className={styles.description}>
            Schedule your Free Demo and <br />
            Experience the power of BrandCut AI
          </p>
        </div>
      </div>

      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles["input-container"]}>
          <label htmlFor="name" className={styles.placeholder}>
            Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className={styles.input}
            required
          />
          {errors.name && <p className={styles.error}>{errors.name}</p>}
        </div>
        <div className={styles["input-container"]}>
          <label htmlFor="email" className={styles.placeholder}>
            Email Address
          </label>
          <input
            type="email"
            id="emailaddress"
            name="email"
            value={email}
            onChange={(e) => setEmailId(e.target.value)}
            className={styles.input}
            required
          />
          {errors.email && <p className={styles.error}>{errors.email}</p>}
        </div>
        {/* <div className={styles["number-container"]} > */}
        <div className="w-[270px] md:w-[300px]">
          <label htmlFor="phonenumber" className={styles.placeholder}>
            Phone Number
          </label>
          <PhoneInput
            id="phonenumber"
            country={"in"}
            value={phoneNumber}
            onChange={(number, country) =>
              handlePhoneNumberChange(number, country)
            }
            inputStyle={{
              width: "100.4%",
              borderRadius: "5px",
              border: "none",
              backgroundColor: "rgba(255, 255, 255, 0.2)",
              fontWeight: "500",
              fontFamily: "inherit",
            }}
            buttonStyle={{
              border: "none",
              borderRadius: "5px",
              height: "100%",
              backgroundColor: "transparent",
              color: "black",
            }}
            containerStyle={{ width: "100%" }}
            enableSearch={true}
            inputProps={{
              name: "phoneNumber",
              required: true,
            }}
          />
          {errors.phoneNumber && (
            <p className={styles.error}>{errors.phoneNumber}</p>
          )}
        </div>

        <div className={styles["input-container"]}>
          <label className={styles.placeholder}>Designation</label>
          <input
            id="designation"
            type="text"
            name="designation"
            value={designation}
            onChange={(e) => setDesignation(e.target.value)}
            className={styles.input}
            required
          />
          {errors.designation && (
            <p className={styles.error}>{errors.designation}</p>
          )}
        </div>

        <div className={styles["input-container"]}>
          <label className={styles.placeholder}>Company Name</label>
          <input
            type="text"
            id="companyname"
            name="companyName"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className={styles.input}
            required
          />
          {errors.companyName && (
            <p className={styles.error}>{errors.companyName}</p>
          )}
        </div>
        <div className="disclaimerContainer-brandcut ">
          <label className="checkboxLabel-brandcut ">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
              className="checkbox-brandcut"
            />
            <span className="checkboxText-brandcut ">
              I agree and authorize team to contact me and/or send relevant
              information over Email, SMS & WhatsApp. This will override the
              registry with DNC/NDNC.
            </span>
          </label>
        </div>
        {errors.terms && <p className="text-red-500 text-sm">{errors.terms}</p>}
        <div className="linksContainer-brandcut">
          <a href="/terms-of-service/" className="link-brandcut">
            Terms of Service
          </a>
          <a href="/privacy-policy/" className="link-brandcut">
            Privacy Policy
          </a>
        </div>
        <div className="mb-4 mt-6">
          <button
            id="submit"
            type="submit"
            className={`w-full px-32 py-2 bg-[#C62B6D] text-white rounded transition-opacity ${
              !isChecked || isLoading
                ? "opacity-50 cursor-not-allowed"
                : "hover:opacity-90"
            }`}
            disabled={!isChecked || isLoading}
          >
            {isLoading ? (
              <div className="flex items-center justify-center space-x-2">
                <svg
                  className="animate-spin h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                <span>Submitting...</span>
              </div>
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
      {isSuccessOpen && (
        <div className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="relative bg-white p-8 rounded-lg max-w-md w-full text-center">
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={closeSuccessModal}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            </button>
            <h2 className="text-2xl font-bold mb-4 text-black">Success!</h2>
            <p className="mb-4 text-black">
              Your request has been sent successfully.
            </p>
            <button
              id="close"
              className="px-4 py-2 bg-[#C62B6D] text-white rounded"
              onClick={closeSuccessModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default ContactSection;
