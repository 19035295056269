import React ,{useEffect} from "react";
import { Helmet } from "react-helmet-async";  
import Footer from "../components/Footer";
 const Terms = () => {
  useEffect(() => {
    // Set page title
    document.title = "Terms of Service | Random Walk";

    // Set meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', "Explore Random Walk AI’s Terms of Service. Review our policies on usage, rights, and responsibilities to ensure a secure and transparent experience.");
    } else {
      const meta = document.createElement('meta');
      meta.name = "description";
      meta.content = "Explore Random Walk AI’s Terms of Service. Review our policies on usage, rights, and responsibilities to ensure a secure and transparent experience.";
      document.head.appendChild(meta);
    }

    // Add or update Open Graph title
    let ogTitle = document.querySelector('meta[property="og:title"]');
    if (!ogTitle) {
      ogTitle = document.createElement('meta');
      ogTitle.setAttribute('property', 'og:title');
      document.head.appendChild(ogTitle);
    }
    ogTitle.setAttribute('content', "Terms of Service | Random Walk");

    // Add or update Open Graph description
    let ogDescription = document.querySelector('meta[property="og:description"]');
    if (!ogDescription) {
      ogDescription = document.createElement('meta');
      ogDescription.setAttribute('property', 'og:description');
      document.head.appendChild(ogDescription);
    }
    ogDescription.setAttribute('content', "Explore Random Walk AI’s Terms of Service. Review our policies on usage, rights, and responsibilities to ensure a secure and transparent experience.");

    // Add viewport meta tag
    let viewport = document.querySelector('meta[name="viewport"]');
    if (!viewport) {
      viewport = document.createElement('meta');
      viewport.name = "viewport";
      document.head.appendChild(viewport);
    }
    viewport.setAttribute('content', "width=device-width, initial-scale=1");

    // Add theme-color meta tag
    let themeColor = document.querySelector('meta[name="theme-color"]');
    if (!themeColor) {
      themeColor = document.createElement('meta');
      themeColor.name = "theme-color";
      document.head.appendChild(themeColor);
    }
    themeColor.setAttribute('content', "#000000");

    // Add Open Graph image
    let ogImage = document.querySelector('meta[property="og:image"]');
    if (!ogImage) {
      ogImage = document.createElement('meta');
      ogImage.setAttribute('property', 'og:image');
      document.head.appendChild(ogImage);
    }
    ogImage.setAttribute('content', `${process.env.PUBLIC_URL}/rw_logo.png`);

    // Add Open Graph type
    let ogType = document.querySelector('meta[property="og:type"]');
    if (!ogType) {
      ogType = document.createElement('meta');
      ogType.setAttribute('property', 'og:type');
      document.head.appendChild(ogType);
    }
    ogType.setAttribute('content', "website");

    // Add canonical link
    let canonicalLink = document.querySelector('link[rel="canonical"]');
    if (!canonicalLink) {
      canonicalLink = document.createElement('link');
      canonicalLink.rel = "canonical";
      document.head.appendChild(canonicalLink);
    }
    canonicalLink.href = "https://randomwalk.ai/terms-of-service";
  }, []);
  return (
    <section className="text-[#666666]">


        {/* <Helmet>
        <title>Terms of Service | Random Walk</title>
        <meta property="og:title" content="Terms of Service | Random Walk" />
        <meta name="description" content="Explore Random Walk AI’s Terms of Service. Review our policies on usage, rights, and responsibilities to ensure a secure and transparent experience." />
        <meta property="og:description" content="Explore Random Walk AI’s Terms of Service. Review our policies on usage, rights, and responsibilities to ensure a secure and transparent experience." />
        <link rel="canonical" href="https://randomwalk.ai/terms-of-service/" />
      </Helmet> */}

      <article className="bg-[#B4BDFF] mt-10 h-96 lg:h-52 flex justify-center items-center font-display">
        <header className="text-[#666666] text-4xl md:text-7xl font-bold">
          <p className="  text-center ">Terms Of Service</p>
        </header>
      </article>
      <article className="font-display   lg:text-left flex justify-center items-center px-10 lg:px-60 pt-10 lg:pt-20 pb-20">
        <p>
          <div className="lg:text-2xl ">AGREEMENT TO OUR LEGAL TERMS</div>
          <br />
          We are Random Walk (‘Company’, ‘we’, ‘us’, or ‘our’), a company
          registered in India at LBR Towers, Anna Salai, Chennai, India,
          Chennai, Tamilnadu 600021.
          <br />
          <br />
          We operate the website http://www.randomwalk.ai (the ‘Site’), as well
          as any other related products and services that refer or link to these
          legal terms (the ‘Legal Terms’) (collectively, the ‘Services’).
          <br />
          <br />
          We provide AI integration and managed services.
          <br />
          <br />
          You can contact us by phone at +91 784 575 7355, email at
          enquiry@randomwalk.ai, or by mail to LBR Towers, Anna Salai, Chennai,
          India, Chennai, Tamilnadu 600021, India.
          <br />
          <br />
          These Legal Terms constitute a legally binding agreement made between
          you, whether personally or on behalf of an entity (‘you’), and Random
          Walk, concerning your access to and use of the Services. You agree
          that by accessing the Services, you have read, understood, and agreed
          to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL
          OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE
          SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
          <br />
          <br />
          Supplemental terms and conditions or documents that may be posted on
          the Services from time to time are hereby expressly incorporated
          herein by reference. We reserve the right, in our sole discretion, to
          make changes or modifications to these Legal Terms from time to time.
          We will alert you about any changes by updating the ‘Last updated’
          date of these Legal Terms, and you waive any right to receive specific
          notice of each such change. It is your responsibility to periodically
          review these Legal Terms to stay informed of updates. You will be
          subject to, and will be deemed to have been made aware of and to have
          accepted, the changes in any revised Legal Terms by your continued use
          of the Services after the date such revised Legal Terms are posted.
          <br />
          <br />
          All users who are minors in the jurisdiction in which they reside
          (generally under the age of 18) must have the permission of, and be
          directly supervised by, their parent or guardian to use the Services.
          If you are a minor, you must have your parent or guardian read and
          agree to these Legal Terms prior to you using the Services.
          <br />
          <br />
          We recommend that you print a copy of these Legal Terms for your
          records.
          <br />
          <br />
          TABLE OF CONTENTS <br />
          <br />
          1.OUR SERVICES <br />
          2.INTELLECTUAL PROPERTY RIGHTS
          <br />
          3.USER REPRESENTATIONS
          <br />
          4.PROHIBITED ACTIVITIES
          <br />
          5.USER GENERATED CONTRIBUTIONS
          <br />
          6.CONTRIBUTION LICENCE
          <br />
          7.THIRD-PARTY WEBSITES AND CONTENT
          <br />
          8.SERVICES MANAGEMENT
          <br />
          9.PRIVACY POLICY
          <br />
          10.TERM AND TERMINATION
          <br />
          11.MODIFICATIONS AND INTERRUPTIONS
          <br />
          12.GOVERNING LAW
          <br />
          13.DISPUTE RESOLUTION
          <br />
          14.CORRECTIONS
          <br />
          15.DISCLAIMER
          <br />
          16.LIMITATIONS OF LIABILITY
          <br />
          17.INDEMNIFICATION
          <br />
          18.USER DATA
          <br />
          19ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
          <br />
          19.CALIFORNIA USERS AND RESIDENTS
          <br />
          20.MISCELLANEOUS
          <br />
          21.CONTACT US
          <br />
          <br />
          <div className="font-bold text-2xl">
            1. OUR SERVICES
            <br />
            <br />
          </div>
          The information provided when using the Services is not intended for
          distribution to or use by any person or entity in any jurisdiction or
          country where such distribution or use would be contrary to law or
          regulation or which would subject us to any registration requirement
          within such jurisdiction or country. Accordingly, those persons who
          choose to access the Services from other locations do so on their own
          initiative and are solely responsible for compliance with local laws,
          if and to the extent local laws are applicable.
          <br />
          <br />
          The Services are not tailored to comply with industry-specific
          regulations (Health Insurance Portability and Accountability Act
          (HIPAA), Federal Information Security Management Act (FISMA), etc.),
          so if your interactions would be subjected to such laws, you may not
          use the Services. You may not use the Services in a way that would
          violate the Gramm-Leach-Bliley Act (GLBA).
          <br />
          <br />
          <div className="font-bold text-2xl">
            2. INTELLECTUAL PROPERTY RIGHTS
            <br />
            <br />
          </div>
          <div className="text-xl">
            Our intellectual property
            <br />
            <br />
          </div>
          We are the owner or the licensee of all intellectual property rights
          in our Services, including all source code, databases, functionality,
          software, website designs, audio, video, text, photographs, and
          graphics in the Services (collectively, the ‘Content’), as well as the
          trademarks, service marks, and logos contained therein (the ‘Marks’).
          <br />
          <br />
          Our Content and Marks are protected by copyright and trademark laws
          (and various other intellectual property rights and unfair competition
          laws) and treaties in the United States and around the world.
          <br />
          <br />
          The Content and Marks are provided in or through the Services ‘AS IS’
          for your personal, non-commercial use or internal business purpose
          only.
          <br />
          <br />
          <div className="text-xl">
            Your use of our Services
            <br />
            <br />
          </div>
          Subject to your compliance with these Legal Terms, including the
          ‘PROHIBITED ACTIVITIES’ section below, we grant you a non-exclusive,
          non-transferable, revocable licence to:
          <br />
          <br />
          access the Services; and download or print a copy of any portion of
          the Content to which you have properly gained access. solely for your
          personal, non-commercial use or internal business purpose.
          <br />
          <br />
          Except as set out in this section or elsewhere in our Legal Terms, no
          part of the Services and no Content or Marks may be copied,
          reproduced, aggregated, republished, uploaded, posted, publicly
          displayed, encoded, translated, transmitted, distributed, sold,
          licensed, or otherwise exploited for any commercial purpose
          whatsoever, without our express prior written permission.
          <br />
          <br />
          If you wish to make any use of the Services, Content, or Marks other
          than as set out in this section or elsewhere in our Legal Terms,
          please address your request to: enquiry@randomwalk.ai. If we ever
          grant you the permission to post, reproduce, or publicly display any
          part of our Services or Content, you must identify us as the owners or
          licensors of the Services, Content, or Marks and ensure that any
          copyright or proprietary notice appears or is visible on posting,
          reproducing, or displaying our Content.
          <br />
          <br />
          We reserve all rights not expressly granted to you in and to the
          Services, Content, and Marks.
          <br />
          <br />
          Any breach of these Intellectual Property Rights will constitute a
          material breach of our Legal Terms and your right to use our Services
          will terminate immediately.
          <br />
          <br />
          <div className="text-xl">
            Your Submissions
            <br />
            <br />
          </div>
          Please review this section and the ‘PROHIBITED ACTIVITIES’ section
          carefully prior to using our Services to understand the (a) rights you
          give us and (b) obligations you have when you post or upload any
          content through the Services.
          <br />
          <br />
          Submissions: By directly sending us any question, comment, suggestion,
          idea, feedback, or other information about the Services
          (‘Submissions’), you agree to assign to us all intellectual property
          rights in such Submission. You agree that we shall own this Submission
          and be entitled to its unrestricted use and dissemination for any
          lawful purpose, commercial or otherwise, without acknowledgment or
          compensation to you.
          <br />
          <br />
          You are responsible for what you post or upload: By sending us
          Submissions through any part of the Services you:
          <br />
          <br />
          confirm that you have read and agree with our ‘PROHIBITED ACTIVITIES’
          and will not post, send, publish, upload, or transmit through the
          Services any Submission that is illegal, harassing, hateful, harmful,
          defamatory, obscene, bullying, abusive, discriminatory, threatening to
          any person or group, sexually explicit, false, inaccurate, deceitful,
          or misleading;
          <br />
          <br />
          to the extent permissible by applicable law, waive any and all moral
          rights to any such Submission;
          <br />
          <br />
          warrant that any such Submission are original to you or that you have
          the necessary rights and licences to submit such Submissions and that
          you have full authority to grant us the above-mentioned rights in
          relation to your Submissions; and
          <br />
          <br />
          warrant and represent that your Submissions do not constitute
          confidential information.
          <br />
          <br />
          You are solely responsible for your Submissions and you expressly
          agree to reimburse us for any and all losses that we may suffer
          because of your breach of (a) this section, (b) any third party’s
          intellectual property rights, or (c) applicable law.
          <br />
          <br />
          <div className="font-bold text-2xl">
            3.USER REPRESENTATIONS
            <br />
            <br />
          </div>
          By using the Services, you represent and warrant that: (1) you have
          the legal capacity and you agree to comply with these Legal Terms; (2)
          you are not a minor in the jurisdiction in which you reside, or if a
          minor, you have received parental permission to use the Services; (3)
          you will not access the Services through automated or non-human means,
          whether through a bot, script or otherwise; (4) you will not use the
          Services for any illegal or unauthorised purpose; and (5) your use of
          the Services will not violate any applicable law or regulation.
          <br />
          <br />
          If you provide any information that is untrue, inaccurate, not
          current, or incomplete, we have the right to suspend or terminate your
          account and refuse any and all current or future use of the Services
          (or any portion thereof).
          <br />
          <br />
          <div className="font-bold text-2xl">
            4. PROHIBITED ACTIVITIES
            <br />
            <br />
          </div>
          You may not access or use the Services for any purpose other than that
          for which we make the Services available. The Services may not be used
          in connection with any commercial endeavours except those that are
          specifically endorsed or approved by us.
          <br />
          <br />
          As a user of the Services, you agree not to:
          <br />
          <br />
          Systematically retrieve data or other content from the Services to
          create or compile, directly or indirectly, a collection, compilation,
          database, or directory without written permission from us.
          <br />
          <br />
          Trick, defraud, or mislead us and other users, especially in any
          attempt to learn sensitive account information such as user passwords.
          <br />
          <br />
          Circumvent, disable, or otherwise interfere with security-related
          features of the Services, including features that prevent or restrict
          the use or copying of any Content or enforce limitations on the use of
          the Services and/or the Content contained therein.
          <br />
          <br />
          Disparage, tarnish, or otherwise harm, in our opinion, us and/or the
          Services.
          <br />
          <br />
          Use any information obtained from the Services in order to harass,
          abuse, or harm another person.
          <br />
          <br />
          Make improper use of our support services or submit false reports of
          abuse or misconduct.
          <br />
          <br />
          Use the Services in a manner inconsistent with any applicable laws or
          regulations.
          <br />
          <br />
          Engage in unauthorised framing of or linking to the Services.
          <br />
          <br />
          Upload or transmit (or attempt to upload or to transmit) viruses,
          Trojan horses, or other material, including excessive use of capital
          letters and spamming (continuous posting of repetitive text), that
          interferes with any party’s uninterrupted use and enjoyment of the
          Services or modifies, impairs, disrupts, alters, or interferes with
          the use, features, functions, operation, or maintenance of the
          Services.
          <br />
          <br />
          Engage in any automated use of the system, such as using scripts to
          send comments or messages, or using any data mining, robots, or
          similar data gathering and extraction tools.
          <br />
          <br />
          Delete the copyright or other proprietary rights notice from any
          Content.
          <br />
          <br />
          Attempt to impersonate another user or person or use the username of
          another user.
          <br />
          <br />
          Upload or transmit (or attempt to upload or to transmit) any material
          that acts as a passive or active information collection or
          transmission mechanism, including without limitation, clear graphics
          interchange formats (‘gifs’), 1×1 pixels, web bugs, cookies, or other
          similar devices (sometimes referred to as ‘spyware’ or ‘passive
          collection mechanisms’ or ‘pcms’).
          <br />
          <br />
          Interfere with, disrupt, or create an undue burden on the Services or
          the networks or services connected to the Services.
          <br />
          <br />
          Harass, annoy, intimidate, or threaten any of our employees or agents
          engaged in providing any portion of the Services to you.
          <br />
          <br />
          Attempt to bypass any measures of the Services designed to prevent or
          restrict access to the Services, or any portion of the Services.
          <br />
          <br />
          Copy or adapt the Services’ software, including but not limited to
          Flash, PHP, HTML, JavaScript, or other code.
          <br />
          <br />
          Except as permitted by applicable law, decipher, decompile,
          disassemble, or reverse engineer any of the software comprising or in
          any way making up a part of the Services.
          <br />
          <br />
          Except as may be the result of standard search engine or Internet
          browser usage, use, launch, develop, or distribute any automated
          system, including without limitation, any spider, robot, cheat
          utility, scraper, or offline reader that accesses the Services, or use
          or launch any unauthorised script or other software.
          <br />
          <br />
          Use a buying agent or purchasing agent to make purchases on the
          Services.
          <br />
          <br />
          Make any unauthorised use of the Services, including collecting
          usernames and/or email addresses of users by electronic or other means
          for the purpose of sending unsolicited email, or creating user
          accounts by automated means or under false pretences.
          <br />
          <br />
          Use the Services as part of any effort to compete with us or otherwise
          use the Services and/or the Content for any revenue-generating
          endeavour or commercial enterprise.
          <br />
          <br />
          Sell or otherwise transfer your profile.
          <br />
          <br />
          Use the Services to advertise or offer to sell goods and services.
          <br />
          <br />
          <div className="font-bold text-2xl">
            5. USER GENERATED CONTRIBUTIONS
            <br />
            <br />
          </div>
          The Services does not offer users to submit or post content.
          <br />
          <br />
          <div className="font-bold text-2xl">
            6. CONTRIBUTION LICENCE
            <br />
            <br />
          </div>
          You and Services agree that we may access, store, process, and use any
          information and personal data that you provide following the terms of
          the Privacy Policy and your choices (including settings).
          <br />
          <br />
          By submitting suggestions or other feedback regarding the Services,
          you agree that we can use and share such feedback for any purpose
          without compensation to you.
          <br />
          <br />
          <div className="font-bold text-2xl">
            7. THIRD-PARTY WEBSITES AND CONTENT
            <br />
            <br />
          </div>
          The Services may contain (or you may be sent via the Site) links to
          other websites (‘Third-Party Websites’) as well as articles,
          photographs, text, graphics, pictures, designs, music, sound, video,
          information, applications, software, and other content or items
          belonging to or originating from third parties (‘Third-Party
          Content’). Such Third-Party Websites and Third-Party Content are not
          investigated, monitored, or checked for accuracy, appropriateness, or
          completeness by us, and we are not responsible for any Third-Party
          Websites accessed through the Services or any Third-Party Content
          posted on, available through, or installed from the Services,
          including the content, accuracy, offensiveness, opinions, reliability,
          privacy practices, or other policies of or contained in the
          Third-Party Websites or the Third-Party Content. Inclusion of, linking
          to, or permitting the use or installation of any Third-Party Websites
          or any Third-Party Content does not imply approval or endorsement
          thereof by us. If you decide to leave the Services and access the
          Third-Party Websites or to use or install any Third-Party Content, you
          do so at your own risk, and you should be aware these Legal Terms no
          longer govern. You should review the applicable terms and policies,
          including privacy and data gathering practices, of any website to
          which you navigate from the Services or relating to any applications
          you use or install from the Services. Any purchases you make through
          Third-Party Websites will be through other websites and from other
          companies, and we take no responsibility whatsoever in relation to
          such purchases which are exclusively between you and the applicable
          third party. You agree and acknowledge that we do not endorse the
          products or services offered on Third-Party Websites and you shall
          hold us blameless from any harm caused by your purchase of such
          products or services. Additionally, you shall hold us blameless from
          any losses sustained by you or harm caused to you relating to or
          resulting in any way from any Third-Party Content or any contact with
          Third-Party Websites.
          <br />
          <br />
          <div className="font-bold text-2xl">
            8. SERVICES MANAGEMENT
            <br />
            <br />
          </div>
          We reserve the right, but not the obligation, to: (1) monitor the
          Services for violations of these Legal Terms; (2) take appropriate
          legal action against anyone who, in our sole discretion, violates the
          law or these Legal Terms, including without limitation, reporting such
          user to law enforcement authorities; (3) in our sole discretion and
          without limitation, refuse, restrict access to, limit the availability
          of, or disable (to the extent technologically feasible) any of your
          Contributions or any portion thereof; (4) in our sole discretion and
          without limitation, notice, or liability, to remove from the Services
          or otherwise disable all files and content that are excessive in size
          or are in any way burdensome to our systems; and (5) otherwise manage
          the Services in a manner designed to protect our rights and property
          and to facilitate the proper functioning of the Services.
          <br />
          <br />
          <div className="font-bold text-2xl">
            9. PRIVACY POLICY
            <br />
            <br />
          </div>
          We care about data privacy and security. Please review our Privacy
          Policy: https://randomwalk.ai/privacy-policy/. By using the Services,
          you agree to be bound by our Privacy Policy, which is incorporated
          into these Legal Terms. Please be advised the Services are hosted in
          India. If you access the Services from any other region of the world
          with laws or other requirements governing personal data collection,
          use, or disclosure that differ from applicable laws in India, then
          through your continued use of the Services, you are transferring your
          data to India, and you expressly consent to have your data transferred
          to and processed in India.
          <br />
          <br />
          <div className="font-bold text-2xl">
            1o. TERM AND TERMINATION
            <br />
            <br />
          </div>
          These Legal Terms shall remain in full force and effect while you use
          the Services. WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL
          TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT
          NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING
          BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO
          REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
          WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY
          APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR
          PARTICIPATION IN THE SERVICES OR DELETE ANY CONTENT OR INFORMATION
          THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
          <br />
          <br />
          If we terminate or suspend your account for any reason, you are
          prohibited from registering and creating a new account under your
          name, a fake or borrowed name, or the name of any third party, even if
          you may be acting on behalf of the third party. In addition to
          terminating or suspending your account, we reserve the right to take
          appropriate legal action, including without limitation pursuing civil,
          criminal, and injunctive redress.
          <br />
          <br />
          <div className="font-bold text-2xl">
            11. MODIFICATIONS AND INTERRUPTIONS
            <br />
            <br />
          </div>
          We reserve the right to change, modify, or remove the contents of the
          Services at any time or for any reason at our sole discretion without
          notice. However, we have no obligation to update any information on
          our Services. We will not be liable to you or any third party for any
          modification, price change, suspension, or discontinuance of the
          Services.
          <br />
          <br />
          We cannot guarantee the Services will be available at all times. We
          may experience hardware, software, or other problems or need to
          perform maintenance related to the Services, resulting in
          interruptions, delays, or errors. We reserve the right to change,
          revise, update, suspend, discontinue, or otherwise modify the Services
          at any time or for any reason without notice to you. You agree that we
          have no liability whatsoever for any loss, damage, or inconvenience
          caused by your inability to access or use the Services during any
          downtime or discontinuance of the Services. Nothing in these Legal
          Terms will be construed to obligate us to maintain and support the
          Services or to supply any corrections, updates, or releases in
          connection therewith.
          <br />
          <br />
          <div className="font-bold text-2xl">
            12. GOVERNING LAW
            <br />
            <br />
          </div>
          These Legal Terms shall be governed by and defined following the laws
          of India. Random Walk and yourself irrevocably consent that the courts
          of India shall have exclusive jurisdiction to resolve any dispute
          which may arise in connection with these Legal Terms.
          <br />
          <br />
          <div className="font-bold text-2xl">
            13.DISPUTE RESOLUTION
            <br />
            <br />
          </div>
          <div className="text-xl">
            Informal Negotiations
            <br />
            <br />
          </div>
          To expedite resolution and control the cost of any dispute,
          controversy, or claim related to these Legal Terms (each a ‘Dispute’
          and collectively, the ‘Disputes’) brought by either you or us
          (individually, a ‘Party’ and collectively, the ‘Parties’), the Parties
          agree to first attempt to negotiate any Dispute (except those Disputes
          expressly provided below) informally for at least thirty (30) days
          before initiating arbitration. Such informal negotiations commence
          upon written notice from one Party to the other Party.
          <br />
          <br />
          <div className="text-xl">
            Binding Arbitration
            <br />
            <br />
          </div>
          Any dispute arising out of or in connection with these Legal Terms,
          including any question regarding its existence, validity, or
          termination, shall be referred to and finally resolved by the
          International Commercial Arbitration Court under the European
          Arbitration Chamber (Belgium, Brussels, Avenue Louise, 146) according
          to the Rules of this ICAC, which, as a result of referring to it, is
          considered as the part of this clause. The number of arbitrators shall
          be three (3). The seat, or legal place, or arbitration shall be
          Chennai, India. The language of the proceedings shall be English. The
          governing law of these Legal Terms shall be substantive law of India.
          <br />
          <br />
          <div className="text-xl">
            Restrictions
            <br />
            <br />
          </div>
          The Parties agree that any arbitration shall be limited to the Dispute
          between the Parties individually. To the full extent permitted by law,
          (a) no arbitration shall be joined with any other proceeding; (b)
          there is no right or authority for any Dispute to be arbitrated on a
          class-action basis or to utilise class action procedures; and (c)
          there is no right or authority for any Dispute to be brought in a
          purported representative capacity on behalf of the general public or
          any other persons.
          <br />
          <br />
          <div className="text-xl">
            Exceptions to Informal Negotiations and Arbitration
            <br />
            <br />
          </div>
          The Parties agree that the following Disputes are not subject to the
          above provisions concerning informal negotiations binding arbitration:
          (a) any Disputes seeking to enforce or protect, or concerning the
          validity of, any of the intellectual property rights of a Party; (b)
          any Dispute related to, or arising from, allegations of theft, piracy,
          invasion of privacy, or unauthorised use; and (c) any claim for
          injunctive relief. If this provision is found to be illegal or
          unenforceable, then neither Party will elect to arbitrate any Dispute
          falling within that portion of this provision found to be illegal or
          unenforceable and such Dispute shall be decided by a court of
          competent jurisdiction within the courts listed for jurisdiction
          above, and the Parties agree to submit to the personal jurisdiction of
          that court.
          <br />
          <br />
          <div className="text-xl">
            CORRECTIONS
            <br />
            <br />
          </div>
          There may be information on the Services that contains typographical
          errors, inaccuracies, or omissions, including descriptions, pricing,
          availability, and various other information. We reserve the right to
          correct any errors, inaccuracies, or omissions and to change or update
          the information on the Services at any time, without prior notice.
          <br />
          <br />
          <div className="text-xl">
            DISCLAIMER
            <br />
            <br />
          </div>
          THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
          AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE
          FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS
          OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF,
          INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
          NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
          ACCURACY OR COMPLETENESS OF THE SERVICES’ CONTENT OR THE CONTENT OF
          ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL
          ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
          INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
          DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND
          USE OF THE SERVICES, (3) ANY UNAUTHORISED ACCESS TO OR USE OF OUR
          SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR
          FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR
          CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS,
          VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR
          THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR
          OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF
          ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
          TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT
          WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT
          OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
          SERVICES, ANY HYPERLINKED WEBSITE, OR ANY WEBSITE OR MOBILE
          APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL
          NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
          TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR
          SERVICES. AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY
          MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGEMENT AND
          EXERCISE CAUTION WHERE APPROPRIATE.
          <br />
          <br />
          <div className="text-xl font-bold">
            16. LIMITATIONS OF LIABILITY
            <br />
            <br />
          </div>
          IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE
          TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
          EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
          PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
          USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY
          OF SUCH DAMAGES. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
          HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS
          OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED TO THE AMOUNT
          PAID, IF ANY, BY YOU TO US DURING THE SIX (6) MONTH PERIOD PRIOR TO
          ANY CAUSE OF ACTION ARISING. CERTAIN US STATE LAWS AND INTERNATIONAL
          LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION
          OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR
          ALL OF THE ABOVE DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND
          YOU MAY HAVE ADDITIONAL RIGHTS
          <br />
          <br />
          <div className="text-xl font-bold">
            17. INDEMNIFICATION
            <br />
            <br />
          </div>
          You agree to defend, indemnify, and hold us harmless, including our
          subsidiaries, affiliates, and all of our respective officers, agents,
          partners, and employees, from and against any loss, damage, liability,
          claim, or demand, including reasonable attorneys’ fees and expenses,
          made by any third party due to or arising out of: (1) use of the
          Services; (2) breach of these Legal Terms; (3) any breach of your
          representations and warranties set forth in these Legal Terms; (4)
          your violation of the rights of a third party, including but not
          limited to intellectual property rights; or (5) any overt harmful act
          toward any other user of the Services with whom you connected via the
          Services. Notwithstanding the foregoing, we reserve the right, at your
          expense, to assume the exclusive defence and control of any matter for
          which you are required to indemnify us, and you agree to cooperate, at
          your expense, with our defence of such claims. We will use reasonable
          efforts to notify you of any such claim, action, or proceeding which
          is subject to this indemnification upon becoming aware of it.
          <br />
          <br />
          <div className="text-xl font-bold">
            18. USER DATA
            <br />
            <br />
          </div>
          We will maintain certain data that you transmit to the Services for
          the purpose of managing the performance of the Services, as well as
          data relating to your use of the Services. Although we perform regular
          routine backups of data, you are solely responsible for all data that
          you transmit or that relates to any activity you have undertaken using
          the Services. You agree that we shall have no liability to you for any
          loss or corruption of any such data, and you hereby waive any right of
          action against us arising from any such loss or corruption of such
          data.
          <br />
          <br />
          <div className="text-xl font-bold">
            19. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
            <br />
            <br />
          </div>
          Visiting the Services, sending us emails, and completing online forms
          constitute electronic communications. You consent to receive
          electronic communications, and you agree that all agreements, notices,
          disclosures, and other communications we provide to you
          electronically, via email and on the Services, satisfy any legal
          requirement that such communication be in writing. YOU HEREBY AGREE TO
          THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER
          RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS
          OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You
          hereby waive any rights or requirements under any statutes,
          regulations, rules, ordinances, or other laws in any jurisdiction
          which require an original signature or delivery or retention of
          non-electronic records, or to payments or the granting of credits by
          any means other than electronic means.
          <br />
          <br />
          <div className="text-xl font-bold">
            20. CALIFORNIA USERS AND RESIDENTS
            <br />
            <br />
          </div>
          If any complaint with us is not satisfactorily resolved, you can
          contact the Complaint Assistance Unit of the Division of Consumer
          Services of the California Department of Consumer Affairs in writing
          at 1625 North Market Blvd., Suite N 112, Sacramento, California 95834
          or by telephone at (800) 952-5210 or (916) 445-1254.
          <br />
          <br />
          <div className="text-xl font-bold">
            21. MISCELLANEOUS
            <br />
            <br />
          </div>
          These Legal Terms and any policies or operating rules posted by us on
          the Services or in respect to the Services constitute the entire
          agreement and understanding between you and us. Our failure to
          exercise or enforce any right or provision of these Legal Terms shall
          not operate as a waiver of such right or provision. These Legal Terms
          operate to the fullest extent permissible by law. We may assign any or
          all of our rights and obligations to others at any time. We shall not
          be responsible or liable for any loss, damage, delay, or failure to
          act caused by any cause beyond our reasonable control. If any
          provision or part of a provision of these Legal Terms is determined to
          be unlawful, void, or unenforceable, that provision or part of the
          provision is deemed severable from these Legal Terms and does not
          affect the validity and enforceability of any remaining provisions.
          There is no joint venture, partnership, employment or agency
          relationship created between you and us as a result of these Legal
          Terms or use of the Services. You agree that these Legal Terms will
          not be construed against us by virtue of having drafted them. You
          hereby waive any and all defences you may have based on the electronic
          form of these Legal Terms and the lack of signing by the parties
          hereto to execute these Legal Terms.
          <br />
          <br />
          <div className="text-xl font-bold">
            22. CONTACT US
            <br />
            <br />
          </div>
          In order to resolve a complaint regarding the Services or to receive
          further information regarding use of the Services, please contact us
          at:
          <br />
          <br />
          Random Walk
          <br />
          <br />
          LBR Towers, Anna Salai, Chennai, India
          <br />
          <br />
          Chennai, Tamilnadu 600021
          <br />
          <br />
          India
          <br />
          <br />
          Phone: +91 784 575 7355
          <br />
          <br />
          enquiry@randomwalk.ai
        </p>
      </article>





    </section>
  );
};
export default Terms;